import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Icon } from '@material-ui/core'
import { DeleteButton, EditButton } from 'components/Button/IconButtons'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import PageHeader from 'components/utils/PageHeader'
import {
  Space,
  SpaceWhereManyInput,
  useSpaceListQuery,
  useSpacesQuery,
} from 'generated/types-and-hooks'
import { MUIDataTableOptions } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getTableChangeHandler } from 'utils/query/table-utils'
import { usePagination } from 'utils/query/usePagination'
import { useSpaceListStyles } from './styles'
import { SearchBox } from 'components/Form/SearchBox'
import { useSearch } from 'utils/query/useSearch'
import { useGlobalLoading } from 'context/LoadingContext'
import Logo from 'components/Image/Logo'

const SpaceList = () => {
  const classes = useSpaceListStyles()
  const { t } = useTranslation(['common', 'space'])
  const [count, setCount] = useState(0)
  const { setQuery, clearQuery, searchQueryParams } = useSearch<
    Omit<SpaceWhereManyInput, 'memberships' | 'company'>
  >(['name.contains'])
  const { currentPage, changePage, rowsPerPage, changeRowsPerPage, paginationParams } =
    usePagination()
  const match = useRouteMatch()
  const history = useHistory()
  const { data, loading } = useSpacesQuery({
    variables: {
      ...paginationParams,
      where: {
        OR: searchQueryParams,
      },
    },
    notifyOnNetworkStatusChange: true,
  })
  useGlobalLoading(loading)
  // useSpaceListQuery and next useEffect must be removed when count field exists in Spaces query
  const { data: allSpaceData } = useSpaceListQuery()

  useEffect(() => {
    if (!allSpaceData?.spaces) return
    setCount(allSpaceData.spaces.length)
  }, [allSpaceData])
  //

  const spaceList: NonNullable<Space>[] = useMemo<Space[]>(() => {
    if (data?.spaces?.length) {
      //Count must be set here when count field exists in Spaces query
      //setCount(data.count ?? 0)
      const spacesData = data.spaces as NonNullable<Space>[]
      return spacesData
    }
    return [] as NonNullable<Space>[]
  }, [data])

  const onTableChange = useMemo(
    () =>
      getTableChangeHandler({
        onChangePage: changePage,
        onChangeRowsPerPage: changeRowsPerPage,
      }),
    [changePage, changeRowsPerPage]
  )

  const columns: DataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: { filter: false, sort: false, display: 'excluded' },
    },
    {
      name: 'logo',
      label: t('common:table.logo'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => <Logo src={value} />,
      },
    },
    {
      name: 'name',
      label: t('common:table.name'),
      options: { filter: false, sort: false },
    },
    {
      name: 'icon',
      label: t('common:table.icon'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => <Icon fontSize="medium">{value}</Icon>,
      },
    },
    {
      name: 'primaryColor',
      label: t('common:table.primaryColor'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <div className={classes.colorBox} style={{ background: value }} />
        },
      },
    },
    {
      name: 'description',
      label: t('common:table.description'),
      options: { filter: false, sort: false },
    },
    {
      name: 'Edit',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        narrow: true,
        customBodyRender: (_, { rowData }) => (
          <EditButton
            onClick={(e) => {
              e.stopPropagation()
              history.push(`${match.url}/${rowData[0]}/edit`)
            }}
          />
        ),
      },
    },
    {
      name: 'Delete',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        narrow: true,
        customBodyRender: () => (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation()
              alert('Deleting... (Not implemented yet)')
            }}
          />
        ),
      },
    },
  ]

  const options: MUIDataTableOptions = {
    serverSide: true,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    page: currentPage,
    count,
    onTableChange,
    onRowClick: ([id]) => {
      history.push(`${match.url}/${id}`)
    },
    customToolbar: () => {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => history.push(`${match.path}/new`)}
          >
            {t('space:form.newSpace')}
          </Button>
        </>
      )
    },
  }

  return (
    <div className={classes.table}>
      <PageHeader>{t('space:name_other')}</PageHeader>
      <Box mb={2}>
        <SearchBox onSearch={setQuery} onClear={clearQuery} />
      </Box>
      <DataTable
        isLoading={loading}
        title=""
        columns={columns}
        data={spaceList.map((space) => {
          return [
            space.id,
            space.logo?.fileLocation,
            space.name,
            space.icon,
            space.primaryColor,
            space.description,
          ]
        })}
        options={options}
      />
    </div>
  )
}

export default SpaceList
