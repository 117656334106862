import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Header from './Header'
import Tabs from 'components/Tabs/Tabs'
import CreateOrEditPost from './CreateOrEdit/CreateOrEditPost'
import ViewPost from './View/ViewPost'
// import CompanyUserList from './CompanyUsers/List'
// import CompanyMemberRoutes from './CompanyUsers'
// import CreateOrEditMember from './CompanyUsers/CreateOrEdit/CreateOrEditMember'

const PostRoutes = () => {
  const { t } = useTranslation(['common', 'company', 'user'])
  const match = useRouteMatch()
  const params = useParams<{ postId: string; userId: string }>()
  const postId = parseInt(params.postId, 10)

  return (
    <>
      <Header postId={postId} />
      <Tabs
        // labels={[t('common:tab.details'), t('common:tab.members')]}
        labels={[t('common:tab.details')]}
        // subLinks={['', '/members']}
        subLinks={['']}
      />
      <Switch>
        <Route exact path={`${match.path}/edit`}>
          <CreateOrEditPost mode="edit" postId={postId} />
        </Route>
        <Route exact path={`${match.path}`}>
          <ViewPost postId={postId} />
        </Route>
        {/*
        <Route exact path={`${match.path}/members`}>
          <CompanyUserList companyId={companyId} />
        </Route>
        <Route path={`${match.path}/members/new`}>
          <CreateOrEditMember mode="create" companyId={companyId} />
        </Route>
        <Route path={`${match.path}/members/:userId`}>
          <CompanyMemberRoutes companyId={companyId} />
        </Route>
        */}
      </Switch>
    </>
  )
}

export default PostRoutes
