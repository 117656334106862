import { useHistory, useRouteMatch } from 'react-router-dom'
import PageHeader from 'components/utils/PageHeader'
import { Breadcrumbs, Chip } from '@material-ui/core'
import { emphasize, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { useUserQuery } from 'generated/types-and-hooks'
import ListIcon from '@material-ui/icons/List'
import PersonIcon from '@material-ui/icons/Person'
import { DeleteButton, EditButton } from 'components/Button/IconButtons'
import { useTranslation } from 'react-i18next'
import { useGlobalLoading } from 'context/LoadingContext'

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(4),
    color: theme.palette.grey[800],
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) as typeof Chip

const useUserHeaderStyles = makeStyles(() => ({
  name: { fontSize: '1rem' },
  personIcon: { margin: '0 0.5rem' },
}))

interface Props {
  userId: number
}

const Header = ({ userId }: Props) => {
  const id = userId
  const classes = useUserHeaderStyles()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation('user')
  const { data, loading } = useUserQuery({ variables: { where: { id } } })
  useGlobalLoading(loading)

  return (
    <PageHeader
      aside={
        <>
          {/* Edit user status and company membership. It will uncomment later. 
          <EditButton onClick={() => history.push(`${url}/edit`)} /> */}
          <DeleteButton />
        </>
      }
    >
      <Breadcrumbs>
        <StyledBreadcrumb
          component="a"
          label={t('user:name_other_capital')}
          onClick={() => history.push('/users')}
          icon={<ListIcon fontSize="medium" />}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PersonIcon className={classes.personIcon} />
          <Chip className={classes.name} size="medium" label={data?.user?.fullName ?? null} />
        </div>
      </Breadcrumbs>
    </PageHeader>
  )
}

export default Header
