import { Box, Container, Paper, useTheme, useMediaQuery, Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import FullPageLoading from 'components/utils/FullPageLoading'
import { useAuth, useLogin } from 'context/AuthContext'
import { ReactElement, useMemo } from 'react'
import { hexToRgb } from 'utils/color/hexToRgb'
import LoginForm from './LoginForm'

function Login() {
  const theme = useTheme()
  const fixedBreakpoint = useMediaQuery(theme.breakpoints.up('sm'))

  const useStyles = makeStyles(() =>
    createStyles({
      loginContainer: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
      },
    })
  )

  const classes = useStyles()
  const [login, { error, loading }] = useLogin()

  const handleLogin = (email: string, password: string, persist?: boolean) => {
    login(email, password, persist)
  }

  return (
    <Container maxWidth="xs" className={classes.loginContainer}>
      <Paper elevation={4}>
        <Box
          display="flex"
          flexDirection={fixedBreakpoint ? 'row' : 'column-reverse'}
          alignItems="center"
        >
          <LoginForm onLoginClick={handleLogin} loading={loading} error={error} />
        </Box>
      </Paper>
    </Container>
  )
}

export default Login
