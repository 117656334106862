import { Switch, Route } from 'react-router-dom'
import Login from './Login'
import Signup from './Signup'
import { useHistory } from 'react-router'

const Unauthenticated = () => {
  const history = useHistory()
  return (
    <Switch>
      <Route path="/signup">
        <Signup onSuccesfulSignup={() => history.push('/login')} />
      </Route>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  )
}

export default Unauthenticated
