import { useEffect } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Route, useHistory, useLocation, useRouteMatch, Switch } from 'react-router-dom'
import { EditButton } from 'components/Button/IconButtons'
import { useTranslation } from 'react-i18next'
import DocumentEditor from './DocumentEditor'
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy'
import TermsAndConditions from './termsAndConditions/TermsAndConditions'
import Tabs from 'components/Tabs/Tabs'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  appBar: {
    width: '60%',
    maxWidth: '30rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: 'black',
    border: '0px',
    marginBottom: '3rem',
  },

  appBarXs: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: 'black',
    border: '0px',
    marginBottom: '3rem',
  },

  editBtn: {
    margin: '-3.5rem 0 -1rem 70%',
  },
}))

interface Props {
  companyId: number
}

export default function Documents({ companyId }: Props) {
  const { t } = useTranslation('company')
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const match = useRouteMatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    if (pathname === `/companies/${companyId}/documents`)
      history.push(`/companies/${companyId}/documents/privacy`)
  }, [])

  return (
    <div className={classes.root}>
      <Tabs
        labels={[t('company:documents.privacyPolicy'), t('company:documents.termsAndConditions')]}
        subLinks={['/privacy', '/terms']}
        className={matches ? classes.appBarXs : classes.appBar}
      />
      {!pathname.includes('edit') && (
        <EditButton
          className={classes.editBtn}
          onClick={() => {
            pathname.includes('terms')
              ? history.push(`${match.url}/terms/edit`)
              : history.push(`${match.url}/privacy/edit`)
          }}
        />
      )}
      <Switch>
        <Route exact path={`${match.path}/:documentType/edit`}>
          <DocumentEditor />
        </Route>
        <Route exact path={`${match.path}/privacy`}>
          <PrivacyPolicy companyId={companyId} />
        </Route>
        <Route exact path={`${match.path}/terms`}>
          <TermsAndConditions companyId={companyId} />
        </Route>
      </Switch>
    </div>
  )
}
