import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core'
import AppButton from 'components/Button/AppButton'
import { useTranslation } from 'react-i18next'

interface Props {
  onClose: () => void
  onDelete: () => void
  title?: string
  body?: string
  deleteBtnLabel?: string
  open?: boolean
  loading?: boolean
}

const DeleteWarningDialog = (props: Props) => {
  const { t } = useTranslation('common')

  return (
    <Dialog open={'open' in props ? (props.open as boolean) : true} onClose={props.onClose}>
      <DialogTitle>{props.title ?? t('common:confirm.del')}</DialogTitle>
      <DialogContent>{props.body}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
        <AppButton
          loading={props.loading}
          color="secondary"
          onClick={() => {
            props.onDelete()
          }}
        >
          {props.deleteBtnLabel ?? t('common:action.delete')}
        </AppButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteWarningDialog
