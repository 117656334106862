import Icon from '@material-ui/core/Icon'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Popover, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import _debounce from 'lodash/debounce'
import { FieldError } from 'react-hook-form'
import { fromSnakeCase, selectableIcons, toSnakeCase } from './icons'
import { CloseButton } from 'components/Button/IconButtons'
import { textShortener } from 'components/Table/TextShortener'
import { useIconSelectorStyles } from './styles'

interface Props {
  label: string
  value: string
  onChange: (icon: string) => void
  error: FieldError | undefined
  className?: string
  popupMarginTop?: string
  required?: boolean
}

const IconSelect = ({
  label,
  value,
  onChange,
  error,
  className,
  popupMarginTop,
  required,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null)
  const [displayedName, setDisplayedName] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [searchByInput, setSearchByInput] = useState('')
  const { t } = useTranslation(['common', 'selector'])
  const classes = useIconSelectorStyles()
  const theme = useTheme()
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'))
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const debounceFn = useCallback(_debounce(handleDebounceFn, 300), [])

  useEffect(() => {
    if (value && selectedIcon === null) {
      setSelectedIcon(value)
      setDisplayedName(fromSnakeCase(value))
    }
  }, [value, selectedIcon])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const iconArray: string[] = useMemo<string[]>(() => {
    if (!open) return []
    if (searchInput) {
      return selectableIcons
        .filter((icon) => icon.toLowerCase().includes(searchInput.toLowerCase()))
        .map((elem) => toSnakeCase(elem))
    } else {
      return selectableIcons.map((elem) => toSnakeCase(elem))
    }
  }, [open, selectableIcons, searchByInput])

  const addIconHandler = (icon: string) => {
    onChange(icon)
    setSelectedIcon(icon)
    setDisplayedName(fromSnakeCase(icon))
  }

  const removeIconHandler = () => {
    onChange('')
    setSelectedIcon('')
    setDisplayedName('')
  }

  function handleDebounceFn(iconValue: string) {
    setSearchByInput(iconValue)
  }

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
    debounceFn(searchInput)
  }

  return (
    <>
      <section className={className}>
        <div className={classes.flexContainer}>
          {matchesUpSm && (
            <Typography className={classes.labelSection} color="textSecondary">
              {required ? `${label} *` : label}
            </Typography>
          )}
          <div className={classes.flexSection}>
            <Button
              className={classes.btnMain}
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
            >
              {selectedIcon ? t('selector:icon.selectIcon') : t('selector:icon.chooseIcon')}
            </Button>
          </div>
          {(matchesUpSm || selectedIcon) && (
            <Icon className={classes.flexSection} fontSize="large">
              {selectedIcon}
            </Icon>
          )}
        </div>
      </section>
      <Popover
        className={matchesUpMd ? classes.popoverUpMd : classes.popover}
        style={{ marginTop: popupMarginTop ? popupMarginTop : '5rem' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div
          className={
            matchesUpSm || !selectedIcon ? classes.iconContainerUpSm : classes.iconContainer
          }
        >
          <div className={classes.stickyContainer}>
            <TextField
              className={classes.searchBox}
              name="searchIcon"
              variant="outlined"
              placeholder={t('common:action.searchDot')}
              value={searchInput}
              autoComplete="off"
              onChange={searchHandler}
            />
            <CloseButton className={classes.btnClose} onClick={handleClose} />
          </div>
          <Typography className={classes.results} color="textPrimary">
            {`${iconArray.length} ${searchInput ? t('selector:icon.matching') : ''} ${
              iconArray.length > 1 ? t('selector:icon.name_other') : t('selector:icon.name')
            }`}
          </Typography>
          {iconArray.map((icon, index) => (
            <div className={classes.iconCard} key={icon}>
              <Icon className={classes.icon} fontSize="large" onClick={() => addIconHandler(icon)}>
                {iconArray[index]}
              </Icon>
              <Typography color="textSecondary">
                {textShortener(fromSnakeCase(icon), 12)}
              </Typography>
            </div>
          ))}
        </div>
        {selectedIcon && (
          <div className={classes.flexContainerInner}>
            <div className={classes.flexSectionInner}>
              <Typography color="textPrimary">Selected Icon:</Typography>
            </div>
            <div className={classes.flexSectionInner}>
              <Icon fontSize="large">{selectedIcon}</Icon>
              <Typography color="textSecondary">{displayedName}</Typography>
            </div>
            <div className={classes.flexSectionInner}>
              <Button
                className={classes.btnSecondary}
                variant="outlined"
                onClick={removeIconHandler}
              >
                {t('common:action.remove')}
              </Button>
              <Button className={classes.btnPrimary} variant="outlined" onClick={handleClose}>
                {t('selector:icon.ok')}
              </Button>
            </div>
          </div>
        )}
      </Popover>
      {error && !selectedIcon && <div className={classes.error}>{error.message}</div>}
    </>
  )
}

export default IconSelect
