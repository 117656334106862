import React, {
  createContext,
  useCallback,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { ApolloError } from '@apollo/client'
import { CurrentUser, useCurrentUserLazyQuery, User, UserRole } from 'generated/types-and-hooks'

import FullPageLoading from 'components/utils/FullPageLoading'
import { useAuthToken } from './_useAuthToken'

interface AuthContextReturnValues {
  _setAuthToken: (token: string, options: { persist: boolean }) => void
  _clearAuthToken: () => void
  initialLoading: boolean
  user?: AppCurrentUser
  isLoading: boolean
  error?: ApolloError
}

/**
 * Context
 */
const AuthContext: React.Context<AuthContextReturnValues> = createContext(
  {} as AuthContextReturnValues
)
AuthContext.displayName = 'AuthContext'

export interface AppCurrentUser extends CurrentUser {
  isAdmin: boolean
  isSuperAdmin: boolean
}

/**
 * Provider
 */
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [fetchCurrentUser, currentUser] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
  })
  const onTokenChange = useCallback(() => {
    fetchCurrentUser()
  }, [fetchCurrentUser])

  const authTokenHookArgs = useMemo(() => ({ onTokenChange }), [onTokenChange])
  const { setAuthToken, clearAuthToken } = useAuthToken(authTokenHookArgs)
  const [initialLoading, setInitialLoading] = useState(true)
  useEffect(() => {
    if (!currentUser.data) return
    setInitialLoading(false)
  }, [currentUser.data])

  const contextValue: AuthContextReturnValues = useMemo(() => {
    const user = currentUser.data?.currentUser
      ? extendCurrentUser(currentUser.data.currentUser)
      : undefined

    return {
      _setAuthToken: setAuthToken,
      _clearAuthToken: clearAuthToken,
      isLoading: initialLoading || currentUser.loading,
      initialLoading: initialLoading,
      error: currentUser.error,
      user,
    }
  }, [setAuthToken, clearAuthToken, initialLoading, currentUser])

  // if (contextValue.isLoading) return <FullPageLoading />
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

/**
 * Hook
 */
const useAuth = () => {
  const authContext = useContext(AuthContext)
  if (!authContext) throw new Error('Missing AuthProvider, when trying to use the useAuth Hook')
  return authContext
}

function extendCurrentUser(user: CurrentUser): AppCurrentUser {
  let isAdmin = false
  let isSuperAdmin = false
  if (user.role === UserRole.Admin) isAdmin = true
  if (user.role === UserRole.SuperAdmin) {
    isAdmin = true
    isSuperAdmin = true
  }
  return { ...user, isAdmin, isSuperAdmin }
}

export { useLogin } from './useLogin'
export { useLogout } from './useLogout'
export { useSignup } from './useSignup'

export { useAuth, AuthProvider }
