import { TextField } from '@material-ui/core'
import { LANGUAGE_CODES, getLanguageByCode } from 'utils/constants/languages'
import { Autocomplete } from 'components/Form/Atoms/Autocomplete'
import { FieldValues, UseControllerProps } from 'react-hook-form'
import { useMemo } from 'react'
import { AutocompleteProps } from '@material-ui/lab'

type Props<
  T,
  FieldValueTypes,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> &
  UseControllerProps<FieldValueTypes> & {
    allowedLanguages?: string[]
    label: string
    disabled?: boolean
    required?: boolean
  }

export const LanguageSelector = <
  T,
  FieldValueTypes extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  control,
  name,
  label,
  required,
  id,
  disabled,
  rules,
  defaultValue,
  allowedLanguages,
  multiple,
}: Props<T, FieldValueTypes, Multiple, DisableClearable, FreeSolo>) => {
  const textFieldProps = {
    required,
    label,
    id: id ?? null,
    disabled: !!disabled,
  }

  const filteredOptions: string[] = useMemo(() => {
    return allowedLanguages
      ? LANGUAGE_CODES.filter((code) => allowedLanguages.includes(code))
      : LANGUAGE_CODES
  }, [allowedLanguages])

  return (
    <Autocomplete
      name={name}
      control={control}
      rules={rules}
      multiple={multiple ?? false}
      options={filteredOptions}
      getOptionLabel={(langCode) => {
        const language = getLanguageByCode(langCode)
        if (!language) return ''
        return language.native
      }}
      defaultValue={defaultValue}
      filterSelectedOptions
      renderInput={(param) => <TextField variant="outlined" {...textFieldProps} {...param} />}
    />
  )
}
