import client from 'apollo/client'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useAuth } from '../AuthContext'

export const useLogout = () => {
  const { _clearAuthToken } = useAuth()
  const history = useHistory()

  const logout = useCallback(async () => {
    try {
      await client.clearStore()
      _clearAuthToken?.()
      history.push('/login')
      return 'success'
    } catch {
      return 'error'
    }
  }, [_clearAuthToken])

  return logout
}
