import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useReducer,
} from 'react'
import { useInterval } from 'utils/hooks/useInterval'

/**
 * Types
 */
interface State {
  loading: boolean
  progress: number
}

type ContextValue =
  | (State & {
      dispatch: React.Dispatch<Action>
    })
  | undefined

const defaultState: State = {
  loading: false,
  progress: 0,
}

type Action = { type: 'start' } | { type: 'stop' } | { type: '_tickLoading' } | { type: '_hide' }

/**
 * Context
 */
const LoadingContext = createContext<ContextValue>(undefined)
LoadingContext.displayName = 'LoadingContext'
/**
 * Reducer
 */
const loadingReducer = (state: State, action: Action): State => {
  const { type } = action
  const newState = { ...state }
  switch (type) {
    case 'start': {
      newState.loading = true
      newState.progress = Math.floor(Math.random() * 10)
      break
    }
    case 'stop': {
      newState.progress = 100
      break
    }
    case '_hide': {
      newState.progress = 0
      newState.loading = false
      break
    }
    case '_tickLoading': {
      if (state.progress >= 99) return newState
      const smallAmount = Math.floor(Math.random() * 7)
      const tinyAmount = Math.round(Math.random())
      const bigAmount = Math.floor(Math.random() * 20)
      const amount =
        state.progress < 50 ? bigAmount : state.progress < 85 ? smallAmount : tinyAmount
      const newProgress = Math.min(99, state.progress + amount)
      newState.progress = newProgress
      break
    }
    default:
      throw new Error(`Unhandled loadingContext dispatch action: ${type}`)
  }
  return newState
}

/**
 * Provider
 */
const LoadingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(loadingReducer, defaultState)
  const { loading, progress } = state

  const intervalFunction = useCallback(() => dispatch({ type: '_tickLoading' }), [])
  const [_startLoadingInterval, _stopLoadingInterval, timer] = useInterval(intervalFunction, 300)

  useEffect(() => {
    if (progress === 100) setTimeout(() => dispatch({ type: '_hide' }), 500)
  }, [progress])

  useEffect(() => {
    if (!loading) {
      if (timer) {
        _stopLoadingInterval()
      }

      return
    }
    _startLoadingInterval()
  }, [loading, _stopLoadingInterval, _startLoadingInterval, timer])

  const value: ContextValue = useMemo(() => ({ loading, progress, dispatch }), [loading, progress])
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
}

/*
 * Hook
 */
function useGlobalLoading(isLoading?: boolean) {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  const { dispatch } = context
  const startLoading = useCallback(() => dispatch({ type: 'start' }), [dispatch])
  const stopLoading = useCallback(() => dispatch({ type: 'stop' }), [dispatch])

  useEffect(() => {
    if (isLoading && !context.loading) startLoading()
    if (!isLoading && context.loading) stopLoading()
  }, [isLoading])

  return { ...context, startLoading, stopLoading } as const
}

export { LoadingProvider, useGlobalLoading }
