import { createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 'calc(100% - 15rem)',
      position: 'relative',
      flexGrow: 1,
      padding: theme.spacing(3),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
)

const Body: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <main className={classes.content}>
      <Toolbar />
      {children}
    </main>
  )
}

export default Body
