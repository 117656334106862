import {useMemo} from 'react'
import {Container, Divider, Grid, Paper, Typography, useMediaQuery, useTheme,} from '@material-ui/core'
import BackDropLoading from 'components/utils/BackDropLoading'
import {Post, usePostQuery} from 'generated/types-and-hooks'
import defaultLogo from 'assets/img/default-logo.png'
import {useTranslation} from 'react-i18next'
import {useSpaceViewStyles} from 'modules/space/View/styles'
import {Link} from 'react-router-dom'

interface Props {
  postId: number
}

const ViewPost = ({ postId }: Props) => {
  const { data, loading } = usePostQuery({ variables: { where: { id: postId } } })
  const { t } = useTranslation(['common', 'company', 'space'])
  const classes = useSpaceViewStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  const post: NonNullable<Post> = useMemo<Post>(() => {
    if (data?.post && !loading) {
      return data.post as NonNullable<Post>
    }
    return {} as NonNullable<Post>
  }, [data])

  // avatar
  // aboutUs
  // ourServices
  // location
  // membershipsConnection
  const baseData = [
    { title: t('common:table.name'), text: post.name },
    { title: t('common:table.uuid'), text: post.uuid },
    { title: 'Address', text: post.address },
    { title: 'Username', text: post.username },
    { title: 'Email', text: post.email },
    { title: 'Phone', text: post.phone },
    { title: 'Bio', text: post.bio },
    {
      title: t('common:table.created'),
      text: new Date(Number(post.createdAt)).toString().substr(3, 18) ?? '',
    },
    {
      title: t('common:table.updated'),
      text: new Date(Number(post.updatedAt)).toString().substr(3, 18) ?? '',
    },
  ]

  if (loading) {
    return <BackDropLoading />
  } else {
    return (
      <>
        <Container className={matches ? classes.rootXs : classes.root}>
          <Grid container wrap={matches ? 'wrap' : 'nowrap'} spacing={3}>
            <Grid item xs={matches ? 12 : 4} className={classes.logoGrid}>
              <Paper className={matches ? classes.logoXs : classes.logo}>
                {post?.avatar?.fileLocation ? (
                  <img
                    className={classes.image}
                    alt={t('company:companyLogo')}
                    src={post.avatar.fileLocation}
                  />
                ) : (
                  <img className={classes.image} src={defaultLogo} alt={t('company:defaultLogo')} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={matches ? 12 : 8} className={classes.baseData}>
              {baseData.map((u) => (
                <Grid key={u.title.replace(' ', '').toLowerCase()} container alignItems="center">
                  <Grid item xs={matches ? 5 : 5}>
                    <Typography className={classes.textLeft} color="textPrimary">
                      {u.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={matches ? 7 : 7}>
                    <Typography className={classes.textRight} color="textSecondary">
                      {u.text ?? '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {post.aboutUs?.[0]?.content && (
            <Grid container spacing={3}>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography paragraph color="textPrimary">
                    {t('company:aboutUs')}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: post.aboutUs[0].content,
                    }}
                    color="textSecondary"
                  ></Typography>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </>
    )
  }
}

export default ViewPost
