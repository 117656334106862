import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import IconSelect from './IconSelect'

type Props<FieldValueTypes> = UseControllerProps<FieldValueTypes> & {
  label: string
  required?: boolean
  className?: string
  popupMarginTop?: string
}

function IconSelector<FieldValueTypes extends FieldValues>({
  name,
  label,
  control,
  required,
  className,
  popupMarginTop,
  rules,
}: Props<FieldValueTypes>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <IconSelect
          label={label}
          value={field.value}
          onChange={field.onChange}
          error={error}
          className={className}
          popupMarginTop={popupMarginTop}
          required={required}
        />
      )}
    />
  )
}

export default IconSelector
