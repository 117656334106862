import { useCallback, useEffect, useState } from 'react'

type DialogState = { isOpen: true; id: number } | { isOpen: false }
export const useDeleteDialog = () => {
  const [dialogState, setDialogState] = useState<DialogState>({ isOpen: false })

  useEffect(() => {
    return () => setDialogState({ isOpen: false })
  }, [])

  const openDialog = useCallback((_id: number) => {
    setDialogState({ isOpen: true, id: _id })
  }, [])

  const closeDialog = useCallback(() => {
    setDialogState({ isOpen: false })
  }, [])

  return {
    isOpen: dialogState.isOpen,
    openDialog,
    closeDialog,
    selectedId: 'id' in dialogState ? dialogState.id : null,
  }
}
