import { UserTableData, tableStyles } from '../model_styles'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import { MUIDataTableOptions, SelectableRows } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TableText from '../TableText'

interface Props {
  userTableData: UserTableData[]
  loading: boolean
}

const Users = ({ userTableData, loading }: Props) => {
  const { t } = useTranslation(['stat', 'user'])
  const classes = tableStyles()
  const history = useHistory()

  const options: MUIDataTableOptions = {
    viewColumns: false,
    download: false,
    print: false,
    search: false,
    pagination: false,
    onRowClick: () => history.push('/users'),
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
  }

  const columns: DataTableColumn[] = [
    {
      name: 'allCount',
      label: t('stat:label.total'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'activeCount',
      label: t('user:enum.status.ACTIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'inactiveCount',
      label: t('user:enum.status.INACTIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'passiveCount',
      label: t('user:enum.status.PASSIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'registeredCount',
      label: t('user:enum.status.REGISTERED'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'bannedCount',
      label: t('user:enum.status.BANNED'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'bannedCount',
      label: 'Invited',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
  ]

  return (
    <div className={classes.table}>
      <DataTable
        isLoading={loading}
        title={t('stat:title.users')}
        columns={columns}
        data={userTableData}
        options={options}
      />
    </div>
  )
}

export default Users
