import { TextField } from 'components/Form/Atoms'
import { Control } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StyledHeader } from './Styles'
import { SignupFormValues } from './types'

interface Props {
  control: Control<SignupFormValues>
}

const SecurityQuestionFields = (props: Props) => {
  const { t } = useTranslation('signup')

  return (
    <>
      <Grid item xs={12}>
        <StyledHeader variant="h6">{t('signup:form.securityQuestions')}</StyledHeader>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          control={props.control}
          label={t('signup:form.securityQuestion', { number: '1' })}
          name="securityQuestions.0"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          control={props.control}
          label={t('signup:form.securityAnswer', { number: '1' })}
          name="securityAnswers.0"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          control={props.control}
          label={t('signup:form.securityQuestion', { number: '2' })}
          name="securityQuestions.1"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          control={props.control}
          label={t('signup:form.securityAnswer', { number: '2' })}
          name="securityAnswers.1"
        />
      </Grid>
    </>
  )
}

export default SecurityQuestionFields
