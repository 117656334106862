import { useTranslation } from 'react-i18next'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import Header from './Header'
import Tabs from 'components/Tabs/Tabs'
import EditUser from './CreateOrEdit/EditUser'
import ViewUser from './View/ViewUser'

const UserRoutes = () => {
  const { t } = useTranslation(['common', 'company'])
  const match = useRouteMatch()
  const params = useParams<{ userId: string }>()
  const userId = parseInt(params.userId, 10)

  return (
    <>
      <Header userId={userId} />
      <Tabs
        labels={[t('common:tab.details'), t('company:name_other')]}
        subLinks={['', '/companies']}
      />
      <Switch>
        {/* Edit user status and company membership. It will uncomment later.
        <Route exact path={`${match.path}/edit`}>
          <EditUser userId={userId} />
        </Route> */}
        <Route exact path={`${match.path}`}>
          <ViewUser userId={userId} />
        </Route>
      </Switch>
    </>
  )
}

export default UserRoutes
