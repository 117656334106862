import client from 'apollo/client'
import gql from 'graphql-tag'
import { TFunction } from 'i18next'

const QUERY = gql`
  query IsUserRegistered($email: String, $username: String) {
    isUserRegistered(email: $email, username: $username)
  }
`

type Variables = { username: string } | { email: string }

async function checkIfUserRegistered<T>(variables: Variables, t: TFunction): Promise<boolean> {
  const { data } = await client.query<{ isUserRegistered: boolean }>({
    query: QUERY,
    variables,
  })
  return data.isUserRegistered
}

export { checkIfUserRegistered }
