import {
  CompanyMembershipRole,
  CompanyMembershipStatus,
  UserStatus,
} from 'generated/types-and-hooks'

export type FormValues = {
  status: UserStatus
  companyUuId: string
  roleAtCompany: CompanyMembershipRole
  statusAtCompany: CompanyMembershipStatus
}

export const selectableRoles = Object.values(CompanyMembershipRole).filter(
  (role) => role !== CompanyMembershipRole.KeyUser && role !== CompanyMembershipRole.Employee
)
