import {
  createStyles,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    textUpMd: {
      fontSize: '0.875rem',
    },
  })
)

interface Props {
  text: string | number
  info?: string
}

const TableText = ({ text, info }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {info ? (
        <Tooltip title={info} arrow placement="left-start">
          <Typography className={matches ? classes.textUpMd : ''} color="textSecondary">
            {text}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={matches ? classes.textUpMd : ''} color="textSecondary">
          {text}
        </Typography>
      )}
    </>
  )
}

export default TableText
