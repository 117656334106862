export const textShortener = (text: string | undefined | null, maxChars: number) => {
  return text && text.length > maxChars
    ? text?.trim().includes(' ')
      ? `${text.slice(
          0,
          Math.max(0, text.slice(0, Math.max(0, maxChars - 3)).lastIndexOf(' '))
        )}...`
      : `${text?.slice(0, Math.max(0, maxChars - 3))}...`
    : text
}
