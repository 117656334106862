import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Header from './Header'
import Tabs from 'components/Tabs/Tabs'
import CreateOrEditCompany from './CreateOrEdit/CreateOrEditCompany'
import ViewCompany from './View/ViewCompany'
import CompanyUserList from './CompanyUsers/List'
import Documents from './Documents'
import CompanyMemberRoutes from './CompanyUsers'
import CreateOrEditMember from './CompanyUsers/CreateOrEdit/CreateOrEditMember'

const CompanyRoutes = () => {
  const { t } = useTranslation(['common', 'company', 'user'])
  const match = useRouteMatch()
  const params = useParams<{ companyId: string; userId: string }>()
  const companyId = parseInt(params.companyId, 10)

  return (
    <>
      <Header companyId={companyId} />
      <Tabs
        labels={[t('common:tab.details'), t('common:tab.members'), t('company:document')]}
        subLinks={['', '/members', '/documents/privacy']}
      />
      <Switch>
        <Route exact path={`${match.path}/edit`}>
          <CreateOrEditCompany mode="edit" companyId={companyId} />
        </Route>
        <Route exact path={`${match.path}`}>
          <ViewCompany companyId={companyId} />
        </Route>
        <Route path={`${match.path}/documents`}>
          <Documents companyId={companyId} />
        </Route>
        <Route exact path={`${match.path}/members`}>
          <CompanyUserList companyId={companyId} />
        </Route>
        <Route path={`${match.path}/members/new`}>
          <CreateOrEditMember mode="create" companyId={companyId} />
        </Route>
        <Route path={`${match.path}/members/:userId`}>
          <CompanyMemberRoutes companyId={companyId} />
        </Route>
      </Switch>
    </>
  )
}

export default CompanyRoutes
