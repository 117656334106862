import { set } from 'lodash'
import { Reducer, useCallback, useReducer } from 'react'
import { Paths } from 'utils/types'

type Action = { type: 'clearQuery' } | { type: 'setQuery'; value: string }

export function useSearch<T>(pathsToSearch: Paths<T>[]) {
  const reducer = useCallback(
    (state: T[] | undefined, action: Action) => {
      switch (action.type) {
        case 'clearQuery': {
          return undefined
        }
        case 'setQuery': {
          const query = action.value
          if (!query) return undefined
          const newQueryParams: any[] = []
          pathsToSearch.forEach((pathStr) => {
            const newParam: any = {}
            if (typeof pathStr === 'string') {
              set(newParam, pathStr, query)
            }
            newQueryParams.push(newParam as T)
          })
          return newQueryParams as T[]
        }

        default:
          throw new Error(`SearchReducer called with an unknown action`)
      }
    },
    [pathsToSearch]
  )
  const [queryParams, dispatch] = useReducer<Reducer<T[] | undefined, Action>>(reducer, undefined)
  const setQuery = useCallback((query: string) => dispatch({ type: 'setQuery', value: query }), [])
  const clearQuery = useCallback(() => dispatch({ type: 'clearQuery' }), [])

  return { setQuery, clearQuery, searchQueryParams: queryParams } as const
}
