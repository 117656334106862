// - Comment out everything if deleteUserMutation exists! -
import DeleteWarningDialog from 'components/Dialog/DeleteWarningDialog'
//import { useDeleteUserMutation } from 'generated/types-and-hooks'
//import { useSnackbar } from 'notistack'
//import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  onClose: () => void
  userId: number
  open: boolean
}

const DeleteUserDialog = ({ userId, open, onClose }: Props) => {
  //const [deleteUser, { data: deletedUser, error: errorMessage, loading }] = useDeleteUserMutation()
  //const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'user'])

  /*
  const handleDeleteUser = (_id: number) => {
    deleteUser({
      variables: { id: _id },
      update(cache) {
        const normalizedId = cache.identify({ _id, __typename: 'User' })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      optimisticResponse: {},
    }).catch((e) => {
      console.error(e)
      enqueueSnackbar(
        t('common:status.unSuccessDelete', {
          entity: t('user:name'),
        }),
        {
          variant: 'error',
        }
      )
    })
  }

  useEffect(() => {
    if (!deletedUser) return
    enqueueSnackbar(
      t('common:status.successDelete', {
        entity: t('user:name'),
      }),
      { variant: 'success' }
    )
    onClose()
  }, [deletedUser, enqueueSnackbar])
*/

  const onDelete = () => {
    //handleDeleteUser(userId)
    alert(`User Id: ${userId}. Delete function has not been implemented, yet.`)
  }

  return (
    <DeleteWarningDialog
      open={open}
      //loading={loading}
      onDelete={onDelete}
      onClose={onClose}
      title={t('common:confirm.delete', { entity: t('user:name') })}
      //body={errorMessage?.message ? errorMessage.message : ''}
    />
  )
}

export default DeleteUserDialog
