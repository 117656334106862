import { List, createStyles, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import MenuItem from './MenuItem'
import { SIDEBAR_WIDTH } from './SideBar'
export const useLayoutStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: SIDEBAR_WIDTH,
      flexShrink: 0,
    },
    drawerPaper: {
      width: SIDEBAR_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
  })
)

interface Props {
  toggleSidebar?: () => void
}

const MenuItems = (props: Props) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('setting')

  const menuItems = [
    {
      sidebar: {
        label: 'Users',
        link: '/users',
      },
      path: '/users',
    },
    {
      sidebar: {
        label: 'Spaces',
        link: '/spaces',
      },
      path: '/spaces',
    },
    {
      sidebar: {
        label: 'Companies',
        link: '/companies',
      },
      path: '/companies',
    },
    {
      sidebar: {
        label: 'Posts',
        link: '/posts',
      },
      path: '/posts',
    },
    {
      sidebar: {
        label: 'Statistics',
        link: '/statistics',
      },
      path: '/statistics',
    },
    {
      sidebar: {
        label: 'Leaders',
        link: '/settings',
      },
      path: '/settings',
    },
  ] as const

  return (
    <List onClick={props.toggleSidebar ?? undefined}>
      {menuItems.map((route) =>
        route?.sidebar ? (
          <MenuItem
            name={route.sidebar.label}
            link={route.sidebar.link ?? ''}
            isActive={isActiveLink(String(route.path), pathname)}
            key={String(route.path)}
            className="menuItem"
          />
        ) : null
      )}
    </List>
  )
}

const isActiveLink = (routeToMatch: string, pathname: string) => {
  const splitted = pathname.split('/')
  return splitted[1].includes(routeToMatch.replace('/', ''))
}

export default MenuItems
