import ListItem from '@material-ui/core/ListItem'
import { NavLink, NavLinkProps } from 'react-router-dom'
import SvgIcon from '@material-ui/core/SvgIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import React from 'react'

export interface MenuItemProps {
  className: string
  link: string
  Icon?: typeof SvgIcon
  name: string
  isActive: boolean
}

const NavLinkRef = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink exact {...props} innerRef={ref} />
))
NavLinkRef.displayName = 'NavLinkRef'

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { className, link, Icon, name } = props

  return (
    <ListItem
      selected={props.isActive}
      button
      className={className}
      component={NavLinkRef}
      to={link}
    >
      {Icon ? (
        <ListItemIcon>
          <Icon color="primary" />
        </ListItemIcon>
      ) : null}
      <ListItemText primary={name} />
    </ListItem>
  )
}
MenuItem.displayName = 'MenuItem'

export default MenuItem
