import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

export type AddPostMemberInput = {
  post: PostWhereOneInput
  user: UserWhereOneInput
  status?: Maybe<PostMembershipStatus>
  role?: Maybe<PostMembershipRole>
}

export type Asset = {
  __typename?: 'Asset'
  id?: Maybe<Scalars['Int']>
  fileLocation?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  fileExtension?: Maybe<Scalars['String']>
  fileSize?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export type AssetWhereManyInput = {
  id?: Maybe<Scalars['Int']>
  AND?: Maybe<Array<Maybe<AssetWhereManyInput>>>
  OR?: Maybe<Array<Maybe<AssetWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<AssetWhereManyInput>>>
}

export type AssetWhereOneInput = {
  id?: Maybe<Scalars['Int']>
}

export type AssetsConnection = {
  __typename?: 'AssetsConnection'
  assets?: Maybe<Array<Maybe<Asset>>>
  count?: Maybe<Scalars['Int']>
}

export type AssetsOrderByInput = {
  order?: Maybe<OrderBy>
  updatedAt?: Maybe<OrderBy>
  createdAt?: Maybe<OrderBy>
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type CompaniesConnection = {
  __typename?: 'CompaniesConnection'
  companies?: Maybe<Array<Maybe<Company>>>
  count?: Maybe<Scalars['Int']>
}

export type CompaniesOrderByInput = {
  name?: Maybe<OrderBy>
  location?: Maybe<OrderBy>
  updatedAt?: Maybe<OrderBy>
  createdAt?: Maybe<OrderBy>
}

export type Company = {
  __typename?: 'Company'
  id?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
  logo?: Maybe<Asset>
  name?: Maybe<Scalars['String']>
  introduction?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
  visibility?: Maybe<CompanyVisibility>
  status?: Maybe<CompanyStatus>
  address?: Maybe<Scalars['String']>
  location?: Maybe<Location>
  radius?: Maybe<Scalars['Int']>
  memberships?: Maybe<Array<Maybe<CompanyMembershipWithoutCompany>>>
  currentUserMembership?: Maybe<CompanyMembership>
  privacyPolicy?: Maybe<Array<Maybe<CompanyDocument>>>
  termsAndConditions?: Maybe<Array<Maybe<CompanyDocument>>>
  aboutUs?: Maybe<Array<Maybe<CompanyDocument>>>
  description?: Maybe<Array<Maybe<CompanyDocument>>>
  endpoint?: Maybe<Scalars['String']>
  space?: Maybe<Space>
  gender?: Maybe<Scalars['String']>
  ageFrom?: Maybe<Scalars['Int']>
  ageTo?: Maybe<Scalars['Int']>
  activeDateFrom?: Maybe<Scalars['String']>
  activeDateTo?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export type CompanyDocument = {
  __typename?: 'CompanyDocument'
  language: Scalars['String']
  default?: Maybe<Scalars['Boolean']>
  content: Scalars['String']
}

export type CompanyDocumentInput = {
  language: Scalars['String']
  default?: Maybe<Scalars['Boolean']>
  content: Scalars['String']
}

export type CompanyMembership = {
  __typename?: 'CompanyMembership'
  id?: Maybe<Scalars['Int']>
  user?: Maybe<User>
  company?: Maybe<Company>
  role?: Maybe<CompanyMembershipRole>
  title?: Maybe<Scalars['String']>
  status?: Maybe<CompanyMembershipStatus>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type CompanyMembershipOrderByInput = {
  role?: Maybe<OrderBy>
  updatedAt?: Maybe<OrderBy>
  createdAt?: Maybe<OrderBy>
}

export enum CompanyMembershipRole {
  User = 'USER',
  Employee = 'EMPLOYEE',
  KeyUser = 'KEY_USER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}

export type CompanyMembershipRoleWhereManyInput = {
  equals?: Maybe<CompanyMembershipRole>
  in?: Maybe<Array<Maybe<CompanyMembershipRole>>>
  notIn?: Maybe<Array<Maybe<CompanyMembershipRole>>>
}

export enum CompanyMembershipStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Requested = 'REQUESTED',
  Passive = 'PASSIVE',
  Banned = 'BANNED',
  Deleted = 'DELETED',
}

export type CompanyMembershipStatusWhereManyInput = {
  equals?: Maybe<CompanyMembershipStatus>
  in?: Maybe<Array<Maybe<CompanyMembershipStatus>>>
  notIn?: Maybe<Array<Maybe<CompanyMembershipStatus>>>
}

export type CompanyMembershipWhereManyInput = {
  company?: Maybe<CompanyWhereManyInput>
  user?: Maybe<UserWhereManyInput>
  role?: Maybe<CompanyMembershipRole>
  status?: Maybe<CompanyMembershipStatus>
  AND?: Maybe<Array<Maybe<CompanyMembershipWhereManyInput>>>
  OR?: Maybe<Array<Maybe<CompanyMembershipWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<CompanyMembershipWhereManyInput>>>
}

export type CompanyMembershipWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>
  user?: Maybe<UserWhereOneInput>
  company?: Maybe<CompanyWhereOneInput>
}

export type CompanyMembershipWhereUserInput = {
  role?: Maybe<CompanyMembershipRoleWhereManyInput>
  status?: Maybe<CompanyMembershipStatusWhereManyInput>
}

export type CompanyMembershipWithoutCompany = {
  __typename?: 'CompanyMembershipWithoutCompany'
  user?: Maybe<User>
  role?: Maybe<CompanyMembershipRole>
  status?: Maybe<CompanyMembershipStatus>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type CompanyMembershipsConnection = {
  __typename?: 'CompanyMembershipsConnection'
  memberships?: Maybe<Array<Maybe<CompanyMembership>>>
  count?: Maybe<Scalars['Int']>
}

export type CompanyPostMembership = {
  __typename?: 'CompanyPostMembership'
  id?: Maybe<Scalars['Int']>
  company?: Maybe<Company>
  post?: Maybe<Post>
  role?: Maybe<PostMembershipRole>
  status?: Maybe<CompanyPostMembershipStatus>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type CompanyPostMembershipOrderByInput = {
  status?: Maybe<OrderBy>
  updatedAt?: Maybe<OrderBy>
  createdAt?: Maybe<OrderBy>
}

export enum CompanyPostMembershipStatus {
  Invited = 'INVITED',
  Requested = 'REQUESTED',
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type CompanyPostMembershipWhereManyInput = {
  company?: Maybe<CompanyWhereManyInput>
  post?: Maybe<PostWhereManyInput>
  status?: Maybe<CompanyPostMembershipStatus>
  AND?: Maybe<Array<Maybe<CompanyMembershipWhereManyInput>>>
  OR?: Maybe<Array<Maybe<CompanyMembershipWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<CompanyMembershipWhereManyInput>>>
}

export type CompanyPostMembershipWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>
  post?: Maybe<PostWhereOneInput>
  company?: Maybe<CompanyWhereOneInput>
}

export type CompanyPostMembershipsConnection = {
  __typename?: 'CompanyPostMembershipsConnection'
  memberships?: Maybe<Array<Maybe<CompanyPostMembership>>>
  count?: Maybe<Scalars['Int']>
}

export enum CompanyStatus {
  Requested = 'REQUESTED',
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blocked = 'BLOCKED',
}

export type CompanyToDraftInput = {
  logo?: Maybe<AssetWhereOneInput>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  introduction?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  status?: Maybe<CompanyStatus>
  secondaryColor?: Maybe<Scalars['String']>
  privacyPolicy?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  termsAndConditions?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  aboutUs?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  endpoint: Scalars['String']
  superadminID: Scalars['Int']
  visibility?: Maybe<Scalars['String']>
  space?: Maybe<SpaceWhereOneInput>
  gender?: Maybe<Scalars['String']>
  ageFrom?: Maybe<Scalars['Int']>
  ageTo?: Maybe<Scalars['Int']>
  activeDateFrom?: Maybe<Scalars['String']>
  activeDateTo?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum CompanyVisibility {
  Open = 'OPEN',
  HalfOpen = 'HALF_OPEN',
  HalfClosed = 'HALF_CLOSED',
  Closed = 'CLOSED',
}

export type CompanyWhereManyInput = {
  id?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
  name?: Maybe<StringWhereManyInput>
  space?: Maybe<SpaceWhereOneInput>
  visibility?: Maybe<CompanyVisibility>
  location?: Maybe<LocationInput>
  gender?: Maybe<Scalars['String']>
  ageFrom?: Maybe<Scalars['Int']>
  ageTo?: Maybe<Scalars['Int']>
  activeDateFrom?: Maybe<Scalars['String']>
  activeDateTo?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  memberships?: Maybe<CompanyMembershipWhereManyInput>
  AND?: Maybe<Array<Maybe<CompanyWhereManyInput>>>
  OR?: Maybe<Array<Maybe<CompanyWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<CompanyWhereManyInput>>>
}

export type CompanyWhereOneInput = {
  id?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
}

export type CreateAssetInput = {
  order?: Maybe<Scalars['Int']>
  fileLocation: Scalars['String']
  fileName: Scalars['String']
  fileExtension: Scalars['String']
  fileSize: Scalars['Int']
}

export type CreateCompanyInput = {
  logo?: Maybe<AssetWhereOneInput>
  name: Scalars['String']
  introduction?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
  status?: Maybe<CompanyStatus>
  description?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  aboutUs?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  endpoint?: Maybe<Scalars['String']>
  visibility?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  radius?: Maybe<Scalars['Int']>
  location?: Maybe<LocationInput>
  space: SpaceWhereOneInput
  privacyPolicy?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  termsAndConditions?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  shouldCreateServer?: Maybe<Scalars['Boolean']>
  gender?: Maybe<Scalars['String']>
  ageFrom?: Maybe<Scalars['Int']>
  ageTo?: Maybe<Scalars['Int']>
  activeDateFrom?: Maybe<Scalars['String']>
  activeDateTo?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CreateCompanyMembershipInput = {
  userUUID?: Maybe<Scalars['String']>
  companyUUID: Scalars['String']
  status?: Maybe<CompanyMembershipStatus>
  role?: Maybe<CompanyMembershipRole>
}

export type CreatePostInput = {
  title?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  categoryID?: Maybe<Scalars['Int']>
  gender?: Maybe<Scalars['String']>
  minAge?: Maybe<Scalars['Int']>
  maxAge?: Maybe<Scalars['Int']>
  maxResponses?: Maybe<Scalars['Int']>
  maxDistance?: Maybe<Scalars['Int']>
  language?: Maybe<Scalars['String']>
  location?: Maybe<LocationInput>
  expiresAt?: Maybe<Scalars['String']>
  profileID?: Maybe<Scalars['Int']>
  aboutUs?: Maybe<Scalars['String']>
  ourServices?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type CreateSpaceInput = {
  logo?: Maybe<AssetWhereOneInput>
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  primaryColor: Scalars['String']
  secondaryColor?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
}

export type CreateUserInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  password: Scalars['String']
  languages?: Maybe<Array<Scalars['String']>>
  username: Scalars['String']
  securityQuestions: Array<Scalars['String']>
  securityAnswers: Scalars['String']
  gender?: Maybe<UserGender>
  birthDate?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
}

export type CurrentUser = {
  __typename?: 'CurrentUser'
  uuid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  avatar?: Maybe<Asset>
  securityQuestions?: Maybe<Array<Maybe<Scalars['String']>>>
  languages?: Maybe<Array<Maybe<Scalars['String']>>>
  role?: Maybe<UserRole>
  status?: Maybe<UserStatus>
  username?: Maybe<Scalars['String']>
  gender?: Maybe<UserGender>
  birthDate?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  memberships?: Maybe<Array<Maybe<CompanyMembership>>>
  postMemberships?: Maybe<Array<Maybe<PostMembership>>>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  securityAnswers?: Maybe<Scalars['String']>
}

export type CurrentUserPostMembershipsArgs = {
  type?: Maybe<Scalars['String']>
}

export type ExternalUserInput = {
  email?: Maybe<Scalars['String']>
  companyUUID: Scalars['String']
  companyMembershipRole?: Maybe<CompanyMembershipRole>
  chatID?: Maybe<Scalars['Int']>
  postID?: Maybe<Scalars['Int']>
}

export type FcMessage = {
  title: Scalars['String']
  body?: Maybe<Scalars['String']>
  data?: Maybe<FcMessageData>
}

export type FcMessageData = {
  companyId?: Maybe<Scalars['String']>
  companyUrl?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  announcementId?: Maybe<Scalars['String']>
  postId?: Maybe<Scalars['String']>
  chatRoomId?: Maybe<Scalars['String']>
}

export type Group = {
  __typename?: 'Group'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type GroupConnection = {
  __typename?: 'GroupConnection'
  groups?: Maybe<Array<Maybe<Group>>>
  count?: Maybe<Scalars['Int']>
}

export type GroupWhereManyInput = {
  id?: Maybe<Scalars['Int']>
  name?: Maybe<StringWhereManyInput>
  AND?: Maybe<Array<Maybe<GroupWhereManyInput>>>
  OR?: Maybe<Array<Maybe<GroupWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<GroupWhereManyInput>>>
}

export type IntArrWhereManyInput = {
  equals?: Maybe<Array<Maybe<Scalars['Int']>>>
  has?: Maybe<Scalars['Int']>
  hasEvery?: Maybe<Array<Maybe<Scalars['Int']>>>
  hasSome?: Maybe<Array<Maybe<Scalars['Int']>>>
  isEmpty?: Maybe<Scalars['Boolean']>
}

export type IntWhereManyInput = {
  gte?: Maybe<Scalars['Int']>
  gt?: Maybe<Scalars['Int']>
  lt?: Maybe<Scalars['Int']>
  lte?: Maybe<Scalars['Int']>
  equals?: Maybe<Scalars['Int']>
  in?: Maybe<Array<Maybe<Scalars['Int']>>>
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type Location = {
  __typename?: 'Location'
  lat: Scalars['Float']
  long: Scalars['Float']
}

export type LocationInput = {
  lat?: Maybe<Scalars['Float']>
  long?: Maybe<Scalars['Float']>
}

export type Mutation = {
  __typename?: 'Mutation'
  _empty?: Maybe<Scalars['String']>
  createUser?: Maybe<User>
  loginUser?: Maybe<UserLoginSuccess>
  registerUser?: Maybe<UserLoginSuccess>
  fcmTokenToUser?: Maybe<Scalars['String']>
  updateUser?: Maybe<User>
  deleteUser?: Maybe<Scalars['String']>
  updateCompany?: Maybe<Company>
  createCompany?: Maybe<Company>
  requestCompany?: Maybe<Company>
  companyToDraft?: Maybe<Company>
  updatePost?: Maybe<Post>
  createPost?: Maybe<Post>
  addPostMember?: Maybe<Scalars['String']>
  updatePostMember?: Maybe<Scalars['String']>
  removePostMember?: Maybe<Scalars['String']>
  requestCompanyPostMembership?: Maybe<Scalars['String']>
  createAsset?: Maybe<Asset>
  createAssets?: Maybe<Array<Maybe<Asset>>>
  deleteAsset?: Maybe<Scalars['String']>
  updateSpace?: Maybe<Space>
  createSpace?: Maybe<Space>
  createCompanyMembership?: Maybe<CompanyMembership>
  updateCompanyMembership?: Maybe<CompanyMembership>
  updateCompanyPostMembership?: Maybe<CompanyPostMembership>
  sendNotification?: Maybe<Scalars['Boolean']>
  inviteExternalUser?: Maybe<UserInvites>
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationLoginUserArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationRegisterUserArgs = {
  input: CreateUserInput
}

export type MutationFcmTokenToUserArgs = {
  token: Scalars['String']
}

export type MutationUpdateUserArgs = {
  where?: Maybe<UserWhereOneInput>
  input: UpdateUserInput
}

export type MutationDeleteUserArgs = {
  where?: Maybe<UserWhereOneInput>
}

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput
  where: CompanyWhereOneInput
}

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
}

export type MutationRequestCompanyArgs = {
  input: RequestCompanyInput
}

export type MutationCompanyToDraftArgs = {
  input: CompanyToDraftInput
  where: CompanyWhereOneInput
}

export type MutationUpdatePostArgs = {
  input: UpdatePostInput
  where: PostWhereOneInput
}

export type MutationCreatePostArgs = {
  input: CreatePostInput
}

export type MutationAddPostMemberArgs = {
  input: AddPostMemberInput
}

export type MutationUpdatePostMemberArgs = {
  input: UpdatePostMemberInput
}

export type MutationRemovePostMemberArgs = {
  input: RemovePostMemberInput
}

export type MutationRequestCompanyPostMembershipArgs = {
  input: RequestCompanyPostMembershipInput
}

export type MutationCreateAssetArgs = {
  input: CreateAssetInput
}

export type MutationCreateAssetsArgs = {
  input: Array<CreateAssetInput>
}

export type MutationDeleteAssetArgs = {
  where: AssetWhereOneInput
}

export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput
  where: AssetWhereOneInput
}

export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput
}

export type MutationCreateCompanyMembershipArgs = {
  input: CreateCompanyMembershipInput
}

export type MutationUpdateCompanyMembershipArgs = {
  input: UpdateCompanyMembershipInput
}

export type MutationUpdateCompanyPostMembershipArgs = {
  input: UpdateCompanyPostMembershipInput
}

export type MutationSendNotificationArgs = {
  uuids?: Maybe<Array<Maybe<Scalars['String']>>>
  message: FcMessage
}

export type MutationInviteExternalUserArgs = {
  input: ExternalUserInput
}

export type MySpaceWhereManyInput = {
  id?: Maybe<IntWhereManyInput>
  name?: Maybe<StringWhereManyInput>
  AND?: Maybe<Array<Maybe<SpaceWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<SpaceWhereManyInput>>>
}

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc',
}

export type Post = {
  __typename?: 'Post'
  id?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  referenceNumber?: Maybe<Scalars['String']>
  providerId?: Maybe<Scalars['Int']>
  group?: Maybe<Scalars['String']>
  ageRange?: Maybe<Scalars['String']>
  priceNet?: Maybe<Scalars['Int']>
  priceGross?: Maybe<Scalars['Int']>
  currency?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  quantityName?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['Int']>
  discountMinus?: Maybe<Scalars['Int']>
  discountUntil?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  bookmarksCount?: Maybe<Scalars['Int']>
  categoryID?: Maybe<Scalars['Int']>
  gender?: Maybe<Scalars['String']>
  minAge?: Maybe<Scalars['Int']>
  maxAge?: Maybe<Scalars['Int']>
  maxResponses?: Maybe<Scalars['Int']>
  maxDistance?: Maybe<Scalars['Int']>
  locationRelevant?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  expiresAt?: Maybe<Scalars['String']>
  profileID?: Maybe<Scalars['Int']>
  bio?: Maybe<Scalars['String']>
  avatar?: Maybe<Asset>
  aboutUs?: Maybe<Array<Maybe<PostDocument>>>
  ourServices?: Maybe<Array<Maybe<PostDocument>>>
  location?: Maybe<Location>
  address?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  status?: Maybe<PostStatus>
  membershipsConnection?: Maybe<PostMembershipsConnection>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export type PostMembershipsConnectionArgs = {
  where?: Maybe<PostMembershipWhereManyInput>
  orderBy?: Maybe<PostMembershipOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type PostDocument = {
  __typename?: 'PostDocument'
  language: Scalars['String']
  default?: Maybe<Scalars['Boolean']>
  content: Scalars['String']
}

export type PostDocumentInput = {
  language: Scalars['String']
  default?: Maybe<Scalars['Boolean']>
  content: Scalars['String']
}

export type PostMembership = {
  __typename?: 'PostMembership'
  user?: Maybe<User>
  post?: Maybe<Post>
  role?: Maybe<PostMembershipRole>
  status?: Maybe<PostMembershipStatus>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type PostMembershipOrderByInput = {
  role?: Maybe<PostMembershipRole>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export enum PostMembershipRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

export enum PostMembershipStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
  Deleted = 'DELETED',
}

export type PostMembershipWhereManyInput = {
  post?: Maybe<PostWhereOneInput>
  user?: Maybe<UserWhereOneInput>
  role?: Maybe<PostMembershipRole>
  AND?: Maybe<Array<Maybe<PostMembershipWhereManyInput>>>
  OR?: Maybe<Array<Maybe<PostMembershipWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<PostMembershipWhereManyInput>>>
}

export type PostMembershipsConnection = {
  __typename?: 'PostMembershipsConnection'
  memberships?: Maybe<Array<Maybe<PostMembership>>>
  count?: Maybe<Scalars['Int']>
}

export enum PostStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type PostWhereManyInput = {
  id?: Maybe<Scalars['Int']>
  username?: Maybe<StringWhereManyInput>
  name?: Maybe<StringWhereManyInput>
  type?: Maybe<Scalars['String']>
  providerID?: Maybe<Scalars['Int']>
  producerID?: Maybe<Scalars['Int']>
  referenceNumber?: Maybe<StringWhereManyInput>
  group?: Maybe<StringWhereManyInput>
  AND?: Maybe<Array<Maybe<PostWhereManyInput>>>
  OR?: Maybe<Array<Maybe<PostWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<PostWhereManyInput>>>
}

export type PostWhereOneInput = {
  id?: Maybe<Scalars['Int']>
  username?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  providerId?: Maybe<Scalars['Int']>
}

export type PostsConnection = {
  __typename?: 'PostsConnection'
  posts?: Maybe<Array<Maybe<Post>>>
  count?: Maybe<Scalars['Int']>
}

export type PostsOrderByInput = {
  name?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export enum ProfileMembershipRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

export type Query = {
  __typename?: 'Query'
  _empty?: Maybe<Scalars['String']>
  currentUser?: Maybe<CurrentUser>
  isUserRegistered?: Maybe<Scalars['Boolean']>
  securityQuestions?: Maybe<Array<Maybe<Scalars['String']>>>
  user?: Maybe<User>
  usersConnection?: Maybe<UsersConnection>
  userInvite?: Maybe<UserInvites>
  company?: Maybe<Company>
  companiesConnection?: Maybe<CompaniesConnection>
  post?: Maybe<Post>
  postsConnection?: Maybe<PostsConnection>
  group?: Maybe<Group>
  groupsConnection?: Maybe<GroupConnection>
  space?: Maybe<Space>
  spaces?: Maybe<Array<Maybe<Space>>>
  mySpaces?: Maybe<Array<Maybe<Space>>>
  companyMembership?: Maybe<CompanyMembership>
  companyMembershipsConnection?: Maybe<CompanyMembershipsConnection>
  companyPostMembership?: Maybe<CompanyPostMembership>
  companyPostMembershipsConnection?: Maybe<CompanyPostMembershipsConnection>
}

export type QueryIsUserRegisteredArgs = {
  email?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
}

export type QuerySecurityQuestionsArgs = {
  email: Scalars['String']
}

export type QueryUserArgs = {
  where: UserWhereOneInput
}

export type QueryUsersConnectionArgs = {
  where?: Maybe<UserWhereManyInput>
  orderBy?: Maybe<UsersOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryUserInviteArgs = {
  where: UserInvitesWhereOneInput
}

export type QueryCompanyArgs = {
  where: CompanyWhereOneInput
}

export type QueryCompaniesConnectionArgs = {
  where?: Maybe<CompanyWhereManyInput>
  orderBy?: Maybe<CompaniesOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryPostArgs = {
  where: PostWhereOneInput
}

export type QueryPostsConnectionArgs = {
  where?: Maybe<PostWhereManyInput>
  orderBy?: Maybe<PostsOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryGroupArgs = {
  where: GroupWhereManyInput
}

export type QueryGroupsConnectionArgs = {
  where?: Maybe<GroupWhereManyInput>
}

export type QuerySpaceArgs = {
  where: SpaceWhereOneInput
}

export type QuerySpacesArgs = {
  where?: Maybe<SpaceWhereManyInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryMySpacesArgs = {
  where?: Maybe<MySpaceWhereManyInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryCompanyMembershipArgs = {
  where: CompanyMembershipWhereUniqueInput
}

export type QueryCompanyMembershipsConnectionArgs = {
  where?: Maybe<CompanyMembershipWhereManyInput>
  orderBy?: Maybe<CompanyMembershipOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryCompanyPostMembershipArgs = {
  where: CompanyPostMembershipWhereUniqueInput
}

export type QueryCompanyPostMembershipsConnectionArgs = {
  where?: Maybe<CompanyPostMembershipWhereManyInput>
  orderBy?: Maybe<CompanyPostMembershipOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type RemovePostMemberInput = {
  post: PostWhereOneInput
  user: UserWhereOneInput
}

export type RequestCompanyInput = {
  logo?: Maybe<AssetWhereOneInput>
  name: Scalars['String']
  introduction: Scalars['String']
  primaryColor: Scalars['String']
  secondaryColor?: Maybe<Scalars['String']>
  status: CompanyStatus
  description?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  aboutUs?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  endpoint?: Maybe<Scalars['String']>
  visibility?: Maybe<Scalars['String']>
  radius?: Maybe<Scalars['Int']>
  address?: Maybe<Scalars['String']>
  location?: Maybe<LocationInput>
  space: SpaceWhereOneInput
  gender?: Maybe<Scalars['String']>
  ageFrom?: Maybe<Scalars['Int']>
  ageTo?: Maybe<Scalars['Int']>
  activeDateFrom?: Maybe<Scalars['String']>
  activeDateTo?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type RequestCompanyPostMembershipInput = {
  post: PostWhereOneInput
  company: CompanyWhereOneInput
  status?: Maybe<CompanyPostMembershipStatus>
}

export type Space = {
  __typename?: 'Space'
  id?: Maybe<Scalars['Int']>
  logo?: Maybe<Asset>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  companies?: Maybe<Array<Maybe<Company>>>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export type SpaceWhereManyInput = {
  id?: Maybe<IntWhereManyInput>
  name?: Maybe<StringWhereManyInput>
  company?: Maybe<CompanyWhereManyInput>
  AND?: Maybe<Array<Maybe<SpaceWhereManyInput>>>
  OR?: Maybe<Array<Maybe<SpaceWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<SpaceWhereManyInput>>>
}

export type SpaceWhereOneInput = {
  id: Scalars['Int']
}

export type StringArrWhereManyInput = {
  equals?: Maybe<Array<Maybe<Scalars['String']>>>
  has?: Maybe<Scalars['String']>
  hasEvery?: Maybe<Array<Maybe<Scalars['String']>>>
  hasSome?: Maybe<Array<Maybe<Scalars['String']>>>
  isEmpty?: Maybe<Scalars['Boolean']>
}

export type StringWhereManyInput = {
  contains?: Maybe<Scalars['String']>
  startsWith?: Maybe<Scalars['String']>
  endsWith?: Maybe<Scalars['String']>
  equals?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UpdateCompanyInput = {
  logo?: Maybe<AssetWhereOneInput>
  name?: Maybe<Scalars['String']>
  introduction?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  status?: Maybe<CompanyStatus>
  secondaryColor?: Maybe<Scalars['String']>
  privacyPolicy?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  termsAndConditions?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  aboutUs?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  description?: Maybe<Array<Maybe<CompanyDocumentInput>>>
  endpoint?: Maybe<Scalars['String']>
  visibility?: Maybe<Scalars['String']>
  space?: Maybe<SpaceWhereOneInput>
  address?: Maybe<Scalars['String']>
  radius?: Maybe<Scalars['Int']>
  location?: Maybe<LocationInput>
  shouldCreateServer?: Maybe<Scalars['Boolean']>
  gender?: Maybe<Scalars['String']>
  ageFrom?: Maybe<Scalars['Int']>
  ageTo?: Maybe<Scalars['Int']>
  activeDateFrom?: Maybe<Scalars['String']>
  activeDateTo?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UpdateCompanyMembershipInput = {
  userUUID?: Maybe<Scalars['String']>
  companyUUID: Scalars['String']
  status?: Maybe<CompanyMembershipStatus>
  role?: Maybe<CompanyMembershipRole>
}

export type UpdateCompanyPostMembershipInput = {
  post: PostWhereOneInput
  company: CompanyWhereOneInput
  status?: Maybe<CompanyPostMembershipStatus>
}

export type UpdatePostInput = {
  avatar?: Maybe<AssetWhereOneInput>
  name?: Maybe<Scalars['String']>
  referenceNumber?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  aboutUs?: Maybe<Array<Maybe<PostDocumentInput>>>
  ourServices?: Maybe<Array<Maybe<PostDocumentInput>>>
  location?: Maybe<LocationInput>
  address?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type UpdatePostMemberInput = {
  post: PostWhereOneInput
  user?: Maybe<UserWhereOneInput>
  status?: Maybe<PostMembershipStatus>
  role?: Maybe<PostMembershipRole>
}

export type UpdateSpaceInput = {
  logo?: Maybe<AssetWhereOneInput>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  secondaryColor?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
}

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  avatar?: Maybe<AssetWhereOneInput>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  role?: Maybe<UserRole>
  status?: Maybe<UserStatus>
  gender?: Maybe<UserGender>
  languages?: Maybe<Array<Maybe<Scalars['String']>>>
  birthDate?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  uuid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  avatar?: Maybe<Asset>
  securityQuestions?: Maybe<Array<Maybe<Scalars['String']>>>
  languages?: Maybe<Array<Maybe<Scalars['String']>>>
  role?: Maybe<UserRole>
  title?: Maybe<Scalars['String']>
  status?: Maybe<UserStatus>
  username?: Maybe<Scalars['String']>
  gender?: Maybe<UserGender>
  birthDate?: Maybe<Scalars['String']>
  memberships?: Maybe<Array<Maybe<CompanyMembership>>>
  postMemberships?: Maybe<Array<Maybe<PostMembership>>>
  bio?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
}

export type UserInvites = {
  __typename?: 'UserInvites'
  id?: Maybe<Scalars['Int']>
  email?: Maybe<Scalars['String']>
  registrationCode?: Maybe<Scalars['String']>
  chatID?: Maybe<Scalars['Int']>
  postUUID?: Maybe<Scalars['String']>
  companyUUID?: Maybe<Scalars['String']>
  companyMembershipRole?: Maybe<CompanyMembershipRole>
  postMembershipRole?: Maybe<PostMembershipRole>
  companyURL?: Maybe<Scalars['String']>
  invitationFromID?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export type UserInvitesWhereOneInput = {
  registrationCode?: Maybe<Scalars['String']>
}

export type UserLoginSuccess = {
  __typename?: 'UserLoginSuccess'
  user: User
  authorization: Scalars['String']
}

export enum UserRole {
  User = 'USER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}

export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Passive = 'PASSIVE',
  Banned = 'BANNED',
  Registered = 'REGISTERED',
  Deleted = 'DELETED',
}

export type UserWhereManyInput = {
  uuid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  email?: Maybe<StringWhereManyInput>
  firstName?: Maybe<StringWhereManyInput>
  lastName?: Maybe<StringWhereManyInput>
  status?: Maybe<UserStatus>
  role?: Maybe<UserRole>
  AND?: Maybe<Array<Maybe<UserWhereManyInput>>>
  OR?: Maybe<Array<Maybe<UserWhereManyInput>>>
  NOT?: Maybe<Array<Maybe<UserWhereManyInput>>>
}

export type UserWhereOneInput = {
  uuid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  email?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
}

export type UsersConnection = {
  __typename?: 'UsersConnection'
  users?: Maybe<Array<Maybe<User>>>
  count?: Maybe<Scalars['Int']>
}

export type UsersOrderByInput = {
  firstName?: Maybe<OrderBy>
  lastName?: Maybe<OrderBy>
  email?: Maybe<OrderBy>
  updatedAt?: Maybe<OrderBy>
  createdAt?: Maybe<OrderBy>
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'CurrentUser' } & Pick<
      CurrentUser,
      | 'id'
      | 'firstName'
      | 'lastName'
      | 'fullName'
      | 'email'
      | 'languages'
      | 'role'
      | 'bio'
      | 'birthDate'
      | 'gender'
      | 'username'
      | 'status'
    > & {
        avatar?: Maybe<
          { __typename?: 'Asset' } & Pick<
            Asset,
            'id' | 'fileName' | 'fileSize' | 'fileLocation' | 'fileExtension'
          >
        >
      }
  >
}

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type LoginUserMutation = { __typename?: 'Mutation' } & {
  loginUser?: Maybe<
    { __typename?: 'UserLoginSuccess' } & Pick<UserLoginSuccess, 'authorization'> & {
        user: { __typename?: 'User' } & Pick<User, 'id' | 'role'>
      }
  >
}

export type SignupMutationVariables = Exact<{
  input: CreateUserInput
}>

export type SignupMutation = { __typename?: 'Mutation' } & {
  registerUser?: Maybe<
    { __typename?: 'UserLoginSuccess' } & Pick<UserLoginSuccess, 'authorization'> & {
        user: { __typename?: 'User' } & Pick<User, 'id'>
      }
  >
}

export type CreateAssetMutationVariables = Exact<{
  fileLocation: Scalars['String']
  fileName: Scalars['String']
  fileExtension: Scalars['String']
  fileSize: Scalars['Int']
  order?: Maybe<Scalars['Int']>
}>

export type CreateAssetMutation = { __typename?: 'Mutation' } & {
  createAsset?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
}

export type CreateCompanyMemberMutationVariables = Exact<{
  input: CreateCompanyMembershipInput
}>

export type CreateCompanyMemberMutation = { __typename?: 'Mutation' } & {
  createCompanyMembership?: Maybe<
    { __typename?: 'CompanyMembership' } & Pick<CompanyMembership, 'role' | 'status'> & {
        company?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'id' | 'uuid'>>
        user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'uuid'>>
      }
  >
}

export type UpdateCompanyMemberMutationVariables = Exact<{
  input: UpdateCompanyMembershipInput
}>

export type UpdateCompanyMemberMutation = { __typename?: 'Mutation' } & {
  updateCompanyMembership?: Maybe<
    { __typename?: 'CompanyMembership' } & Pick<CompanyMembership, 'role' | 'status'> & {
        company?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'id' | 'uuid'>>
        user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'uuid'>>
      }
  >
}

export type UserBasicQueryVariables = Exact<{
  where: UserWhereOneInput
}>

export type UserBasicQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'uuid' | 'fullName' | 'email' | 'role' | 'status'>
  >
}

export type CompanyMemberListQueryVariables = Exact<{
  where: CompanyWhereOneInput
}>

export type CompanyMemberListQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'> & {
        memberships?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyMembershipWithoutCompany' } & Pick<
                CompanyMembershipWithoutCompany,
                'role' | 'status' | 'createdAt' | 'updatedAt'
              > & {
                  user?: Maybe<
                    { __typename?: 'User' } & Pick<
                      User,
                      'id' | 'uuid' | 'username' | 'fullName' | 'email' | 'status'
                    >
                  >
                }
            >
          >
        >
      }
  >
}

export type CompanyToDraftMutationVariables = Exact<{
  where: CompanyWhereOneInput
  input: CompanyToDraftInput
}>

export type CompanyToDraftMutation = { __typename?: 'Mutation' } & {
  companyToDraft?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | 'id'
      | 'uuid'
      | 'name'
      | 'introduction'
      | 'primaryColor'
      | 'secondaryColor'
      | 'visibility'
      | 'status'
      | 'endpoint'
      | 'updatedAt'
      | 'createdAt'
    > & {
        description?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id'>>
        space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id'>>
      }
  >
}

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput
}>

export type CreateCompanyMutation = { __typename?: 'Mutation' } & {
  createCompany?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'visibility' | 'status'>
  >
}

export type RequestCompanyMutationVariables = Exact<{
  input: RequestCompanyInput
}>

export type RequestCompanyMutation = { __typename?: 'Mutation' } & {
  requestCompany?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | 'id'
      | 'uuid'
      | 'name'
      | 'primaryColor'
      | 'visibility'
      | 'status'
      | 'endpoint'
      | 'radius'
      | 'address'
    > & {
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>>
        logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileName' | 'fileLocation'>>
        space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id' | 'name'>>
      }
  >
}

export type RequestedCompanyFragment = { __typename?: 'Company' } & Pick<
  Company,
  | 'id'
  | 'name'
  | 'introduction'
  | 'primaryColor'
  | 'secondaryColor'
  | 'visibility'
  | 'status'
  | 'endpoint'
> & {
    description?: Maybe<
      Array<
        Maybe<
          { __typename?: 'CompanyDocument' } & Pick<
            CompanyDocument,
            'content' | 'language' | 'default'
          >
        >
      >
    >
    space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id'>>
  }

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput
  where: CompanyWhereOneInput
}>

export type UpdateCompanyMutation = { __typename?: 'Mutation' } & {
  updateCompany?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | 'id'
      | 'name'
      | 'introduction'
      | 'primaryColor'
      | 'secondaryColor'
      | 'visibility'
      | 'status'
      | 'radius'
      | 'address'
      | 'endpoint'
    > & {
        description?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>>
        space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id' | 'name'>>
        logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileName'>>
      }
  >
}

export type PrivacyPolicyQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type PrivacyPolicyQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        privacyPolicy?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'default' | 'language'
              >
            >
          >
        >
      }
  >
}

export type TermsConditionsQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type TermsConditionsQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        termsAndConditions?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'default' | 'language'
              >
            >
          >
        >
      }
  >
}

export type UpdatePrivacyPolicyMutationVariables = Exact<{
  input: UpdateCompanyInput
  where: CompanyWhereOneInput
}>

export type UpdatePrivacyPolicyMutation = { __typename?: 'Mutation' } & {
  updateCompany?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'introduction' | 'name'> & {
        description?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        privacyPolicy?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'default' | 'language'
              >
            >
          >
        >
      }
  >
}

export type UpdateTermsConditionsMutationVariables = Exact<{
  input: UpdateCompanyInput
  where: CompanyWhereOneInput
}>

export type UpdateTermsConditionsMutation = { __typename?: 'Mutation' } & {
  updateCompany?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'name'> & {
        description?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'default' | 'language'
              >
            >
          >
        >
        termsAndConditions?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'default' | 'language'
              >
            >
          >
        >
      }
  >
}

export type CompanyQueryVariables = Exact<{
  where: CompanyWhereOneInput
}>

export type CompanyQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | 'id'
      | 'uuid'
      | 'name'
      | 'introduction'
      | 'primaryColor'
      | 'secondaryColor'
      | 'visibility'
      | 'status'
      | 'endpoint'
      | 'createdAt'
      | 'updatedAt'
      | 'radius'
      | 'address'
      | 'gender'
      | 'ageFrom'
      | 'ageTo'
      | 'activeDateFrom'
      | 'activeDateTo'
      | 'language'
    > & {
        description?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>>
        aboutUs?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyDocument' } & Pick<
                CompanyDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
        space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id' | 'name'>>
      }
  >
}

export type UpdatePostMutationVariables = Exact<{
  input: UpdatePostInput
  where: PostWhereOneInput
}>

export type UpdatePostMutation = { __typename?: 'Mutation' } & {
  updatePost?: Maybe<
    { __typename?: 'Post' } & Pick<
      Post,
      | 'id'
      | 'uuid'
      | 'name'
      | 'username'
      | 'bio'
      | 'phone'
      | 'email'
      | 'createdAt'
      | 'updatedAt'
      | 'address'
    > & {
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>>
        aboutUs?: Maybe<
          Array<
            Maybe<
              { __typename?: 'PostDocument' } & Pick<
                PostDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        ourServices?: Maybe<
          Array<
            Maybe<
              { __typename?: 'PostDocument' } & Pick<
                PostDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        avatar?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
        membershipsConnection?: Maybe<
          { __typename?: 'PostMembershipsConnection' } & Pick<
            PostMembershipsConnection,
            'count'
          > & {
              memberships?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'PostMembership' } & Pick<PostMembership, 'role' | 'status'> & {
                        user?: Maybe<
                          { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email'>
                        >
                      }
                  >
                >
              >
            }
        >
      }
  >
}

export type PostQueryVariables = Exact<{
  where: PostWhereOneInput
}>

export type PostQuery = { __typename?: 'Query' } & {
  post?: Maybe<
    { __typename?: 'Post' } & Pick<
      Post,
      | 'id'
      | 'uuid'
      | 'name'
      | 'username'
      | 'bio'
      | 'phone'
      | 'email'
      | 'createdAt'
      | 'updatedAt'
      | 'address'
    > & {
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>>
        aboutUs?: Maybe<
          Array<
            Maybe<
              { __typename?: 'PostDocument' } & Pick<
                PostDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        ourServices?: Maybe<
          Array<
            Maybe<
              { __typename?: 'PostDocument' } & Pick<
                PostDocument,
                'content' | 'language' | 'default'
              >
            >
          >
        >
        avatar?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
        membershipsConnection?: Maybe<
          { __typename?: 'PostMembershipsConnection' } & Pick<
            PostMembershipsConnection,
            'count'
          > & {
              memberships?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'PostMembership' } & Pick<PostMembership, 'role' | 'status'> & {
                        user?: Maybe<
                          { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email'>
                        >
                      }
                  >
                >
              >
            }
        >
      }
  >
}

export type CreateSpaceMutationVariables = Exact<{
  input: CreateSpaceInput
}>

export type CreateSpaceMutation = { __typename?: 'Mutation' } & {
  createSpace?: Maybe<
    { __typename?: 'Space' } & Pick<
      Space,
      'id' | 'name' | 'description' | 'primaryColor' | 'secondaryColor' | 'icon'
    > & {
        logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
        companies?: Maybe<
          Array<Maybe<{ __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'>>>
        >
      }
  >
}

export type UpdateSpaceMutationVariables = Exact<{
  input: UpdateSpaceInput
  where: AssetWhereOneInput
}>

export type UpdateSpaceMutation = { __typename?: 'Mutation' } & {
  updateSpace?: Maybe<
    { __typename?: 'Space' } & Pick<
      Space,
      | 'id'
      | 'name'
      | 'description'
      | 'primaryColor'
      | 'secondaryColor'
      | 'icon'
      | 'createdAt'
      | 'updatedAt'
    >
  >
}

export type SpaceQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type SpaceQuery = { __typename?: 'Query' } & {
  space?: Maybe<
    { __typename?: 'Space' } & Pick<
      Space,
      | 'id'
      | 'name'
      | 'description'
      | 'primaryColor'
      | 'secondaryColor'
      | 'icon'
      | 'createdAt'
      | 'updatedAt'
    >
  >
}

export type UpdateUserStatusMutationVariables = Exact<{
  input: UpdateUserInput
  where: UserWhereOneInput
}>

export type UpdateUserStatusMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'uuid' | 'status' | 'role'>>
}

export type UsersCompanyListQueryVariables = Exact<{
  where?: Maybe<CompanyWhereManyInput>
  orderBy?: Maybe<CompaniesOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type UsersCompanyListQuery = { __typename?: 'Query' } & {
  companiesConnection?: Maybe<
    { __typename?: 'CompaniesConnection' } & Pick<CompaniesConnection, 'count'> & {
        companies?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'> & {
                  space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id' | 'name'>>
                  memberships?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'CompanyMembershipWithoutCompany' } & Pick<
                          CompanyMembershipWithoutCompany,
                          'role' | 'status'
                        > & {
                            user?: Maybe<
                              { __typename?: 'User' } & Pick<User, 'id' | 'uuid' | 'fullName'>
                            >
                          }
                      >
                    >
                  >
                }
            >
          >
        >
      }
  >
}

export type UserQueryVariables = Exact<{
  where: UserWhereOneInput
}>

export type UserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'uuid'
      | 'fullName'
      | 'email'
      | 'role'
      | 'status'
      | 'username'
      | 'gender'
      | 'birthDate'
      | 'bio'
      | 'createdAt'
      | 'updatedAt'
    > & {
        avatar?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
        memberships?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyMembership' } & Pick<CompanyMembership, 'role' | 'status'> & {
                  company?: Maybe<
                    { __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'>
                  >
                }
            >
          >
        >
      }
  >
}

export type CompanyListQueryVariables = Exact<{
  where?: Maybe<CompanyWhereManyInput>
  orderBy?: Maybe<CompaniesOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type CompanyListQuery = { __typename?: 'Query' } & {
  companiesConnection?: Maybe<
    { __typename?: 'CompaniesConnection' } & Pick<CompaniesConnection, 'count'> & {
        companies?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                | 'id'
                | 'uuid'
                | 'name'
                | 'primaryColor'
                | 'visibility'
                | 'endpoint'
                | 'status'
                | 'radius'
              > & {
                  space?: Maybe<{ __typename?: 'Space' } & Pick<Space, 'id' | 'name'>>
                  logo?: Maybe<
                    { __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileName' | 'fileLocation'>
                  >
                }
            >
          >
        >
      }
  >
}

export type PostListQueryVariables = Exact<{
  where?: Maybe<PostWhereManyInput>
  orderBy?: Maybe<PostsOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type PostListQuery = { __typename?: 'Query' } & {
  postsConnection?: Maybe<
    { __typename?: 'PostsConnection' } & Pick<PostsConnection, 'count'> & {
        posts?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Post' } & Pick<
                Post,
                'id' | 'uuid' | 'name' | 'username' | 'bio' | 'address' | 'phone' | 'email'
              > & {
                  avatar?: Maybe<
                    { __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileName' | 'fileLocation'>
                  >
                  aboutUs?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'PostDocument' } & Pick<
                          PostDocument,
                          'language' | 'default' | 'content'
                        >
                      >
                    >
                  >
                  ourServices?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'PostDocument' } & Pick<
                          PostDocument,
                          'language' | 'default' | 'content'
                        >
                      >
                    >
                  >
                  location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'lat' | 'long'>>
                }
            >
          >
        >
      }
  >
}

export type SpaceListQueryVariables = Exact<{
  where?: Maybe<SpaceWhereManyInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type SpaceListQuery = { __typename?: 'Query' } & {
  spaces?: Maybe<Array<Maybe<{ __typename?: 'Space' } & Pick<Space, 'id'>>>>
}

export type SpacesQueryVariables = Exact<{
  where?: Maybe<SpaceWhereManyInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type SpacesQuery = { __typename?: 'Query' } & {
  spaces?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Space' } & Pick<
          Space,
          'id' | 'name' | 'description' | 'primaryColor' | 'secondaryColor' | 'icon'
        > & {
            logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'id' | 'fileLocation'>>
            companies?: Maybe<
              Array<Maybe<{ __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'>>>
            >
          }
      >
    >
  >
}

export type CompanyMembersQueryVariables = Exact<{
  where?: Maybe<CompanyWhereManyInput>
  orderBy?: Maybe<CompaniesOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type CompanyMembersQuery = { __typename?: 'Query' } & {
  companiesConnection?: Maybe<
    { __typename?: 'CompaniesConnection' } & Pick<CompaniesConnection, 'count'> & {
        companies?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'> & {
                  memberships?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'CompanyMembershipWithoutCompany' } & Pick<
                          CompanyMembershipWithoutCompany,
                          'role' | 'status'
                        > & {
                            user?: Maybe<
                              { __typename?: 'User' } & Pick<
                                User,
                                'id' | 'uuid' | 'role' | 'status'
                              >
                            >
                          }
                      >
                    >
                  >
                }
            >
          >
        >
      }
  >
}

export type CompanyMembershipsQueryVariables = Exact<{
  where?: Maybe<CompanyMembershipWhereManyInput>
  orderBy?: Maybe<CompanyMembershipOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type CompanyMembershipsQuery = { __typename?: 'Query' } & {
  companyMembershipsConnection?: Maybe<
    { __typename?: 'CompanyMembershipsConnection' } & Pick<
      CompanyMembershipsConnection,
      'count'
    > & {
        memberships?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CompanyMembership' } & Pick<
                CompanyMembership,
                'id' | 'role' | 'status' | 'createdAt' | 'updatedAt'
              > & {
                  user?: Maybe<
                    { __typename?: 'User' } & Pick<
                      User,
                      'id' | 'uuid' | 'username' | 'fullName' | 'email' | 'role' | 'status'
                    >
                  >
                  company?: Maybe<
                    { __typename?: 'Company' } & Pick<Company, 'id' | 'uuid' | 'name'>
                  >
                }
            >
          >
        >
      }
  >
}

export type UserListQueryVariables = Exact<{
  where?: Maybe<UserWhereManyInput>
  orderBy?: Maybe<UsersOrderByInput>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}>

export type UserListQuery = { __typename?: 'Query' } & {
  usersConnection?: Maybe<
    { __typename?: 'UsersConnection' } & Pick<UsersConnection, 'count'> & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: 'User' } & Pick<
                User,
                'id' | 'username' | 'fullName' | 'email' | 'role' | 'status'
              >
            >
          >
        >
      }
  >
}

export const RequestedCompanyFragmentDoc = gql`
  fragment requestedCompany on Company {
    id
    name
    description {
      content
      language
      default
    }
    introduction
    primaryColor
    secondaryColor
    visibility
    status
    endpoint
    space {
      id
    }
  }
`
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      email
      languages
      role
      bio
      birthDate
      gender
      username
      status
      avatar {
        id
        fileName
        fileSize
        fileLocation
        fileExtension
      }
    }
  }
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
export const LoginUserDocument = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(password: $password, email: $email) {
      authorization
      user {
        id
        role
      }
    }
  }
`
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options
  )
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>
export const SignupDocument = gql`
  mutation signup($input: CreateUserInput!) {
    registerUser(input: $input) {
      user {
        id
      }
      authorization
    }
  }
`
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options)
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>
export const CreateAssetDocument = gql`
  mutation CreateAsset(
    $fileLocation: String!
    $fileName: String!
    $fileExtension: String!
    $fileSize: Int!
    $order: Int
  ) {
    createAsset(
      input: {
        fileLocation: $fileLocation
        fileName: $fileName
        fileExtension: $fileExtension
        fileSize: $fileSize
        order: $order
      }
    ) {
      id
      fileLocation
    }
  }
`
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      fileLocation: // value for 'fileLocation'
 *      fileName: // value for 'fileName'
 *      fileExtension: // value for 'fileExtension'
 *      fileSize: // value for 'fileSize'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options
  )
}
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>
export const CreateCompanyMemberDocument = gql`
  mutation CreateCompanyMember($input: CreateCompanyMembershipInput!) {
    createCompanyMembership(input: $input) {
      role
      status
      company {
        id
        uuid
      }
      user {
        id
        uuid
      }
    }
  }
`
export type CreateCompanyMemberMutationFn = Apollo.MutationFunction<
  CreateCompanyMemberMutation,
  CreateCompanyMemberMutationVariables
>

/**
 * __useCreateCompanyMemberMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMemberMutation, { data, loading, error }] = useCreateCompanyMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMemberMutation,
    CreateCompanyMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMemberMutation, CreateCompanyMemberMutationVariables>(
    CreateCompanyMemberDocument,
    options
  )
}
export type CreateCompanyMemberMutationHookResult = ReturnType<
  typeof useCreateCompanyMemberMutation
>
export type CreateCompanyMemberMutationResult = Apollo.MutationResult<CreateCompanyMemberMutation>
export type CreateCompanyMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMemberMutation,
  CreateCompanyMemberMutationVariables
>
export const UpdateCompanyMemberDocument = gql`
  mutation UpdateCompanyMember($input: UpdateCompanyMembershipInput!) {
    updateCompanyMembership(input: $input) {
      role
      status
      company {
        id
        uuid
      }
      user {
        id
        uuid
      }
    }
  }
`
export type UpdateCompanyMemberMutationFn = Apollo.MutationFunction<
  UpdateCompanyMemberMutation,
  UpdateCompanyMemberMutationVariables
>

/**
 * __useUpdateCompanyMemberMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMemberMutation, { data, loading, error }] = useUpdateCompanyMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyMemberMutation,
    UpdateCompanyMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyMemberMutation, UpdateCompanyMemberMutationVariables>(
    UpdateCompanyMemberDocument,
    options
  )
}
export type UpdateCompanyMemberMutationHookResult = ReturnType<
  typeof useUpdateCompanyMemberMutation
>
export type UpdateCompanyMemberMutationResult = Apollo.MutationResult<UpdateCompanyMemberMutation>
export type UpdateCompanyMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMemberMutation,
  UpdateCompanyMemberMutationVariables
>
export const UserBasicDocument = gql`
  query userBasic($where: UserWhereOneInput!) {
    user(where: $where) {
      id
      uuid
      fullName
      email
      role
      status
    }
  }
`

/**
 * __useUserBasicQuery__
 *
 * To run a query within a React component, call `useUserBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBasicQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserBasicQuery(
  baseOptions: Apollo.QueryHookOptions<UserBasicQuery, UserBasicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserBasicQuery, UserBasicQueryVariables>(UserBasicDocument, options)
}
export function useUserBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserBasicQuery, UserBasicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserBasicQuery, UserBasicQueryVariables>(UserBasicDocument, options)
}
export type UserBasicQueryHookResult = ReturnType<typeof useUserBasicQuery>
export type UserBasicLazyQueryHookResult = ReturnType<typeof useUserBasicLazyQuery>
export type UserBasicQueryResult = Apollo.QueryResult<UserBasicQuery, UserBasicQueryVariables>
export const CompanyMemberListDocument = gql`
  query CompanyMemberList($where: CompanyWhereOneInput!) {
    company(where: $where) {
      id
      uuid
      name
      memberships {
        user {
          id
          uuid
          username
          fullName
          email
          status
        }
        role
        status
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useCompanyMemberListQuery__
 *
 * To run a query within a React component, call `useCompanyMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMemberListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompanyMemberListQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyMemberListQuery, CompanyMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyMemberListQuery, CompanyMemberListQueryVariables>(
    CompanyMemberListDocument,
    options
  )
}
export function useCompanyMemberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyMemberListQuery, CompanyMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyMemberListQuery, CompanyMemberListQueryVariables>(
    CompanyMemberListDocument,
    options
  )
}
export type CompanyMemberListQueryHookResult = ReturnType<typeof useCompanyMemberListQuery>
export type CompanyMemberListLazyQueryHookResult = ReturnType<typeof useCompanyMemberListLazyQuery>
export type CompanyMemberListQueryResult = Apollo.QueryResult<
  CompanyMemberListQuery,
  CompanyMemberListQueryVariables
>
export const CompanyToDraftDocument = gql`
  mutation CompanyToDraft($where: CompanyWhereOneInput!, $input: CompanyToDraftInput!) {
    companyToDraft(where: $where, input: $input) {
      id
      uuid
      name
      description {
        content
        language
        default
      }
      introduction
      primaryColor
      secondaryColor
      visibility
      status
      endpoint
      updatedAt
      createdAt
      logo {
        id
      }
      space {
        id
      }
    }
  }
`
export type CompanyToDraftMutationFn = Apollo.MutationFunction<
  CompanyToDraftMutation,
  CompanyToDraftMutationVariables
>

/**
 * __useCompanyToDraftMutation__
 *
 * To run a mutation, you first call `useCompanyToDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyToDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyToDraftMutation, { data, loading, error }] = useCompanyToDraftMutation({
 *   variables: {
 *      where: // value for 'where'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyToDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<CompanyToDraftMutation, CompanyToDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompanyToDraftMutation, CompanyToDraftMutationVariables>(
    CompanyToDraftDocument,
    options
  )
}
export type CompanyToDraftMutationHookResult = ReturnType<typeof useCompanyToDraftMutation>
export type CompanyToDraftMutationResult = Apollo.MutationResult<CompanyToDraftMutation>
export type CompanyToDraftMutationOptions = Apollo.BaseMutationOptions<
  CompanyToDraftMutation,
  CompanyToDraftMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      visibility
      status
    }
  }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    CreateCompanyDocument,
    options
  )
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const RequestCompanyDocument = gql`
  mutation RequestCompany($input: RequestCompanyInput!) {
    requestCompany(input: $input) {
      id
      uuid
      name
      primaryColor
      visibility
      status
      endpoint
      radius
      address
      location {
        lat
        long
      }
      logo {
        id
        fileName
        fileLocation
      }
      space {
        id
        name
      }
    }
  }
`
export type RequestCompanyMutationFn = Apollo.MutationFunction<
  RequestCompanyMutation,
  RequestCompanyMutationVariables
>

/**
 * __useRequestCompanyMutation__
 *
 * To run a mutation, you first call `useRequestCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCompanyMutation, { data, loading, error }] = useRequestCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestCompanyMutation, RequestCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestCompanyMutation, RequestCompanyMutationVariables>(
    RequestCompanyDocument,
    options
  )
}
export type RequestCompanyMutationHookResult = ReturnType<typeof useRequestCompanyMutation>
export type RequestCompanyMutationResult = Apollo.MutationResult<RequestCompanyMutation>
export type RequestCompanyMutationOptions = Apollo.BaseMutationOptions<
  RequestCompanyMutation,
  RequestCompanyMutationVariables
>
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!, $where: CompanyWhereOneInput!) {
    updateCompany(input: $input, where: $where) {
      id
      name
      description {
        content
        language
        default
      }
      introduction
      primaryColor
      secondaryColor
      visibility
      status
      radius
      address
      endpoint
      location {
        lat
        long
      }
      space {
        id
        name
      }
      logo {
        id
        fileName
      }
    }
  }
`
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options
  )
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>
export const PrivacyPolicyDocument = gql`
  query privacyPolicy($id: Int!) {
    company(where: { id: $id }) {
      id
      privacyPolicy {
        content
        default
        language
      }
    }
  }
`

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrivacyPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options
  )
}
export function usePrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options
  )
}
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>
export type PrivacyPolicyQueryResult = Apollo.QueryResult<
  PrivacyPolicyQuery,
  PrivacyPolicyQueryVariables
>
export const TermsConditionsDocument = gql`
  query termsConditions($id: Int!) {
    company(where: { id: $id }) {
      id
      termsAndConditions {
        content
        default
        language
      }
    }
  }
`

/**
 * __useTermsConditionsQuery__
 *
 * To run a query within a React component, call `useTermsConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsConditionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTermsConditionsQuery(
  baseOptions: Apollo.QueryHookOptions<TermsConditionsQuery, TermsConditionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TermsConditionsQuery, TermsConditionsQueryVariables>(
    TermsConditionsDocument,
    options
  )
}
export function useTermsConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TermsConditionsQuery, TermsConditionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TermsConditionsQuery, TermsConditionsQueryVariables>(
    TermsConditionsDocument,
    options
  )
}
export type TermsConditionsQueryHookResult = ReturnType<typeof useTermsConditionsQuery>
export type TermsConditionsLazyQueryHookResult = ReturnType<typeof useTermsConditionsLazyQuery>
export type TermsConditionsQueryResult = Apollo.QueryResult<
  TermsConditionsQuery,
  TermsConditionsQueryVariables
>
export const UpdatePrivacyPolicyDocument = gql`
  mutation updatePrivacyPolicy($input: UpdateCompanyInput!, $where: CompanyWhereOneInput!) {
    updateCompany(input: $input, where: $where) {
      id
      description {
        content
        language
        default
      }
      introduction
      name
      privacyPolicy {
        content
        default
        language
      }
    }
  }
`
export type UpdatePrivacyPolicyMutationFn = Apollo.MutationFunction<
  UpdatePrivacyPolicyMutation,
  UpdatePrivacyPolicyMutationVariables
>

/**
 * __useUpdatePrivacyPolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacyPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacyPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacyPolicyMutation, { data, loading, error }] = useUpdatePrivacyPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePrivacyPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrivacyPolicyMutation,
    UpdatePrivacyPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePrivacyPolicyMutation, UpdatePrivacyPolicyMutationVariables>(
    UpdatePrivacyPolicyDocument,
    options
  )
}
export type UpdatePrivacyPolicyMutationHookResult = ReturnType<
  typeof useUpdatePrivacyPolicyMutation
>
export type UpdatePrivacyPolicyMutationResult = Apollo.MutationResult<UpdatePrivacyPolicyMutation>
export type UpdatePrivacyPolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrivacyPolicyMutation,
  UpdatePrivacyPolicyMutationVariables
>
export const UpdateTermsConditionsDocument = gql`
  mutation updateTermsConditions($input: UpdateCompanyInput!, $where: CompanyWhereOneInput!) {
    updateCompany(input: $input, where: $where) {
      id
      description {
        content
        default
        language
      }
      name
      termsAndConditions {
        content
        default
        language
      }
    }
  }
`
export type UpdateTermsConditionsMutationFn = Apollo.MutationFunction<
  UpdateTermsConditionsMutation,
  UpdateTermsConditionsMutationVariables
>

/**
 * __useUpdateTermsConditionsMutation__
 *
 * To run a mutation, you first call `useUpdateTermsConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermsConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermsConditionsMutation, { data, loading, error }] = useUpdateTermsConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateTermsConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTermsConditionsMutation,
    UpdateTermsConditionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTermsConditionsMutation, UpdateTermsConditionsMutationVariables>(
    UpdateTermsConditionsDocument,
    options
  )
}
export type UpdateTermsConditionsMutationHookResult = ReturnType<
  typeof useUpdateTermsConditionsMutation
>
export type UpdateTermsConditionsMutationResult =
  Apollo.MutationResult<UpdateTermsConditionsMutation>
export type UpdateTermsConditionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTermsConditionsMutation,
  UpdateTermsConditionsMutationVariables
>
export const CompanyDocument = gql`
  query Company($where: CompanyWhereOneInput!) {
    company(where: $where) {
      id
      uuid
      name
      description {
        content
        language
        default
      }
      introduction
      primaryColor
      secondaryColor
      visibility
      status
      endpoint
      createdAt
      updatedAt
      radius
      address
      location {
        lat
        long
      }
      aboutUs {
        content
        language
        default
      }
      logo {
        id
        fileLocation
      }
      space {
        id
        name
      }
      gender
      ageFrom
      ageTo
      activeDateFrom
      activeDateTo
      language
    }
  }
`

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options)
}
export function useCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options)
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>
export const UpdatePostDocument = gql`
  mutation UpdatePost($input: UpdatePostInput!, $where: PostWhereOneInput!) {
    updatePost(input: $input, where: $where) {
      id
      uuid
      name
      username
      bio
      phone
      email
      createdAt
      updatedAt
      address
      location {
        lat
        long
      }
      aboutUs {
        content
        language
        default
      }
      ourServices {
        content
        language
        default
      }
      avatar {
        id
        fileLocation
      }
      membershipsConnection {
        count
        memberships {
          user {
            id
            fullName
            email
          }
          role
          status
        }
      }
    }
  }
`
export type UpdatePostMutationFn = Apollo.MutationFunction<
  UpdatePostMutation,
  UpdatePostMutationVariables
>

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(
    UpdatePostDocument,
    options
  )
}
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostMutation,
  UpdatePostMutationVariables
>
export const PostDocument = gql`
  query Post($where: PostWhereOneInput!) {
    post(where: $where) {
      id
      uuid
      name
      username
      bio
      phone
      email
      createdAt
      updatedAt
      address
      location {
        lat
        long
      }
      aboutUs {
        content
        language
        default
      }
      ourServices {
        content
        language
        default
      }
      avatar {
        id
        fileLocation
      }
      membershipsConnection {
        count
        memberships {
          user {
            id
            fullName
            email
          }
          role
          status
        }
      }
    }
  }
`

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, options)
}
export function usePostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, options)
}
export type PostQueryHookResult = ReturnType<typeof usePostQuery>
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>
export const CreateSpaceDocument = gql`
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      logo {
        id
        fileLocation
      }
      name
      description
      primaryColor
      secondaryColor
      icon
      companies {
        id
        uuid
        name
      }
    }
  }
`
export type CreateSpaceMutationFn = Apollo.MutationFunction<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>

/**
 * __useCreateSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpaceMutation, { data, loading, error }] = useCreateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSpaceMutation, CreateSpaceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(
    CreateSpaceDocument,
    options
  )
}
export type CreateSpaceMutationHookResult = ReturnType<typeof useCreateSpaceMutation>
export type CreateSpaceMutationResult = Apollo.MutationResult<CreateSpaceMutation>
export type CreateSpaceMutationOptions = Apollo.BaseMutationOptions<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>
export const UpdateSpaceDocument = gql`
  mutation UpdateSpace($input: UpdateSpaceInput!, $where: AssetWhereOneInput!) {
    updateSpace(input: $input, where: $where) {
      id
      name
      description
      primaryColor
      secondaryColor
      icon
      createdAt
      updatedAt
    }
  }
`
export type UpdateSpaceMutationFn = Apollo.MutationFunction<
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables
>

/**
 * __useUpdateSpaceMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpaceMutation, { data, loading, error }] = useUpdateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSpaceMutation, UpdateSpaceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSpaceMutation, UpdateSpaceMutationVariables>(
    UpdateSpaceDocument,
    options
  )
}
export type UpdateSpaceMutationHookResult = ReturnType<typeof useUpdateSpaceMutation>
export type UpdateSpaceMutationResult = Apollo.MutationResult<UpdateSpaceMutation>
export type UpdateSpaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables
>
export const SpaceDocument = gql`
  query Space($id: Int!) {
    space(where: { id: $id }) {
      id
      name
      description
      primaryColor
      secondaryColor
      icon
      createdAt
      updatedAt
    }
  }
`

/**
 * __useSpaceQuery__
 *
 * To run a query within a React component, call `useSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<SpaceQuery, SpaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpaceQuery, SpaceQueryVariables>(SpaceDocument, options)
}
export function useSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpaceQuery, SpaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpaceQuery, SpaceQueryVariables>(SpaceDocument, options)
}
export type SpaceQueryHookResult = ReturnType<typeof useSpaceQuery>
export type SpaceLazyQueryHookResult = ReturnType<typeof useSpaceLazyQuery>
export type SpaceQueryResult = Apollo.QueryResult<SpaceQuery, SpaceQueryVariables>
export const UpdateUserStatusDocument = gql`
  mutation UpdateUserStatus($input: UpdateUserInput!, $where: UserWhereOneInput!) {
    updateUser(input: $input, where: $where) {
      id
      uuid
      status
      role
    }
  }
`
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<
  UpdateUserStatusMutation,
  UpdateUserStatusMutationVariables
>

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserStatusMutation,
    UpdateUserStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(
    UpdateUserStatusDocument,
    options
  )
}
export type UpdateUserStatusMutationHookResult = ReturnType<typeof useUpdateUserStatusMutation>
export type UpdateUserStatusMutationResult = Apollo.MutationResult<UpdateUserStatusMutation>
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserStatusMutation,
  UpdateUserStatusMutationVariables
>
export const UsersCompanyListDocument = gql`
  query UsersCompanyList(
    $where: CompanyWhereManyInput
    $orderBy: CompaniesOrderByInput
    $skip: Int
    $take: Int
  ) {
    companiesConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      companies {
        id
        uuid
        name
        space {
          id
          name
        }
        memberships {
          role
          status
          user {
            id
            uuid
            fullName
          }
        }
      }
    }
  }
`

/**
 * __useUsersCompanyListQuery__
 *
 * To run a query within a React component, call `useUsersCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCompanyListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUsersCompanyListQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersCompanyListQuery, UsersCompanyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersCompanyListQuery, UsersCompanyListQueryVariables>(
    UsersCompanyListDocument,
    options
  )
}
export function useUsersCompanyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersCompanyListQuery, UsersCompanyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersCompanyListQuery, UsersCompanyListQueryVariables>(
    UsersCompanyListDocument,
    options
  )
}
export type UsersCompanyListQueryHookResult = ReturnType<typeof useUsersCompanyListQuery>
export type UsersCompanyListLazyQueryHookResult = ReturnType<typeof useUsersCompanyListLazyQuery>
export type UsersCompanyListQueryResult = Apollo.QueryResult<
  UsersCompanyListQuery,
  UsersCompanyListQueryVariables
>
export const UserDocument = gql`
  query user($where: UserWhereOneInput!) {
    user(where: $where) {
      id
      uuid
      fullName
      email
      role
      status
      username
      gender
      birthDate
      bio
      createdAt
      updatedAt
      avatar {
        id
        fileLocation
      }
      memberships {
        role
        status
        company {
          id
          uuid
          name
        }
      }
    }
  }
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options)
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options)
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>
export const CompanyListDocument = gql`
  query CompanyList(
    $where: CompanyWhereManyInput
    $orderBy: CompaniesOrderByInput
    $skip: Int
    $take: Int
  ) {
    companiesConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      companies {
        id
        uuid
        name
        primaryColor
        visibility
        endpoint
        status
        radius
        space {
          id
          name
        }
        logo {
          id
          fileName
          fileLocation
        }
      }
    }
  }
`

/**
 * __useCompanyListQuery__
 *
 * To run a query within a React component, call `useCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanyListQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyListQuery, CompanyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, options)
}
export function useCompanyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyListQuery, CompanyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyListQuery, CompanyListQueryVariables>(
    CompanyListDocument,
    options
  )
}
export type CompanyListQueryHookResult = ReturnType<typeof useCompanyListQuery>
export type CompanyListLazyQueryHookResult = ReturnType<typeof useCompanyListLazyQuery>
export type CompanyListQueryResult = Apollo.QueryResult<CompanyListQuery, CompanyListQueryVariables>
export const PostListDocument = gql`
  query PostList($where: PostWhereManyInput, $orderBy: PostsOrderByInput, $skip: Int, $take: Int) {
    postsConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      posts {
        id
        uuid
        name
        username
        bio
        address
        phone
        email
        avatar {
          id
          fileName
          fileLocation
        }
        aboutUs {
          language
          default
          content
        }
        ourServices {
          language
          default
          content
        }
        location {
          lat
          long
        }
      }
    }
  }
`

/**
 * __usePostListQuery__
 *
 * To run a query within a React component, call `usePostListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePostListQuery(
  baseOptions?: Apollo.QueryHookOptions<PostListQuery, PostListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostListQuery, PostListQueryVariables>(PostListDocument, options)
}
export function usePostListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostListQuery, PostListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostListQuery, PostListQueryVariables>(PostListDocument, options)
}
export type PostListQueryHookResult = ReturnType<typeof usePostListQuery>
export type PostListLazyQueryHookResult = ReturnType<typeof usePostListLazyQuery>
export type PostListQueryResult = Apollo.QueryResult<PostListQuery, PostListQueryVariables>
export const SpaceListDocument = gql`
  query SpaceList($where: SpaceWhereManyInput, $skip: Int, $take: Int) {
    spaces(where: $where, skip: $skip, take: $take) {
      id
    }
  }
`

/**
 * __useSpaceListQuery__
 *
 * To run a query within a React component, call `useSpaceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSpaceListQuery(
  baseOptions?: Apollo.QueryHookOptions<SpaceListQuery, SpaceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpaceListQuery, SpaceListQueryVariables>(SpaceListDocument, options)
}
export function useSpaceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpaceListQuery, SpaceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpaceListQuery, SpaceListQueryVariables>(SpaceListDocument, options)
}
export type SpaceListQueryHookResult = ReturnType<typeof useSpaceListQuery>
export type SpaceListLazyQueryHookResult = ReturnType<typeof useSpaceListLazyQuery>
export type SpaceListQueryResult = Apollo.QueryResult<SpaceListQuery, SpaceListQueryVariables>
export const SpacesDocument = gql`
  query Spaces($where: SpaceWhereManyInput, $skip: Int, $take: Int) {
    spaces(where: $where, skip: $skip, take: $take) {
      id
      logo {
        id
        fileLocation
      }
      name
      description
      primaryColor
      secondaryColor
      icon
      companies {
        id
        uuid
        name
      }
    }
  }
`

/**
 * __useSpacesQuery__
 *
 * To run a query within a React component, call `useSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpacesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSpacesQuery(
  baseOptions?: Apollo.QueryHookOptions<SpacesQuery, SpacesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpacesQuery, SpacesQueryVariables>(SpacesDocument, options)
}
export function useSpacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpacesQuery, SpacesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpacesQuery, SpacesQueryVariables>(SpacesDocument, options)
}
export type SpacesQueryHookResult = ReturnType<typeof useSpacesQuery>
export type SpacesLazyQueryHookResult = ReturnType<typeof useSpacesLazyQuery>
export type SpacesQueryResult = Apollo.QueryResult<SpacesQuery, SpacesQueryVariables>
export const CompanyMembersDocument = gql`
  query CompanyMembers(
    $where: CompanyWhereManyInput
    $orderBy: CompaniesOrderByInput
    $skip: Int
    $take: Int
  ) {
    companiesConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      companies {
        id
        uuid
        name
        memberships {
          role
          status
          user {
            id
            uuid
            role
            status
          }
        }
      }
    }
  }
`

/**
 * __useCompanyMembersQuery__
 *
 * To run a query within a React component, call `useCompanyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanyMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyMembersQuery, CompanyMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyMembersQuery, CompanyMembersQueryVariables>(
    CompanyMembersDocument,
    options
  )
}
export function useCompanyMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyMembersQuery, CompanyMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyMembersQuery, CompanyMembersQueryVariables>(
    CompanyMembersDocument,
    options
  )
}
export type CompanyMembersQueryHookResult = ReturnType<typeof useCompanyMembersQuery>
export type CompanyMembersLazyQueryHookResult = ReturnType<typeof useCompanyMembersLazyQuery>
export type CompanyMembersQueryResult = Apollo.QueryResult<
  CompanyMembersQuery,
  CompanyMembersQueryVariables
>
export const CompanyMembershipsDocument = gql`
  query CompanyMemberships(
    $where: CompanyMembershipWhereManyInput
    $orderBy: CompanyMembershipOrderByInput
    $skip: Int
    $take: Int
  ) {
    companyMembershipsConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      memberships {
        id
        role
        status
        createdAt
        updatedAt
        user {
          id
          uuid
          username
          fullName
          email
          role
          status
        }
        company {
          id
          uuid
          name
        }
      }
    }
  }
`

/**
 * __useCompanyMembershipsQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanyMembershipsQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyMembershipsQuery, CompanyMembershipsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyMembershipsQuery, CompanyMembershipsQueryVariables>(
    CompanyMembershipsDocument,
    options
  )
}
export function useCompanyMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipsQuery,
    CompanyMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyMembershipsQuery, CompanyMembershipsQueryVariables>(
    CompanyMembershipsDocument,
    options
  )
}
export type CompanyMembershipsQueryHookResult = ReturnType<typeof useCompanyMembershipsQuery>
export type CompanyMembershipsLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipsLazyQuery
>
export type CompanyMembershipsQueryResult = Apollo.QueryResult<
  CompanyMembershipsQuery,
  CompanyMembershipsQueryVariables
>
export const UserListDocument = gql`
  query UserList($where: UserWhereManyInput, $orderBy: UsersOrderByInput, $skip: Int, $take: Int) {
    usersConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      users {
        id
        username
        fullName
        email
        role
        status
      }
    }
  }
`

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options)
}
export function useUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options)
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>
