import { MUIDataTableState } from 'mui-datatables'

export const parseFilter = (tableState: MUIDataTableState) => {
  const columnNames: string[] = tableState.columns.map((c) => c.name)
  const filtersByColumn: { [key: string]: string[] } = {}
  columnNames.forEach((columnName, i) => {
    const fltrs = tableState.filterList[i]
    if (fltrs.length) {
      filtersByColumn[columnName] = fltrs
    }
  })
  return filtersByColumn
}

interface TableChangeHandlerArgs<OrderType> {
  onFilterChange?: (filters: { [key: string]: string[] }) => void
  onOrderChange?: (args: OrderType) => void
  onChangeRowsPerPage?: (rows: number) => void
  onChangePage?: (page: number) => void
  debug?: boolean
}

export function getTableChangeHandler<
  OrderType extends { [key: string]: string | undefined | null }
>(args: TableChangeHandlerArgs<OrderType>) {
  const { onFilterChange, onOrderChange, onChangePage, onChangeRowsPerPage } = args
  const handler = (action: string, tableState: MUIDataTableState) => {
    switch (action) {
      case 'changePage':
        if (onChangePage) onChangePage(tableState.page)
        break
      case 'changeRowsPerPage':
        if (onChangeRowsPerPage) onChangeRowsPerPage(tableState.rowsPerPage)
        break
      case 'sort':
        if (onOrderChange) {
          const newOrder = {
            [tableState.sortOrder.name]: tableState.sortOrder.direction,
          } as OrderType
          onOrderChange(newOrder)
        }
        break
      case 'filterChange':
        if (onFilterChange) onFilterChange(parseFilter(tableState))
        break

      case 'resetFilters':
        if (onFilterChange) onFilterChange(parseFilter(tableState))
        break
      default:
        // eslint-disable-next-line no-console
        if (args.debug) console.log('[TableChangeHandler]: Unhandled: ', action)
        break
    }
  }
  return handler
}
