import React, { HTMLProps, useRef } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'

export interface FileSelectorBtnProps extends ButtonProps {
  onFileSelect?: (file: FileList) => void
  inputProps?: HTMLProps<HTMLInputElement>
}

export const FileSelectorBtn: React.FC<FileSelectorBtnProps> = ({
  children,
  inputProps,
  onFileSelect,
  ...buttonProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onClick = () => {
    inputRef.current?.click()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) return
    if (onFileSelect) onFileSelect(e.currentTarget.files)
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={onChange}
        type="file"
        {...inputProps}
      />
      <Button onClick={onClick} {...buttonProps}>
        {children}
      </Button>
    </>
  )
}
