export function readURL(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const result = reader?.result
      if (typeof result === 'string') {
        resolve(result)
      } else reject(new Error('File not supported'))
    }
    reader.readAsDataURL(file)
  })
}
