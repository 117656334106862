import { Checkbox } from 'components/Form/Atoms'
import { Control } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SignupFormValues } from './types'
import { validate } from 'graphql'

export type Props = {
  control: Control<SignupFormValues>
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '1rem',
  },
}))

const CheckboxFields = (props: Props) => {
  const classes = useStyles()
  const { t } = useTranslation('company')

  return (
    <div className={classes.container}>
      <Checkbox
        control={props.control}
        name="acceptTermsAndConditions"
        color="primary"
        rules={{
          validate: (value) => value || 'You must accept the terms and conditions',
        }}
        label={
          <>
            <span>{t('company:documents.accept')}</span>
            <Link to="/terms-and-conditions" target="_blank">
              {t('company:documents.termsAndConditions')}
            </Link>
            <span>.</span>
          </>
        }
      />
      <Checkbox
        control={props.control}
        rules={{
          validate: (value) => value || 'You must accept the privacy policy',
        }}
        name="acceptPrivacyPolicy"
        color="primary"
        label={
          <>
            <span>{t('company:documents.accept')}</span>
            <Link to="/privacy-policy" target="_blank">
              {t('company:documents.privacyPolicy')}
            </Link>
            <span>.</span>
          </>
        }
      />
    </div>
  )
}

export default CheckboxFields
