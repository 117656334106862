import { makeStyles, CircularProgress, Typography } from '@material-ui/core'
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const DEFAULT_OPTIONS: MUIDataTableOptions = {
  responsive: 'vertical',
  selectableRows: 'none',
  selectableRowsHideCheckboxes: true,
  viewColumns: false,
  download: false,
  print: false,
  filter: false,
  search: false,
  textLabels: {
    body: {
      noMatch: 'No matching records found...',
    },
  },
}

export interface DataTableColumnOptions extends MUIDataTableColumnOptions {
  narrow?: boolean
}
export interface DataTableColumn extends Omit<MUIDataTableColumn, 'options'> {
  options: DataTableColumnOptions
}

interface Props extends Omit<MUIDataTableProps, 'columns'> {
  columns: DataTableColumn[]
  isLoading?: boolean
}

type Classes = ReturnType<typeof useStyles>

const useStyles = makeStyles(() => ({
  circularProgress: {
    marginLeft: 15,
    position: 'relative',
    top: 4,
  },
  selectableRow: {
    cursor: 'pointer',
  },
  narrowColumn: {
    width: 0,
  },
}))

const DataTable = ({ isLoading, title, options, columns, ...rest }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const processedTitle = useMemo(
    () => processTitle(title, isLoading, classes),
    [title, isLoading, classes]
  )
  const processedOptions = useMemo(() => {
    const procOptions = processOptions(options, classes)
    procOptions.textLabels = {
      body: { noMatch: t('common:table.noMatch') },
      pagination: { rowsPerPage: t('common:table.rowsPerPage') },
    }
    return procOptions
  }, [options, classes, t])
  const processedColumns = useMemo(() => processColumns(columns, classes), [columns, classes])

  return (
    <MUIDataTable
      title={processedTitle}
      options={processedOptions}
      columns={processedColumns}
      {...rest}
    />
  )
}

function processTitle(title: ReactNode, isLoading: boolean | undefined, classes: Classes) {
  return !title || typeof title === 'string' ? (
    <Typography variant="h6">
      {isLoading ? (
        <>
          Loading...
          <CircularProgress size={24} className={classes.circularProgress} />
        </>
      ) : (
        title
      )}
    </Typography>
  ) : (
    title
  )
}

function processOptions(
  options: MUIDataTableOptions | undefined,
  classes: Classes
): MUIDataTableOptions {
  const processedOptions = { ...DEFAULT_OPTIONS, ...options }
  if (options?.onRowClick)
    processedOptions.setRowProps = () => ({ className: classes.selectableRow })
  return processedOptions
}

function processColumns(columns: DataTableColumn[], classes: Classes): MUIDataTableColumn[] {
  return columns.map((column) => {
    const { options, ...restColumn } = column
    const { narrow, ...restOptions } = options
    if (narrow) {
      restOptions.setCellHeaderProps = () => ({
        className: classes.narrowColumn,
      })
    }
    return { options: restOptions, ...restColumn }
  })
}

export default DataTable
