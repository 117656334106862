import { makeStyles } from '@material-ui/core'

export const useSpaceListStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: '0px',
    },
    '& .MuiTableRow-root': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableBlur: {
    minWidth: 700,
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.2)',
    filter: 'blur(5px)',
  },
  nameColumn: {
    width: '20%',
  },
  descriptionColumn: {
    width: '36%',
  },
  otherColumn: {
    width: '10%',
  },
  buttonColumn: {
    width: '12%',
  },
  button: {
    width: '100%',
    maxWidth: '7rem',
  },
  buttonBlur: {
    width: '100%',
    maxWidth: '7rem',
    background: 'rgba(0, 0, 0, 0.15)',
  },
  colorBox: {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '2.5rem',
  },
})
