import { Switch, Route, useRouteMatch } from 'react-router-dom'
import CompanyList from './companyList'
import CreateOrEditCompany from 'modules/company/CreateOrEdit/CreateOrEditCompany'
import CompanyRoutes from 'modules/company'

const Companies = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <CompanyList />
      </Route>
      <Route exact path={`${match.path}/new`}>
        <CreateOrEditCompany mode="create" />
      </Route>
      <Route path={`${match.path}/:companyId`}>
        <CompanyRoutes />
      </Route>
    </Switch>
  )
}

export default Companies
