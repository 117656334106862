import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormValues } from './model'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCompanyFormValidation = (
  register: UseFormRegister<FormValues>,
  statusIsRequested: boolean
) => {
  const { t } = useTranslation('company')
  register('name', {
    required: String(t('company:form.nameRequired')),
    pattern: {
      value: /[A-Z0-9]{1}[A-Za-z0-9]{2}/,
      message: t('company:form.nameError'),
    },
  })
  register('endpoint', {
    required: statusIsRequested ? String(t('company:form.urlRequired')) : false,
    pattern: {
      value: /(http|https):\/\//,
      message: t('company:form.urlError'),
    },
  })
  register('superAdminEmail', {
    required: statusIsRequested ? String(t('company:form.emailRequired')) : false,
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: t('company:form.emailError'),
    },
  })
}
