import { useMemo } from 'react'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import BackDropLoading from 'components/utils/BackDropLoading'
import { Company, useCompanyQuery } from 'generated/types-and-hooks'
import defaultLogo from 'assets/img/default-logo.png'
import { useTranslation } from 'react-i18next'
import { useSpaceViewStyles } from 'modules/space/View/styles'
import { Link } from 'react-router-dom'

interface Props {
  companyId: number
}

const ViewCompany = ({ companyId }: Props) => {
  const { data, loading } = useCompanyQuery({ variables: { where: { id: companyId } } })
  const { t } = useTranslation(['common', 'company', 'space'])
  const classes = useSpaceViewStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  const company: NonNullable<Company> = useMemo<Company>(() => {
    if (data?.company && !loading) {
      const companyData = data.company as NonNullable<Company>
      return companyData
    }
    return {} as NonNullable<Company>
  }, [data])

  const baseData = [
    { title: t('common:table.name'), text: company.name },
    { title: t('common:table.uuid'), text: company.uuid },
    { title: 'Address', text: company.address },
    { title: t('common:table.status'), text: t(`company:enum.status.${company.status}`) },
    { title: t('common:table.type'), text: t(`company:enum.visibility.${company.visibility}`) },
    { title: t('space:name'), text: company.space?.name },
    { title: t('common:table.primaryColor'), text: company.primaryColor },
    { title: t('common:table.secondaryColor'), text: company.secondaryColor },
    { title: t('common:table.url'), text: company.endpoint },
    {
      title: t('common:table.created'),
      text: new Date(Number(company.createdAt)).toString().substr(3, 18) ?? '',
    },
    {
      title: t('common:table.updated'),
      text: new Date(Number(company.updatedAt)).toString().substr(3, 18) ?? '',
    },
  ]

  if (loading) {
    return <BackDropLoading />
  } else {
    return (
      <>
        <Container className={matches ? classes.rootXs : classes.root}>
          <Grid container wrap={matches ? 'wrap' : 'nowrap'} spacing={3}>
            <Grid item xs={matches ? 12 : 4} className={classes.logoGrid}>
              <Paper className={matches ? classes.logoXs : classes.logo}>
                {company?.logo?.fileLocation ? (
                  <img
                    className={classes.image}
                    alt={t('company:companyLogo')}
                    src={company.logo.fileLocation}
                  />
                ) : (
                  <img className={classes.image} src={defaultLogo} alt={t('company:defaultLogo')} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={matches ? 12 : 8} className={classes.baseData}>
              {baseData.map((u) => (
                <Grid key={u.title.replace(' ', '').toLowerCase()} container alignItems="center">
                  <Grid item xs={matches ? 5 : 5}>
                    <Typography className={classes.textLeft} color="textPrimary">
                      {u.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={matches ? 7 : 7}>
                    {u.title.includes('Color') ? (
                      <div className={classes.colorBox} style={{ background: u.text ?? '-' }}></div>
                    ) : u.title.includes('Url') && u.text ? (
                      <Link to={{ pathname: u.text }} target="_blank">
                        {u.text}
                      </Link>
                    ) : (
                      <Typography className={classes.textRight} color="textSecondary">
                        {u.text ?? '-'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {company.introduction && (
            <Grid container spacing={3}>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography paragraph color="textPrimary">
                    {t('common:table.introduction')}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography color="textSecondary">{company.introduction}</Typography>
                </Paper>
              </Grid>
            </Grid>
          )}

          {company.description?.[0]?.content && (
            <Grid container spacing={3}>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography paragraph color="textPrimary">
                    {t('company:table.description')}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: company.description[0].content,
                    }}
                    color="textSecondary"
                  ></Typography>
                </Paper>
              </Grid>
            </Grid>
          )}

          {company.aboutUs?.[0]?.content && (
            <Grid container spacing={3}>
              <Grid item xs>
                <Paper className={classes.paper}>
                  <Typography paragraph color="textPrimary">
                    {t('company:aboutUs')}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: company.aboutUs[0].content,
                    }}
                    color="textSecondary"
                  ></Typography>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </>
    )
  }
}

export default ViewCompany
