import { MUIDataTableOptions, SelectableRows } from 'mui-datatables'
import { useState, useMemo } from 'react'
import {
  Company,
  CompanyStatus,
  CompanyVisibility,
  CompanyWhereManyInput,
  useCompanyListQuery,
} from 'generated/types-and-hooks'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { DeleteButton, EditButton } from 'components/Button/IconButtons'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import PageHeader from 'components/utils/PageHeader'
import { useDeleteDialog } from 'utils/hooks/useDeleteDialog'
import { usePagination } from 'utils/query/usePagination'
import { useTranslation } from 'react-i18next'
import { getTableChangeHandler } from 'utils/query/table-utils'
import { Box, Button } from '@material-ui/core'
import { SearchBox } from 'components/Form/SearchBox'
//import { useFilter } from 'utils/query/useFilter'
import { useGlobalLoading } from 'context/LoadingContext'
import { CompanyData } from './companyList.model'
import DeleteCompanyDialog from 'modules/company/Delete/DeleteCompany'
import Logo from 'components/Image/Logo'
import { useSearch } from 'utils/query/useSearch'
import { TFunction } from 'i18next'

const tableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .MuiTableCell-root': {
        borderBottom: '0px',
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    colorBox: {
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '2.5rem',
    },
  })
)

function mapCompanyToCompanyData(company: Company, t: TFunction): CompanyData {
  const companyData: CompanyData = {
    id: company.id ?? 0,
    logo: company.logo?.fileLocation ?? '',
    name: company.name ?? '',
    visibility: t(`company:enum.visibility.${company.visibility}`) ?? CompanyVisibility.Closed,
    status: t(`company:enum.status.${company.status}`) ?? CompanyStatus.Inactive,
    spaceName: company.space?.name ?? '',
    primaryColor: company.primaryColor ?? '',
    endpoint: company.endpoint ?? '',
  }

  return companyData
}

interface Props {
  spaceId?: number
}

const CompanyList = ({ spaceId }: Props) => {
  const [count, setCount] = useState(0)
  const { currentPage, changePage, rowsPerPage, changeRowsPerPage, paginationParams } =
    usePagination()
  const { setQuery, clearQuery, searchQueryParams } = useSearch<
    Omit<CompanyWhereManyInput, 'memberships'>
  >(['name.contains'])
  const { data: companyListData, loading } = useCompanyListQuery({
    variables: {
      ...paginationParams,
      where: spaceId
        ? {
            OR: searchQueryParams,
            space: { id: spaceId },
            //AND: filters,
          }
        : {
            OR: searchQueryParams,
            //AND: filters,},
          },
    },
  })
  const onTableChange = useMemo(
    () =>
      getTableChangeHandler({
        onChangePage: changePage,
        onChangeRowsPerPage: changeRowsPerPage,
        //onFilterChange: setFilters,
      }),
    [changePage, changeRowsPerPage]
  )
  const classes = tableStyles()
  const match = useRouteMatch()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation(['common', 'company', 'space'])
  const { openDialog, closeDialog, isOpen, selectedId } = useDeleteDialog()
  useGlobalLoading(loading)

  const companyList = useMemo(() => {
    if (!companyListData?.companiesConnection?.companies) return []

    setCount(companyListData?.companiesConnection?.count ?? 0)
    const companiesArray = companyListData.companiesConnection.companies as NonNullable<Company>[]
    const mappedCompanies = companiesArray.map((u) => {
      return mapCompanyToCompanyData(u, t)
    })
    return mappedCompanies
  }, [companyListData])

  const options: MUIDataTableOptions = {
    filter: true,
    serverSide: true,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    page: currentPage,
    count,
    onTableChange,
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
    onRowClick: ([id]) => {
      history.push(`${match.url}/${id}`)
    },
    customToolbar: () => {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => history.push(`${match.url}/new`)}
          >
            {t('company:form.newCompany')}
          </Button>
        </>
      )
    },
  }

  const columns: DataTableColumn[] = [
    {
      name: 'id',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'logo',
      label: t('common:table.logo'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => <Logo src={value} />,
      },
    },
    {
      name: 'name',
      label: t('common:table.name'),
      options: { filter: false, sort: false },
    },
    {
      name: 'visibility',
      label: t('common:table.type'),
      options: { filter: false, sort: false },
    },
    {
      name: 'status',
      label: t('common:table.status'),
      options: { filter: false, sort: false },
    },
    {
      name: 'spaceName',
      label: t('space:name'),
      options: { filter: false, sort: false },
    },
    {
      name: 'primaryColor',
      label: t('common:table.primaryColor'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => (
          <div className={classes.colorBox} style={{ background: value }} />
        ),
      },
    },
    {
      name: 'endpoint',
      label: t('common:table.url'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => (
          <Link to={{ pathname: value }} target="_blank">
            {value}
          </Link>
        ),
      },
    },
    {
      name: 'Edit',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <EditButton
            onClick={(e) => {
              e.stopPropagation()
              history.push(`${url}/${rowData[0]}/edit`)
            }}
          />
        ),
      },
    },
    {
      name: 'Delete',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation()
              openDialog(rowData[0] as number)
            }}
          />
        ),
      },
    },
  ]

  return (
    <div className={classes.table}>
      {selectedId && (
        <DeleteCompanyDialog open={isOpen} onClose={closeDialog} companyId={selectedId} />
      )}
      {!spaceId && <PageHeader>{t('company:name_other')}</PageHeader>}
      <Box mb={2}>
        <SearchBox onSearch={setQuery} onClear={clearQuery} />
      </Box>
      <DataTable
        isLoading={loading}
        title=""
        columns={columns}
        data={companyList.map(c => {
          if(c.endpoint === 'OPEN'){
            return {...c, endpoint: ''}
          } else {
            return c;
          }
        })}
        options={{
          ...options,
          onRowClick: ([id]) => {
            history.push(`/companies/${id}`)
          },
        }}
      />
    </div>
  )
}

export default CompanyList
