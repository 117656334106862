import {MUIDataTableOptions, SelectableRows} from 'mui-datatables'
import {useMemo, useState} from 'react'
import {Post, PostWhereManyInput, usePostListQuery,} from 'generated/types-and-hooks'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {DeleteButton, EditButton} from 'components/Button/IconButtons'
import DataTable, {DataTableColumn} from 'components/Table/DataTable'
import {useDeleteDialog} from 'utils/hooks/useDeleteDialog'
import {usePagination} from 'utils/query/usePagination'
import {useTranslation} from 'react-i18next'
import {getTableChangeHandler} from 'utils/query/table-utils'
import {Box, Button} from '@material-ui/core'
import {SearchBox} from 'components/Form/SearchBox'
//import { useFilter } from 'utils/query/useFilter'
import {useGlobalLoading} from 'context/LoadingContext'
import {PostData} from './postList.model'
import DeleteCompanyDialog from 'modules/company/Delete/DeleteCompany'
import {useSearch} from 'utils/query/useSearch'

const tableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .MuiTableCell-root': {
        borderBottom: '0px',
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    colorBox: {
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '2.5rem',
    },
  })
)

function mapPostToPostData(post: Post): PostData {
  return {
    id: post.id ?? 0,
    name: post.name ?? '',
    uuid: post.uuid ?? '',
    username: post.username ?? '',
    address: post.address ?? '',
    email: post.email ?? '',
    bio: post.address ?? '',
    phone: post.email ?? ''
  }
}

const PostList = () => {
  const [count, setCount] = useState(0)
  const { currentPage, changePage, rowsPerPage, changeRowsPerPage, paginationParams } =
    usePagination()
  const { setQuery, clearQuery, searchQueryParams } = useSearch<
    Omit<PostWhereManyInput, 'memberships'>
  >(['name.contains'])
  const { data: postListData, loading } = usePostListQuery({
    variables: {
      ...paginationParams,
      where: {
        OR: searchQueryParams,
        //AND: filters,},
      },
    },
  })
  const onTableChange = useMemo(
    () =>
      getTableChangeHandler({
        onChangePage: changePage,
        onChangeRowsPerPage: changeRowsPerPage,
        //onFilterChange: setFilters,
      }),
    [changePage, changeRowsPerPage]
  )
  const classes = tableStyles()
  const match = useRouteMatch()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation(['common', 'company', 'space'])
  const { openDialog, closeDialog, isOpen, selectedId } = useDeleteDialog()
  useGlobalLoading(loading)

  const postList = useMemo(() => {
    if (!postListData?.postsConnection?.posts) return []

    setCount(postListData?.postsConnection?.count ?? 0)
    const postArray = postListData?.postsConnection?.posts as NonNullable<Post>[]
    return postArray.map((u) => {
      return mapPostToPostData(u)
    })
  }, [postListData])

  const options: MUIDataTableOptions = {
    filter: true,
    serverSide: true,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    page: currentPage,
    count,
    onTableChange,
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
    onRowClick: ([id]) => {
      history.push(`${match.url}/${id}`)
    },
    // customToolbar: () => {
    //   return (
    //     <>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         startIcon={<AddCircleOutlineIcon />}
    //         onClick={() => history.push(`${match.url}/new`)}
    //       >
    //         New post
    //       </Button>
    //     </>
    //   )
    // },
  }

  const columns: DataTableColumn[] = [
    {
      name: 'id',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'name',
      label: t('common:table.name'),
      options: { filter: false, sort: false },
    },
    {
      name: 'email',
      label: t('common:table.email'),
      options: { filter: false, sort: false },
    },
    {
      name: 'address',
      label: 'Address',
      options: { filter: false, sort: false },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: { filter: false, sort: false },
    },
    {
      name: 'type',
      label: 'Type',
      options: { filter: false, sort: false },
    },
    {
      name: 'Edit',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <EditButton
            onClick={(e) => {
              e.stopPropagation()
              history.push(`${url}/${rowData[0]}/edit`)
            }}
          />
        ),
      },
    },
    {
      name: 'Delete',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation()
              openDialog(rowData[0] as number)
            }}
          />
        ),
      },
    },
  ]

  return (
    <div className={classes.table}>
      {selectedId && (
        <DeleteCompanyDialog open={isOpen} onClose={closeDialog} companyId={selectedId} />
      )}
      <Box mb={2}>
        <SearchBox onSearch={setQuery} onClear={clearQuery} />
      </Box>
      <DataTable
        isLoading={loading}
        title=""
        columns={columns}
        data={postList}
        options={{
          ...options,
          onRowClick: ([id]) => {
            history.push(`/posts/${id}`)
          },
        }}
      />
    </div>
  )
}

export default PostList
