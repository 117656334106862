import { useCallback, useMemo, useState } from 'react'

interface PaginationParams {
  take: number
  skip: number
}

interface Options {
  rowsPerPage?: number
  currentPage?: number
}
const defualtOptions: Required<Options> = {
  rowsPerPage: 10,
  currentPage: 0,
} as const

export const usePagination = (options: Options = defualtOptions) => {
  const [currentPage, setPage] = useState<number>(options.currentPage ?? defualtOptions.currentPage)
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    options.rowsPerPage ?? defualtOptions.rowsPerPage
  )

  const changePage = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])

  const changeRowsPerPage = useCallback(
    (rowsPage: number) => {
      setPage(Math.floor((currentPage * rowsPerPage + 1) / rowsPage))
      setRowsPerPage(rowsPage)
    },
    [currentPage, rowsPerPage]
  )

  const paginationParams: PaginationParams = useMemo(
    () => ({
      take: rowsPerPage,
      skip: currentPage * rowsPerPage,
    }),
    [rowsPerPage, currentPage]
  )

  return {
    currentPage,
    changePage,
    rowsPerPage,
    changeRowsPerPage,
    paginationParams,
  }
}
