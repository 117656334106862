import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreateOrEditMember from './CreateOrEdit/CreateOrEditMember'

interface Props {
  companyId: number
}

const CompanyMemberRoutes = ({ companyId }: Props) => {
  const { t } = useTranslation(['common', 'company', 'user'])
  const match = useRouteMatch()
  const params = useParams<{ userId: string }>()
  const userId = parseInt(params.userId, 10)

  return (
    <Switch>
      {/* Edit company membership. It will uncomment later. 
      <Route path={`${match.path}/edit`}>
        <CreateOrEditMember mode={'edit'} companyId={companyId} userId={userId} />
      </Route> */}
    </Switch>
  )
}

export default CompanyMemberRoutes
