export enum Status {
  Idle = 'idle',
  Rejected = 'rejected',
  Resolved = 'resolved',
  Pending = 'pending',
}

export type Idle = {
  uploadStatus: Status.Idle
  file: File
  fileName: string
  tempSrc: string
}
export type FileSelectionMethod = 'add' | 'merge' | 'replace'

export type Pending = {
  uploadStatus: Status.Pending
  file: File
  fileName: string
}
export type Resolved = {
  uploadStatus: Status.Resolved
  fileLocation: string
  fileName: string
  assetId: number
}
export type Rejected = {
  uploadStatus: Status.Rejected
  error: string
  file: File
  fileName: string
}

export interface UploadFileOptions {
  postId?: number
  order?: number
}
export type FileStatus = Idle | Pending | Resolved | Rejected

export function createIdleStatus(file: File): FileStatus {
  return {
    fileName: file.name,
    file,
    uploadStatus: Status.Idle,
    tempSrc: URL.createObjectURL(file),
  }
}

export function createPendingStatus(file: File): FileStatus {
  return { fileName: file.name, uploadStatus: Status.Pending, file }
}
export function createErrorStatus(file: File, error: string): FileStatus {
  return { fileName: file.name, file, uploadStatus: Status.Rejected, error }
}
export function createResolvedStatus(
  fileName: string,
  fileLocation: string,
  assetId: number
): FileStatus {
  return { fileName, uploadStatus: Status.Resolved, fileLocation, assetId }
}
