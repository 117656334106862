import { TextField } from '@material-ui/core'
import Autocomplete from './Autocomplete'
import { FieldValues, UseControllerProps } from 'react-hook-form'
import { ChangeEvent, useEffect, useState, useCallback } from 'react'
import { AutocompleteProps } from '@material-ui/lab'
import { useAddressAutoComplete } from 'utils/geolocation/addressAutocomplete'
import _debounce from 'lodash/debounce'

type Props<
    T,
    FieldValueTypes,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
    > = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> &
    UseControllerProps<FieldValueTypes> & {
  label: string
  disabled?: boolean
  required?: boolean
  className?: string
}

export const LocationSelector = <
    T,
    FieldValueTypes extends FieldValues,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
    >({
        control,
        name,
        label,
        id,
        disabled,
        required,
        rules,
        defaultValue,
        className
      }: Props<T, FieldValueTypes, Multiple, DisableClearable, FreeSolo>) => {
  const textFieldProps = {
    label,
    id: id ?? null,
    disabled: !!disabled,
    required: !!required,
  }
  const [options, setOptions] = useState([])
  const [autoCompleteAddress, { data }] = useAddressAutoComplete()
  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), [])
    console.log(defaultValue) // eslint-disable-line
  useEffect(() => {
    setOptions([]);
    if (data) {
      setOptions(data);
    }
  }, [data])

  function handleDebounceFn(value: string) {
    void autoCompleteAddress(value)
  }

  return (
      <>
          <Autocomplete
              className={className}
              name={name}
              control={control}
              rules={rules}
              options={options}
              getOptionLabel={(option: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  return option?.formatted_address ?? defaultValue
              }}
              defaultValue={defaultValue}
              filterOptions={(x) => x}
              onInputChange={(
                  event: ChangeEvent<Record<string, unknown>>,
                  value: string,
                  reason: string
              ) => {
                  if (reason === 'input') debounceFn(value)
              }}
              renderInput={(param) => <TextField variant="outlined" {...textFieldProps} {...param} />}
          />
      </>
  )
}
