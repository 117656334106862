import { useEffect, useState, useMemo } from 'react'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { User, useUserQuery } from 'generated/types-and-hooks'
import defaultUserImg from 'assets/img/default-user-image.png'
import { LANGUAGES } from 'utils/constants/languages'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useUserDetailsStyles } from './styles'
import UsersCompanyMembership from './UsersCompany/UsersCompany'
import { useGlobalLoading } from 'context/LoadingContext'

interface Props {
  userId: number
}

interface BaseDataItem {
  title: string
  value: string
}

const getBaseData = (t: TFunction, user?: User | null): BaseDataItem[] => {
  if (!user) return []
  const dataTuples = [
    [t('user:property.fullName'), user?.fullName],
    [t('user:property.userName'), user?.username],
    [t('common:table.email'), user?.email],
    [t('common:table.role'), user?.role],
    [t('common:table.status'), user?.status],
  ]
  const filteredBaseData: BaseDataItem[] = []

  dataTuples.forEach(([key, value]) => {
    if (typeof key === 'string' && typeof value === 'string')
      filteredBaseData.push({ title: key, value })
  })

  return filteredBaseData
}

const ViewUser = ({ userId }: Props) => {
  const id = userId
  const { t } = useTranslation(['common', 'post', 'user'])
  const { data, loading } = useUserQuery({ variables: { where: { id } } })
  const [user, setUser] = useState<User>()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useUserDetailsStyles()
  useGlobalLoading(loading)

  useEffect(() => {
    if (data?.user && !loading) {
      const userObject = data.user as NonNullable<User>
      setUser(userObject)
    }
  }, [data])

  const baseData = useMemo(() => getBaseData(t, data?.user), [data?.user])

  const optionalData = [
    {
      title: t('user:property.languages'),
      text: user?.languages
        ?.map((userLangCode) => LANGUAGES.find((l) => l.code === userLangCode))
        .map((l) => l?.name)
        .join(', '),
    },
    {
      title: t('user:property.birthdate'),
      text:
        user?.birthDate === null ? null : new Date(Number(user?.birthDate)).toString().slice(3, 15),
    },
    { title: t('user:property.gender'), text: user?.gender },
  ]

  return (
    <Container className={matches ? classes.rootXs : classes.root}>
      <Grid
        container
        alignItems="center"
        wrap={matches ? 'wrap' : 'nowrap'}
        spacing={3}
        className={classes.baseData}
      >
        <Grid item xs={matches ? 12 : 4} className={classes.avatarGrid}>
          <Paper className={classes.avatar}>
            <img
              className={classes.image}
              src={user?.avatar?.fileLocation ?? defaultUserImg}
              alt={t('user:img.defaultProfile')}
            />
          </Paper>
        </Grid>
        <Grid item xs={matches ? 12 : 8}>
          {baseData.map((u) => (
            <Grid key={u.title.replace(' ', '').toLowerCase()} container alignItems="center">
              <Grid item xs={5}>
                <Typography key={u.title} className={classes.text} color="textPrimary">
                  {u.title}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography key={u.title} className={classes.text} color="textSecondary">
                  {u.title === baseData[3].title
                    ? t(`user:enum.role.${u.value}`)
                    : u.title === baseData[4].title
                    ? t(`user:enum.status.${u.value}`)
                    : u.value ?? '-'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {data?.user?.bio && (
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper}>
              <Typography paragraph color="textPrimary">
                {t('common:table.bio')}
              </Typography>
              <Divider className={classes.divider} />
              <Typography color="textSecondary">{data?.user?.bio}</Typography>
            </Paper>
          </Grid>
        </Grid>
      )}

      {(user?.languages || user?.birthDate || user?.gender) && (
        <Paper className={classes.paper}>
          {optionalData.map(
            (u) =>
              u.text && (
                <Grid key={u.title} container spacing={3}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">{u.title}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="textSecondary">{u.text}</Typography>
                  </Grid>
                </Grid>
              )
          )}
        </Paper>
      )}
      <UsersCompanyMembership userId={userId} />
    </Container>
  )
}

export default ViewUser
