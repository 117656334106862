import { CompanyMembershipRole, CompanyMembershipStatus } from 'generated/types-and-hooks'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type FormValues = {
  name: string
  email: string
  roleAtCompany: CompanyMembershipRole
  statusAtCompany: CompanyMembershipStatus
}

export const useCompanyMemberFormValidation = (register: UseFormRegister<FormValues>) => {
  const { t } = useTranslation('post')

  register('email', {
    required: String(t('post:members.emailRequired')),
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: t('post:members.emailError'),
    },
  })
}
