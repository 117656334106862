import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ImageUploadWithCrop from 'components/Image/ImageUploadWithCrop'
import useImageUpload from 'utils/hooks/useImageUpload'

import { useObjectUrl } from 'utils/hooks/useObjectUrl'
import { Box, Button, Container, DialogTitle, Grid, MenuItem, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Select, TextField, Checkbox, LanguageSelector } from 'components/Form/Atoms'
import { useTranslation } from 'react-i18next'
import AppButton from 'components/Button/AppButton'
import { useHistory } from 'react-router-dom'

import { getEditorState, getMarkup } from 'components/utils/draftJsConverter'

import {
  CompanyMembershipStatus,
  CompanyStatus,
  useCompanyLazyQuery,
  useCompanyToDraftMutation,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useUserLazyQuery, LocationInput, useCompanyMembershipsLazyQuery
} from 'generated/types-and-hooks'
import { useSnackbar } from 'notistack'
import { useCompanyFormValidation } from './validation'
import { useGlobalLoading } from 'context/LoadingContext'
import { SpaceSelector } from 'components/Form/Atoms/Selectors/SpaceSelector'
import { FormValues } from './model'
import { useStyles } from './styles'
import ColorSelector from 'components/Form/Atoms/Selectors/ColorSelector'
import { LocationSelector } from "components/Form/Atoms/LocationSelector";
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

type EditProps = { mode: 'edit'; companyId: number }
type CreateProps = { mode: 'create' }
type Props = EditProps | CreateProps

const CreateOrEditCompany = (props: Props) => {
  const formMode = props.mode
  const companyId = props.mode === 'edit' ? props.companyId : null
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'company', 'space'])
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [statusIsRequested, setStatusIsRequested] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [editorDescription, setEditorDescription] = useState<EditorState>(EditorState.createEmpty())

  // Image Handling
  const [selectedImage, setSelectedImage] = useState<File>()
  const selectedImageSrc = useObjectUrl(selectedImage)
  const [
    startUploadingImage,
    { error: uploadError, isLoading: isUploading, assetId: logoId, fileLocation: uploadedImgSrc },
  ] = useImageUpload()
  // Form
  const defaultValues: FormValues = {
    name: '',
    endpoint: '',
    visibility: '',
    address: '',
    radius: 0,
    status: CompanyStatus.Inactive,
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
    spaceId: null,
    superAdminEmail: '',
    shouldCreateServer: false,
    gender: '',
    ageFrom: 0,
    ageTo: 0,
    activeDateFrom: '',
    activeDateTo: '',
    language: []
  }
  const {
    control,
    reset,
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const [formValues, setFormValues] = useState<FormValues>(defaultValues)
  const [emailChecked, setEmailChecked] = useState(false)

  // Data handling
  const [getCompany, { data: companyData, loading }] = useCompanyLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [getCompanyMemberships, { data: companyMembershipsData, loading: membershipsLoading }] = useCompanyMembershipsLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [getUser, { data: userData, loading: userLoading }] = useUserLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [createCompany, { data: requestedCompany, loading: requestLoading }] =
    useCreateCompanyMutation({
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'companiesConnection' })
        cache.gc()
      },
      optimisticResponse: {},
    })

  const [companyToDraft, { data: draftedCompany, loading: draftLoading }] =
    useCompanyToDraftMutation()
  const [updateCompany, { data: updatedCompany, loading: updateLoading }] =
    useUpdateCompanyMutation()

  useGlobalLoading(loading || requestLoading || draftLoading || updateLoading)
  useCompanyFormValidation(register, statusIsRequested)

  // Fetch company data when editing
  useEffect(() => {
    if (props.mode === 'create' || !companyId) return
    getCompany({ variables: { where: { id: companyId } } })
    getCompanyMemberships({ variables: { where: { company: { id: companyId } } } })
  }, [props.mode, companyId])

  useEffect(() => {
    if (companyMembershipsData?.companyMembershipsConnection?.count === 1
      && companyMembershipsData?.companyMembershipsConnection?.memberships?.length
      && companyMembershipsData?.companyMembershipsConnection?.memberships[0]?.user?.email
    ) {
      setValue('superAdminEmail', companyMembershipsData?.companyMembershipsConnection?.memberships[0]?.user?.email);
    }
  }, [companyMembershipsData])

  // Set the form values based on fetched company data
  useEffect(() => {
    if (!companyData?.company) return
    if (companyData?.company?.status === 'REQUESTED') setStatusIsRequested(true)

    if (!companyData?.company) return

    console.log('companyData', companyData);
    const markup = companyData?.company?.aboutUs?.[0]?.content
      ? companyData.company.aboutUs[0].content
      : ''

    const markupDescription = companyData?.company?.description?.[0]?.content
      ? companyData.company.description[0].content
      : ''
    setEditorState(getEditorState(markup))
    setEditorDescription(getEditorState(markupDescription))

    reset({
      name: companyData.company.name ?? '',
      introduction: companyData.company.introduction ?? '',
      visibility: companyData.company.visibility ?? 'OPEN',
      status: companyData.company.status ?? CompanyStatus.Inactive,
      endpoint: companyData.company.endpoint && companyData.company.endpoint !== 'OPEN' ? companyData.company.endpoint : '',
      address: companyData.company.address ?? '',
      radius: companyData.company.radius ?? 0,
      primaryColor: companyData.company.primaryColor ? companyData.company.primaryColor : '#FFFFFF',
      secondaryColor: companyData.company.secondaryColor
        ? companyData.company.secondaryColor
        : '#FFFFFF',
      spaceId: Number(companyData.company.space?.id),
      gender: companyData.company.gender ?? '',
      ageFrom: companyData.company.ageFrom ?? 0,
      ageTo: companyData.company.ageTo ?? 0,
      activeDateFrom: companyData?.company?.activeDateFrom === null ? '0' : new Date(Number(companyData?.company?.activeDateFrom)).toISOString().substr(0, 10),
      activeDateTo: companyData?.company?.activeDateTo === null ? '0' : new Date(Number(companyData?.company?.activeDateTo)).toISOString().substr(0, 10),
      language: companyData.company.language ?? [],
    })
  }, [companyData])


  const onSubmit = (values: FormValues) => {
    const locIn: LocationInput = {
      lat: parseFloat(Object(values.address)?.geometry?.location?.lat) ? parseFloat(Object(values.address)?.geometry?.location?.lat) : companyData?.company?.location?.lat,
      long: parseFloat(Object(values.address)?.geometry?.location?.lng) ? parseFloat(Object(values.address)?.geometry?.location?.lng) : companyData?.company?.location?.long
    };

    if (selectedImage) startUploadingImage(selectedImage)
    else if (formMode === 'create') {
      const result = createCompany({
        variables: {
          input: {
            name: values?.name || '',
            introduction: values.introduction || '',
            endpoint: values?.endpoint || '',
            primaryColor: values.primaryColor || '',
            visibility: values.visibility,
            radius: Number(values.radius),
            status: values.status ?? CompanyStatus.Inactive,
            address: Object(values.address).formatted_address,
            location: {
              ...locIn
            },
            secondaryColor: values.secondaryColor,
            space: { id: Number(values.spaceId) },
            shouldCreateServer: values?.shouldCreateServer ?? false,
            gender: values.gender,
            ageFrom: Number(values.ageFrom),
            ageTo: Number(values.ageTo),
            activeDateFrom: values.activeDateFrom,
            activeDateTo: values.activeDateTo,
            language: values.language,
          },
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(e.message, { variant: 'error' })
        enqueueSnackbar(t('common:status.unSuccessRequest', { entity: t('company:name') }), {
          variant: 'error',
        })
        setSubmitted(false)
      })
    } else if (formMode === 'edit' && companyId && statusIsRequested) {
      getUser({ variables: { where: { email: values.superAdminEmail } } })
      setEmailChecked(true)
      setFormValues(values)
    } else if (formMode === 'edit' && companyId) {
      const result = updateCompany({
        variables: {
          where: { id: companyId },
          input: {
            name: values.name,
            introduction: values.introduction,
            endpoint: values.endpoint,
            visibility: values.visibility,
            radius: Number(values.radius),
            status: values.status ?? CompanyStatus.Inactive,
            address: Object(values.address).formatted_address,
            location: {
              ...locIn
            },
            primaryColor: values.primaryColor,
            secondaryColor: values.secondaryColor,
            space: { id: Number(values.spaceId) },
            gender: values.gender,
            ageFrom: Number(values.ageFrom),
            ageTo: Number(values.ageTo),
            activeDateFrom: values.activeDateFrom?new Date(values.activeDateFrom).toISOString():null,
            activeDateTo: values.activeDateTo?new Date(values.activeDateTo).toISOString():null,
            language: values.language

          },
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(e.message, { variant: 'error' })
        enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('company:name') }), {
          variant: 'error',
        })
        setSubmitted(false)
      })
    }
  }

  useEffect(() => {
    if (!userData) return
    if (emailChecked && !userLoading && !userData?.user?.id) {
      enqueueSnackbar(t('company:form.noUserFound'), { variant: 'error' })
      setEmailChecked(false)
      return
    }
    const result = companyToDraft({
      variables: {
        where: { id: companyId },
        input: {
          name: formValues.name,
          introduction: formValues.introduction,
          endpoint: formValues.endpoint || '',
          visibility: formValues.visibility,
          status: formValues.status ?? CompanyStatus.Inactive,
          primaryColor: formValues.primaryColor,
          secondaryColor: formValues.secondaryColor,
          space: { id: Number(formValues.spaceId) },
          superadminID: Number(userData.user?.id),
        },
      },
    })
    result.catch((e) => {
      console.error(e)
      enqueueSnackbar(e.message, { variant: 'error' })
      enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('company:name') }), {
        variant: 'error',
      })
      setSubmitted(false)
    })
  }, [userData])

  // Effect after an image is uploaded
  useEffect(() => {
    if (!uploadedImgSrc) return
    // if (formMode === 'create')
    // requestCompany({
    //   variables: {
    //     where: { id: companyId },
    //     input: { ...getValues(), logo: { id: logoId } },
    //   },
    // })
    updateCompany({
      variables: {
        where: { id: companyId },
        input: { ...getValues(), logo: { id: logoId } },
      },
    })
  }, [uploadedImgSrc])

  useEffect(() => {
    if (!requestedCompany) return
    history.goBack()
    enqueueSnackbar(t('common:status.successRequest', { entity: t('company:name') }), {
      variant: 'success',
    })
  }, [requestedCompany])

  useEffect(() => {
    if (!updatedCompany && !draftedCompany) return
    history.goBack()
    enqueueSnackbar(t('common:status.successUpdate', { entity: t('company:name') }), {
      variant: 'success',
    })
  }, [updatedCompany, draftedCompany])

  let uploadErrorMessage = uploadError
  if (errors?.logo && 'id' in errors.logo) {
    const msg = errors?.logo?.id?.message
    if (msg) uploadErrorMessage = msg
  }

  return (
    <>
      {loading ? null : (
        <Container maxWidth={matches ? 'md' : 'sm'} className={classes.root}>
          <Paper elevation={2} className={classes.paper}>
            <DialogTitle>
              {formMode === 'edit'
                ? statusIsRequested
                  ? t('company:form.companyToDraft')
                  : t('company:form.editCompany')
                : t('company:form.requestCompany')}
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.formBody}>
              <ImageUploadWithCrop
                image={{
                  src: (selectedImageSrc || companyData?.company?.logo?.fileLocation) ?? '',
                  alt: 'company-logo',
                  height: '230px',
                  width: '230px',
                }}
                isUploading={isUploading}
                disabled={props.mode === 'edit'}
                buttonLabel="Upload"
                uploadError={uploadErrorMessage}
                onCroppedImage={(file: File) => setSelectedImage(file)}
              />

              <Grid container spacing={3} className={classes.formBody__fieldsArea}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    disabled={props.mode === 'edit'}
                    control={control}
                    id="name"
                    name="name"
                    label={t('common:table.name')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    disabled={props.mode === 'edit'}
                    control={control}
                    id="introduction"
                    name="introduction"
                    label={t('common:table.introduction')}
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box p={2}>
                    <Typography>{t('common:table.description')}</Typography>
                    <Editor
                      placeholder={t(`company:descriptionDot`)}
                      editorState={editorDescription}
                      readOnly={props.mode === 'edit'}
                      toolbarHidden
                      onEditorStateChange={setEditorDescription}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={2}>
                    <Typography>{t('company:aboutUs')}</Typography>
                    <Editor
                      placeholder={t(`company:aboutUsDot`)}
                      editorState={editorState}
                      readOnly={props.mode === 'edit'}
                      toolbarHidden
                      onEditorStateChange={setEditorDescription}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="endpoint"
                    name="endpoint"
                    label={t('common:table.url')}
                    required={statusIsRequested}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Select
                    required
                    fullWidth
                    variant="outlined"
                    id="visibility"
                    name="visibility"
                    label={'Visibility'}
                    control={control}
                  >
                    {['OPEN', 'HALF_OPEN', 'HALF_CLOSED', 'CLOSED'].map((option, i) => {
                      const nameArray = ['Open', 'Half open', 'Half closed', 'Closed']
                      return (
                        <MenuItem key={option} value={option}>
                          {nameArray[i]}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
                <Grid xs={12} item>
                  <Select
                    required
                    fullWidth
                    variant="outlined"
                    id="status"
                    name="status"
                    label={'Status'}
                    control={control}
                  >
                    {Object.values(CompanyStatus).map((option) => (
                      <MenuItem key={option} value={option}>
                        <span style={{ textTransform: 'capitalize' }}>{option}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid xs={12} item>
                  <LocationSelector
                    control={control}
                    name="address"
                    label={'Address'}
                    defaultValue={companyData?.company?.address ?? defaultValues.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    type="number"
                    id="radius"
                    name="radius"
                    label={t('common:table.radius')}
                  />
                </Grid>
                {statusIsRequested && companyMembershipsData?.companyMembershipsConnection?.memberships?.length && (
                  <Grid item xs={12}>
                    <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="superAdminEmail"
                      name="superAdminEmail"
                      label={t('company:form.superAdminEmail')}
                      required={statusIsRequested}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SpaceSelector
                    className={classes.inputField}
                    control={control}
                    name="spaceId"
                    label={t('space:name')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="gender"
                    name="gender"
                    select
                    SelectProps={{ native: true }}
                    label={t('common:table.gender')}
                  >
                    <option>---</option>
                    <option value="MALE">Férfi</option>
                    <option value="FEMALE">Nő</option>
                    <option value="OTHER">egyéb</option>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <LanguageSelector
                    className={classes.inputField}
                    fullWidth
                    multiple
                    id="language"
                    name="language"
                    label={t('user:property.languages')}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>

                  <Grid spacing={1} container>

                    <Grid item xs={6}>
                      <TextField
                        className={classes.inputField}
                        fullWidth
                        control={control}
                        type="number"
                        id="ageFrom"
                        name="ageFrom"
                        label={t('common:table.ageFrom')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className={classes.inputField}
                        fullWidth
                        control={control}
                        type="number"
                        id="ageTo"
                        name="ageTo"
                        label={t('common:table.ageTo')}
                      />
                    </Grid>

                  </Grid>
                  <Grid item xs={12}>

                    <Grid spacing={1} container>

                      <Grid item xs={6}>
                        <TextField
                          className={classes.inputField}
                          fullWidth
                          control={control}
                          type="date"
                          id="activeDateFrom"
                          name="activeDateFrom"
                          label={t('common:table.activeDateFrom')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className={classes.inputField}
                          fullWidth
                          control={control}
                          type="date"
                          id="activeDateTo"
                          name="activeDateTo"
                          label={t('common:table.activeDateTo')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    disabled={props.mode === 'edit'}
                    control={control}
                    name="shouldCreateServer"
                    color="primary"
                    label={
                      <>
                        <span>{t('company:form.createNewServer')}</span>
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <ColorSelector
                    className={matches ? classes.inputField : classes.inputFieldXs}
                    watch={watch}
                    control={control}
                    disabled={props.mode === 'edit'}
                    setValue={setValue}
                    name="primaryColor"
                    label={t('common:table.primaryColor')}
                    colorByTyping
                    required
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <ColorSelector
                    className={matches ? classes.inputField : classes.inputFieldXs}
                    watch={watch}
                    control={control}
                    disabled={props.mode === 'edit'}
                    setValue={setValue}
                    name="secondaryColor"
                    label={t('common:table.secondaryColor')}
                    colorByTyping
                  />
                </Grid>


                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button
                      color="secondary"
                      onClick={() => history.goBack()}
                      className={classes.button}
                    >
                      {t('common:action.cancel')}
                    </Button>
                    <AppButton
                      color="primary"
                      className={classes.button}
                      variant="contained"
                      type="submit"
                      disabled={submitted}
                      loading={emailChecked || requestLoading || draftLoading || updateLoading}
                    >
                      {t('common:action.save')}
                    </AppButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      )}
    </>
  )
}

export default CreateOrEditCompany
