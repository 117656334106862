import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { useForm } from 'react-hook-form'
import { TextField } from 'components/Form/Atoms'

interface SearchBarProps {
  onSearch: (search: string) => void
  onClear?: () => void
}

export const SearchBox = React.memo((props: SearchBarProps) => {
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: { search: '' },
  })
  const { t } = useTranslation('common')
  const onSubmit = ({ search }: { search: string }) => {
    props.onSearch(search)
  }

  const handleClear = props.onClear
    ? () => {
        reset()
        if (props?.onClear) props.onClear()
      }
    : () => null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" alignItems="center">
        <TextField
          label={t('common:action.search')}
          size={'small'}
          control={control}
          variant="outlined"
          name="search"
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton size="small" aria-label="toggle password visibility" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
                {props.onClear && watch('search') ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={handleClear}
                      aria-label={t('common:action.clear')}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null}
              </>
            ),
          }}
        />
      </Box>
    </form>
  )
})
