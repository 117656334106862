import { createImage } from './createImage'

export async function normalize(
  file: File | Blob,
  maxPxSize: number,
  maxKbSize?: number
): Promise<Blob | File> {
  const fileApiSuport = !!(window.File && window.FileReader)
  if (!/image.*/.exec(file.type) || !fileApiSuport) {
    return file
  }
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) return file
  if (maxKbSize && file.size < maxKbSize / 1000) return file

  const imgUrl = URL.createObjectURL(file)
  const img = await createImage(imgUrl)
  URL.revokeObjectURL(imgUrl)

  let width = img.width
  let height = img.height

  if (width <= maxPxSize && height <= maxPxSize) {
    return file
  } else if (width > height) {
    height *= maxPxSize / width
    width = maxPxSize
  } else {
    width *= maxPxSize / height
    height = maxPxSize
  }

  canvas.width = width
  canvas.height = height
  ctx.drawImage(img, 0, 0, width, height)

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob)
        } else {
          resolve(file)
        }
      },
      file.type,
      100
    )
  })
}
