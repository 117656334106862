import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export const getEditorState = (markup: string): EditorState => {
  const blocksFromHtml = htmlToDraft(markup)
  const { contentBlocks } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, blocksFromHtml.entityMap)
  const editorState = EditorState.createWithContent(contentState)
  return editorState
}

export const getMarkup = (editorState: EditorState): string => {
  const rawContentState = convertToRaw(editorState.getCurrentContent())
  const markup: string = draftToHtml(rawContentState)
  return markup
}
