import { useMemo } from 'react'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  Icon,
} from '@material-ui/core'
import BackDropLoading from 'components/utils/BackDropLoading'
import { Space, useSpaceQuery } from 'generated/types-and-hooks'
import defaultLogo from 'assets/img/default-logo.png'
import { useTranslation } from 'react-i18next'
import { useSpaceViewStyles } from './styles'

interface Props {
  spaceId: number
}

const SpaceView = ({ spaceId }: Props) => {
  const { data, loading } = useSpaceQuery({ variables: { id: spaceId } })
  const { t } = useTranslation(['common', 'space'])
  const classes = useSpaceViewStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  const space: NonNullable<Space> = useMemo<Space>(() => {
    if (data?.space && !loading) {
      const spaceData = data.space as NonNullable<Space>
      return spaceData
    }
    return {} as NonNullable<Space>
  }, [data])

  const baseData = [
    { title: t('common:table.name'), text: space.name },
    { title: t('common:table.icon'), text: space.icon },
    { title: t('common:table.primaryColor'), text: space.primaryColor },
    { title: t('common:table.secondaryColor'), text: space.secondaryColor },
    {
      title: t('common:table.created'),
      text: new Date(Number(space.createdAt)).toString().substr(3, 18) ?? '',
    },
    {
      title: t('common:table.updated'),
      text: new Date(Number(space.updatedAt)).toString().substr(3, 18) ?? '',
    },
  ]

  if (loading) {
    return <BackDropLoading />
  } else {
    return (
      <Container className={matches ? classes.rootXs : classes.root}>
        <Grid container alignItems="center" wrap={matches ? 'wrap' : 'nowrap'} spacing={3}>
          <Grid item xs={matches ? 12 : 4} className={classes.logoGrid}>
            <Paper className={matches ? classes.logoXs : classes.logo}>
              {space?.logo?.fileLocation ? (
                <img
                  className={classes.image}
                  alt={t('company:companyLogo')}
                  src={space.logo.fileLocation}
                />
              ) : (
                <img className={classes.image} src={defaultLogo} alt={t('company:defaultLogo')} />
              )}
            </Paper>
          </Grid>
          <Grid item xs={matches ? 12 : 8} className={classes.baseData}>
            {baseData.map((u) => (
              <Grid key={u.title.replace(' ', '').toLowerCase()} container alignItems="center">
                <Grid item xs={5}>
                  <Typography className={classes.textLeft} color="textPrimary">
                    {u.title}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {u.title === baseData[1].title ? (
                    <Icon fontSize="large">{u.text}</Icon>
                  ) : u.title === baseData[2].title || u.title === baseData[3].title ? (
                    <div className={classes.colorBox} style={{ background: u.text ?? '-' }}></div>
                  ) : (
                    <Typography className={classes.textRight} color="textSecondary">
                      {u.text ?? '-'}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {space.description && (
          <Grid container spacing={3}>
            <Grid item xs>
              <Paper className={classes.paper}>
                <Typography paragraph color="textPrimary">
                  {t('common:table.description')}
                </Typography>
                <Divider className={classes.divider} />
                <Typography color="textSecondary">{space.description}</Typography>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
    )
  }
}

export default SpaceView
