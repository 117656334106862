import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useDocumentStyles = makeStyles((theme: Theme) =>
  createStyles({
    // --- Privacy Policy, Terms And Conditions ---
    root: {
      flexGrow: 1,
      width: 'calc(50% + 15rem)',
      minWidth: '30rem',
      marginTop: '1.5rem',
    },
    rootXs: {
      flexGrow: 1,
      width: '90%',
      marginTop: '1.5rem',
    },
    text: {
      margin: '1rem 0',
    },
    paperTerms: {
      padding: theme.spacing(2),
      margin: '1rem 0 2.5rem',
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  })
)
