import {Button, Divider, Paper, Box, makeStyles, Container} from '@material-ui/core'
import {EditorState} from 'draft-js'
import {
    usePrivacyPolicyLazyQuery,
    useTermsConditionsLazyQuery,
    useUpdatePrivacyPolicyMutation,
    useUpdateTermsConditionsMutation,
} from 'generated/types-and-hooks'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import BackDropLoading from 'components/utils/BackDropLoading'

import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {getEditorState, getMarkup} from 'components/utils/draftJsConverter'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() => ({
    root: {
        padding: '1rem',
        '& .rdw-editor-main': {
            maxHeight: '50vh',
            overflow: 'scroll',
        },
        marginBottom: '3rem',
    },
    divider: {
        margin: '0.5rem 0',
    },
}))

export default function DocumentEditor() {
    const {t} = useTranslation(['common', 'company'])
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
    const history = useHistory()
    const {enqueueSnackbar} = useSnackbar()
    const [updatePrivacyPolicy, {loading: updatingPrivPol}] = useUpdatePrivacyPolicyMutation()
    const [updateTermsAndConditions, {loading: updatingTermsCon}] =
        useUpdateTermsConditionsMutation()
    const [
        getPrivacyPolicy,
        {data: privacyPolicyData, loading: privacyPolicyLoading, error: privacyPolicyError},
    ] = usePrivacyPolicyLazyQuery()
    const [
        getTermsAndConditions,
        {data: termsAndConditionsData, loading: termsConditionsLoading, error: termsConditionsError},
    ] = useTermsConditionsLazyQuery()
    const params = useParams<{ documentType?: string }>()
    //
    const companyId = 1
    //
    useEffect(() => {
        const getterFn = params?.documentType === 'privacy' ? getPrivacyPolicy : getTermsAndConditions
        getterFn()
    }, [params])
    const loading = termsConditionsLoading || privacyPolicyLoading
    const error = privacyPolicyError ?? termsConditionsError
    const updating = updatingTermsCon || updatingPrivPol
    useEffect(() => {
        const markup =
            params?.documentType === 'privacy'
                ? privacyPolicyData?.company?.privacyPolicy?.[0]?.content
                : termsAndConditionsData?.company?.termsAndConditions?.[0]?.content
        if (!markup) return
        setEditorState(getEditorState(markup))
    }, [params, privacyPolicyData, termsAndConditionsData])
    const classes = useStyles()
    const onSave = () => {
        const variables = {
            where: {id: companyId},
            input: {
                termsAndConditions: {
                    language: 'en',
                    default: true,
                    content: getMarkup(editorState),
                },
            },
        }
        //const updateFn =
        //params?.documentType === 'privacy' ? updatePrivacyPolicy : updateTermsAndConditions
        //updateFn({ variables })
        updateTermsAndConditions()
            .then(() => {
                enqueueSnackbar(
                    t('common:status.successUpdate', {
                        entity: t('company:documents.document'),
                    }),
                    {variant: 'success'}
                )
                history.push(`/company-settings/documents/${params.documentType}`)
            })
            .catch((e) => {
                if (e instanceof Error) {
                    enqueueSnackbar(e.message, {variant: 'error'})
                }
            })
    }
    if (error) enqueueSnackbar(error.message, {variant: 'error'})
    return (
        <>
            <Container maxWidth="md">
                {updating ? <BackDropLoading/> : null}
                <Paper className={classes.root}>
                    {loading ? <BackDropLoading/> : null}
                    <Box p={2}>
                        <Editor editorState={editorState} onEditorStateChange={setEditorState}/>
                    </Box>
                    <Divider className={classes.divider}/>
                    <Box display="flex" justifyContent="flex-end">
                        <Button color="secondary" onClick={() => history.goBack()}>
                            {t('common:action.cancel')}
                        </Button>
                        <Button color="primary" onClick={onSave}>
                            {t('common:action.save')}
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </>
    )
}
