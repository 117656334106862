import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'
import {
  Checkbox as MUICheckbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import { ReactNode } from 'react'

type Props<T> = UseControllerProps<T> & Partial<CheckboxProps> & { label?: string | ReactNode }

const useStyles = makeStyles(() => ({
  formControlLabel: {
    margin: 0,
  },
  errorMessage: {
    margin: '-0.5rem 0 0.5rem 1.5625rem', // 1.5625rem: to set the same leftMargin as errorMessage of MUI Checkbox
  },
}))

export const Checkbox = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  label,
  rules,
  ...props
}: Props<T>) => {
  const classes = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControlLabel
            className={classes.formControlLabel}
            control={<MUICheckbox {...field} {...props} />}
            label={label}
          />
          {error ? (
            <FormHelperText className={classes.errorMessage} error={true}>
              {error.message}
            </FormHelperText>
          ) : null}
        </>
      )}
    />
  )
}
