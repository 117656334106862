import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface Props extends DialogProps {
  fullScreenBreakpoint?: Breakpoint
}

export default function ResponsiveDialog({ children, fullScreenBreakpoint, ...props }: Props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenBreakpoint ?? 'sm'))

  props.fullScreen = props.fullScreen ? props.fullScreen : fullScreen

  return <Dialog {...props}>{children}</Dialog>
}
