import { Button, Typography } from '@material-ui/core'
import { TextField } from 'components/Form/Atoms'
import { useState } from 'react'
import { FieldValues, UseControllerProps, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { makeStyles, createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useColorSelectorStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttons: {
      height: '1.5rem',
      marginTop: '1rem',
    },
    text: {
      fontSize: '0.75rem',
    },
  })
)

type Props<FieldValueTypes> = UseControllerProps<FieldValueTypes> & {
  label: string
  disabled?: boolean
  required?: boolean
  className?: string
  colorByTyping?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch?: UseFormWatch<FieldValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: UseFormSetValue<FieldValues>
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ColorSelector = <FieldValueTypes extends FieldValues>({
  control,
  watch,
  setValue,
  name,
  label,
  required,
  className,
  colorByTyping,
  disabled
}: Props<FieldValueTypes>) => {
  const { t } = useTranslation('selector')
  const classes = useColorSelectorStyles()
  const [colorPicker, setColorPicker] = useState(true)

  const inputTypeHandler = () => {
    if (setValue && watch) {
      setColorPicker(!colorPicker)
      setValue(`${name}`, watch(`${name}`))
    }
  }

  return (
    <div className={`${className} ${classes.container}`}>
      <TextField
        fullWidth
        name={name}
        label={label}
        control={control}
        required={required}
        disabled={disabled??false}
        SelectProps={{ native: true }}
        type={colorPicker ? 'color' : 'text'}
      />
      {colorByTyping && (
        <Button className={classes.buttons} variant="outlined" onClick={inputTypeHandler}>
          <Typography className={classes.text} color="textSecondary">
            {colorPicker ? t('selector:color.colorCode') : t('selector:color.colorPicker')}
          </Typography>
        </Button>
      )}
    </div>
  )
}

export default ColorSelector
