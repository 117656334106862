import { createImage } from './createImage'

export async function resize(
  initialImageSrc: string,
  wantedWidth: number,
  wantedHeight: number
): Promise<Blob> {
  const img = await createImage(initialImageSrc)

  const canvas: HTMLCanvasElement = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error()

  canvas.width = wantedWidth
  canvas.height = wantedHeight

  ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight)
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject(new Error('Error during image resizing'))
        }
      },
      'image/jpeg',
      90
    )
  })
}
