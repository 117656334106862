import { useEffect, useState } from 'react'

export const useObjectUrl = (file: File | undefined) => {
  const [objectUrl, setObjectUrl] = useState('')

  useEffect(() => {
    if (!file) return
    setObjectUrl((prevObjUrl) => {
      if (prevObjUrl) URL.revokeObjectURL(prevObjUrl)
      return URL.createObjectURL(file)
    })

    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  return objectUrl
}
