import { ReactElement, useEffect, useState } from 'react'
import {
  TextField,
  Checkbox,
  Grid,
  FormControlLabel,
  Box,
  Link as MUILink,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import AppButton from 'components/Button/AppButton'
import { useSnackbar } from 'notistack'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(() =>
  createStyles({
    title: { textAlign: 'center', userSelect: 'none' },
    formFields: {
      marginTop: '2rem',
    },
    lastRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    loginBtn: {
      marginRight: 0,
    },
  })
)

interface LoginFormProps {
  onLoginClick: (email: string, password: string, rememberMe?: boolean) => void
  loading?: boolean
  error?: Error
}

export default function LoginForm(props: LoginFormProps): ReactElement {
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const history = useHistory()

  const handleLoginClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.onLoginClick(email, password, rememberMe)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={3}
      px={5}
    >
      <h1 className={classes.title}>Login</h1>
      <form className={classes.formFields}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="password"
            />
          </Grid>
          <Grid item>
            {props.error ? <Alert severity="error">{props.error.message}</Alert> : null}
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    name="rememberMe"
                  />
                }
                label="Remember me"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.lastRow}>
              <MUILink component={Link} to="/signup">
                Sign up
              </MUILink>
              <AppButton
                disabled={!password || !email}
                className={classes.loginBtn}
                loading={props?.loading}
                type="submit"
                onClick={handleLoginClick}
                variant="contained"
                color="primary"
              >
                Login
              </AppButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
