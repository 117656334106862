import { makeStyles, createStyles } from '@material-ui/core/styles'

export interface UserTableData {
  allCount: number
  activeCount: number
  inactiveCount: number
  passiveCount: number
  registeredCount: number
  bannedCount: number
}

export interface AllCompanyMembersTableData extends Omit<UserTableData, 'registeredCount'> {
  requestedCount: number
}

export interface CompanyMembersTableData extends AllCompanyMembersTableData {
  companyId: number
  companyName: string
}

export const tableStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: '40rem',
      margin: '0 auto 3rem',
    },
    containerUpMd: {
      maxWidth: '65rem',
      margin: '0 auto 3rem',
      '& .MuiTableCell-root': {
        width: '14%',
      },
    },
    table: {
      marginBottom: '3rem',
      padding: '0 0.5rem',
    },
    text: {
      fontSize: '0.875rem',
    },
  })
)
