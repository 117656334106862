import { Button, CircularProgress, ButtonProps, createStyles, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'inline-block',
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
)

interface Props extends ButtonProps {
  loading?: boolean
  success?: boolean
}

const AppButton = ({ disabled, children, loading, success, ...btnProps }: Props) => {
  const classes = useStyles()
  const getDisabledProp = () => {
    if (loading) return true
    if (disabled) return true
    return false
  }

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })
  return (
    <div className={classes.wrapper}>
      <Button className={buttonClassname} disabled={getDisabledProp()} {...btnProps}>
        {children}
      </Button>
      {loading ? (
        <CircularProgress size={24} color="primary" className={classes.buttonProgress} />
      ) : null}
    </div>
  )
}

export default AppButton
