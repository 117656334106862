import UserRoutes from 'modules/user'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import UserList from './UserList'

const Users = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <UserList />
      </Route>
      <Route path={`${match.path}/:userId`}>
        <UserRoutes />
      </Route>
    </Switch>
  )
}

export default Users
