import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import FallbackComponent from 'components/utils/ErrorBoundaryFallback'
import App from './App'
import './i18n'
import AppProviders from 'AppProviders'
import BackDropLoading from 'components/utils/BackDropLoading'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <AppProviders>
        <Suspense fallback={BackDropLoading}>
          <App />
        </Suspense>
      </AppProviders>
    </ErrorBoundary>
  </React.StrictMode>,
  document.querySelector('#root')
)
