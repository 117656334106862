import { useState } from 'react'

import SingleImageUpload from 'components/Image/SingleImageUpload'

import ImageCropperDialog from 'components/Image/ImageCropperDialog'

interface Props {
  isUploading: boolean
  uploadError: string | Error
  image: {
    src: string
    alt: string
    width: string
    height: string
  }
  onCroppedImage: (file: File) => void
  buttonLabel: string
  circular?: boolean
  disabled?: boolean
}

export default function ImageUploadWithCrop(props: Props) {
  const [resizeDialog, setResizeDialog] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File | null>()

  const handleCrop = (file: File) => {
    props.onCroppedImage(file)
    setSelectedImage(null)
    setResizeDialog(false)
  }

  const handleSelect = (file: File) => {
    setSelectedImage(file)
    setResizeDialog(true)
  }
  return (
    <div style={{ maxWidth: props.image.width ?? 'inherit' }}>
      {selectedImage ? (
        <ImageCropperDialog
          isOpen={resizeDialog}
          onClose={() => {
            setResizeDialog(false)
          }}
          onCrop={handleCrop}
          initialImage={selectedImage}
        />
      ) : null}
      <SingleImageUpload
        onImageSelect={handleSelect}
        imageWidth={props.image.width}
        imageHeight={props.image.height}
        buttonLabel={props.buttonLabel}
        isLoading={props.isUploading}
        imageSrc={props.image.src}
        imageAlt={props.image.alt}
        disabled={props.disabled??false}
        error={props.uploadError ?? ''}
      />
    </div>
  )
}
