import { useMemo, useState } from 'react'
import { User, UserRole, UserStatus, useUserListQuery } from 'generated/types-and-hooks'
import { UserTableData, tableStyles } from './model_styles'
import { useTranslation } from 'react-i18next'
import Users from './Tables/Users'
import PageHeader from 'components/utils/PageHeader'
import CompanyMembers from './Tables/CompanyMembers'
import { useMediaQuery, useTheme } from '@material-ui/core'
import AllCompanyMembers from './Tables/AllCompanyMembers'

const Statistics = () => {
  const [userTableData, setUserTableData] = useState<UserTableData[]>([])
  const { t } = useTranslation(['stat', 'user'])
  const classes = tableStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const { data: userListData, loading } = useUserListQuery({
    variables: {
      where: {
        NOT: [{ role: UserRole.Admin }, { role: UserRole.SuperAdmin }],
      },
    },
  })

  useMemo(() => {
    if (!userListData?.usersConnection?.users) return []

    const usersArray = userListData.usersConnection.users as NonNullable<User>[]
    const allUsers: UserTableData[] = [
      {
        allCount: userListData?.usersConnection?.count ?? 0,
        activeCount: usersArray.filter((u) => u.status === UserStatus.Active).length,
        inactiveCount: usersArray.filter((u) => u.status === UserStatus.Inactive).length,
        passiveCount: usersArray.filter((u) => u.status === UserStatus.Passive).length,
        registeredCount: usersArray.filter((u) => u.status === UserStatus.Registered).length,
        bannedCount: usersArray.filter((u) => u.status === UserStatus.Banned).length,
      },
    ]
    if (allUsers) setUserTableData(allUsers)
    return allUsers
  }, [userListData])

  return (
    <>
      <PageHeader>{t('stat:name')}</PageHeader>
      <div className={matches ? classes.containerUpMd : classes.container}>
        <Users userTableData={userTableData} loading={loading} />
        <AllCompanyMembers />
        <CompanyMembers />
      </div>
    </>
  )
}

export default Statistics
