import { useHistory, useRouteMatch } from 'react-router-dom'
import PageHeader from 'components/utils/PageHeader'
import { Breadcrumbs, Chip } from '@material-ui/core'
import { emphasize, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { useSpaceQuery } from 'generated/types-and-hooks'
import ListIcon from '@material-ui/icons/List'
import { DeleteButton, EditButton } from 'components/Button/IconButtons'
import BackDropLoading from 'components/utils/BackDropLoading'
import { useTranslation } from 'react-i18next'

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(4),
    color: theme.palette.grey[800],
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) as typeof Chip

const useSpaceHeaderStyles = makeStyles(() => ({
  name: { fontSize: '1rem' },
}))

interface Props {
  spaceId: number
}

const Header = ({ spaceId }: Props) => {
  const id = spaceId
  const classes = useSpaceHeaderStyles()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation('space')
  const { data, loading } = useSpaceQuery({ variables: { id } })

  return loading ? (
    <BackDropLoading />
  ) : (
    <PageHeader
      aside={
        <>
          <EditButton onClick={() => history.push(`${url}/edit`)} />
          <DeleteButton />
        </>
      }
    >
      <Breadcrumbs>
        <StyledBreadcrumb
          component="a"
          label={t('space:name_other_capital')}
          onClick={() => history.push('/spaces')}
          icon={<ListIcon fontSize="medium" />}
        />
        <Chip className={classes.name} size="medium" label={data?.space?.name ?? null} />
      </Breadcrumbs>
    </PageHeader>
  )
}

export default Header
