import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { Paper, CircularProgress, IconButton } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
export const DEFAULT_IMAGE_WIDTH = '230px'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: (props: Props) => props.height ?? '230px',
    width: (props: Props) => props.width ?? '230px',
    marginBottom: '15px',
    position: 'relative',
    borderRadius: (props: Props) => (props.circular ? '99999px' : '3px'),
  },

  red: {
    backgroundColor: 'red',
  },

  logoSpinner: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: '3px',
    overflow: 'hidden',
  },

  deleteBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
}))

interface Props {
  src: string
  alt: string

  isLoading?: boolean
  imageClassName?: string
  wrapperClassName?: string
  onDelete?: () => void
  elevation?: number
  height?: string
  width?: string
  circular?: boolean
}

export const ImageDisplay = (props: Props) => {
  const classes = useStyles(props)
  const imageClassName = `${classes.image} ${props.imageClassName ?? ''}`
  const wrapperClassName = `${classes.wrapper} ${props.wrapperClassName ?? ''}`
  return (
    <Paper className={wrapperClassName} elevation={props.elevation ?? 3}>
      {props.isLoading ? (
        <div className={classes.logoSpinner}>
          <CircularProgress />
        </div>
      ) : null}
      {props.src ? <img className={imageClassName} alt={props.alt} src={props.src} /> : null}
      {props.onDelete ? (
        <IconButton
          onClick={props.onDelete}
          color="secondary"
          aria-label="delete picture"
          component="span"
          className={classes.deleteBtn}
        >
          <DeleteForeverIcon />
        </IconButton>
      ) : null}
    </Paper>
  )
}
