import { makeStyles } from '@material-ui/core/styles'

export const useSpaceFormStyles = makeStyles(() => ({
  paper: {
    marginTop: '1rem',
    padding: '1.5rem 2rem',
    margin: '0 auto',
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '1.2rem',
    },

    '& .MuiDialogActions-root': {
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '1.2rem',
    },

    '& .MuiDialogTitle-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '1rem',
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1.5rem 0 0.5rem',
  },

  inputField: { marginBottom: '1.5rem' },
  inputFieldXs: { marginBottom: '0.75rem' },
}))
