import { GEOLOCATION_API, GEOLOCATION_API_KEY } from 'config'
import { useCallback, useState } from 'react'

const API_KEY = GEOLOCATION_API_KEY
const API_URL = GEOLOCATION_API

// ------------------ FETCH FUNCTION -----------------
export const getLocationByText = async (
    text: string
): Promise<[]> => {
  try {
    // const filterString = options ? parseFilterString(options.filter) : ''
    const searchString = text.replaceAll(' ', '+');
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${searchString}&key=AIzaSyCEORqxP_IUN8E8-WtRrkT6Jq17ZjdYUGU`
    const response = await fetch(url)
    const result = await response.json()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result.results;
  } catch (e: unknown) {
    if (e instanceof Error) throw e
    return []
  }
}

// ----------------- HOOK -----------------------
export const useAddressAutoComplete = () => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    data: [],
  })

  const autoCompleteAddress = useCallback(
      async (text: string) => {
        setState({ loading: true, error: null, data: [] })
        try {
          const features = await getLocationByText(text)
          setState({ loading: false, error: null, data: features })
        } catch (e: unknown) {
          if (e instanceof Error) {
            setState({ loading: false, error: null, data: [] })
          }
        }
      },
      []
  )

  return [autoCompleteAddress, state] as const
}
