import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import ImageUploadWithCrop from 'components/Image/ImageUploadWithCrop'
import useImageUpload from 'utils/hooks/useImageUpload'

import {useObjectUrl} from 'utils/hooks/useObjectUrl'
import {Box, Button, Container, DialogTitle, Grid, MenuItem, Paper, useMediaQuery, useTheme} from '@material-ui/core'
import {Select, TextField} from 'components/Form/Atoms'
import {useTranslation} from 'react-i18next'
import AppButton from 'components/Button/AppButton'
import {useHistory} from 'react-router-dom'
import {
  CompanyMembershipStatus,
  CompanyStatus,
  usePostLazyQuery,
  useUpdatePostMutation,
  useUserLazyQuery, LocationInput
} from 'generated/types-and-hooks'
import {useSnackbar} from 'notistack'
import {useGlobalLoading} from 'context/LoadingContext'
import {SpaceSelector} from 'components/Form/Atoms/Selectors/SpaceSelector'
import {FormValues} from './model'
import {useStyles} from './styles'
import ColorSelector from 'components/Form/Atoms/Selectors/ColorSelector'
import { LocationSelector } from "components/Form/Atoms/LocationSelector";

type EditProps = { mode: 'edit'; postId: number }
type CreateProps = { mode: 'create' }
type Props = EditProps | CreateProps

const CreateOrEditPost = (props: Props) => {
  const formMode = props.mode
  const postId = props.mode === 'edit' ? props.postId : null
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'company', 'space'])
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [statusIsRequested, setStatusIsRequested] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  // Image Handling
  const [selectedImage, setSelectedImage] = useState<File>()
  const selectedImageSrc = useObjectUrl(selectedImage)
  const [
    startUploadingImage,
    { error: uploadError, isLoading: isUploading, assetId: logoId, fileLocation: uploadedImgSrc },
  ] = useImageUpload()
  // Form
  const defaultValues: FormValues = {
    name: '',
    address: '',
    bio: '',
    phone: '',
    email: '',
    lat: 0,
    long: 0
  }
  const {
    control,
    reset,
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const [formValues, setFormValues] = useState<FormValues>(defaultValues)
  const [emailChecked, setEmailChecked] = useState(false)

  // Data handling
  const [getPost, { data: postData, loading }] = usePostLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [getUser, { data: userData, loading: userLoading }] = useUserLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [updatePost, { data: updatedPost, loading: updateLoading }] =
    useUpdatePostMutation()

  useGlobalLoading(loading || updateLoading)

  // Fetch company data when editing
  useEffect(() => {
    if (props.mode === 'create' || !postId) return
    getPost({ variables: { where: { id: postId } } })
  }, [props.mode, postId])

  // Set the form values based on fetched company data
  useEffect(() => {
    if (!postData?.post) return
    reset({
      name: postData.post.name ?? '',
      username: postData.post.username ?? '',
      bio: postData.post.bio ?? '',
      phone: postData.post.phone ?? '',
      email: postData.post.email ?? '',
      address: postData.post.address ?? '',
    })
  }, [postData])

  const onSubmit = (values: FormValues) => {
    setSubmitted(true)
    const locIn: LocationInput = {
      lat: parseFloat(Object(values.address).geometry.location.lat) ? parseFloat(Object(values.address).geometry.location.lat) : postData?.post?.location?.lat,
      long: parseFloat(Object(values.address).geometry.location.lng) ? parseFloat(Object(values.address).geometry.location.lng) : postData?.post?.location?.long
    };
    if (selectedImage) startUploadingImage(selectedImage)
    else if (formMode === 'create') {
      // const result = requestCompany({
      //   variables: {
      //     input: {
      //       name: values?.name || '',
      //       description: values.description || '',
      //       endpoint: values?.endpoint || '',
      //       primaryColor: values.primaryColor || '',
      //       visibility: values.visibility,
      //       status: values.status ?? CompanyStatus.Inactive,
      //       address: Object(values.address).formatted_address,
      //       location: {
      //         ...locIn
      //       },
      //       secondaryColor: values.secondaryColor,
      //       space: { id: Number(values.spaceId) },
      //     },
      //   },
      // })
      // result.catch((e) => {
      //   console.error(e)
      //   enqueueSnackbar(e.message, { variant: 'error' })
      //   enqueueSnackbar(t('common:status.unSuccessRequest', { entity: t('company:name') }), {
      //     variant: 'error',
      //   })
      //   setSubmitted(false)
      // })
    } else if (formMode === 'edit' && postId) {
      const result = updatePost({
        variables: {
          where: { id: postId },
          input: {
            name: values.name,
            username: values.username,
            bio: values.bio,
            phone: values.phone,
            email: values.email,
            address: Object(values.address).formatted_address,
            location: {
              ...locIn
            }
          },
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(e.message, { variant: 'error' })
        enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('company:name') }), {
          variant: 'error',
        })
        setSubmitted(false)
      })
    }
  }

  useEffect(() => {
    if (!userData) return
    if (emailChecked && !userLoading && !userData?.user?.id) {
      enqueueSnackbar(t('company:form.noUserFound'), { variant: 'error' })
      setEmailChecked(false)
      return
    }
    // const result = companyToDraft({
    //   variables: {
    //     where: { id: companyId },
    //     input: {
    //       name: formValues.name,
    //       description: formValues.description,
    //       endpoint: formValues.endpoint || '',
    //       visibility: formValues.visibility,
    //       status: formValues.status ?? CompanyStatus.Inactive,
    //       primaryColor: formValues.primaryColor,
    //       secondaryColor: formValues.secondaryColor,
    //       space: { id: Number(formValues.spaceId) },
    //       superadminID: Number(userData.user?.id),
    //     },
    //   },
    // })
    // result.catch((e) => {
    //   console.error(e)
    //   enqueueSnackbar(e.message, { variant: 'error' })
    //   enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('company:name') }), {
    //     variant: 'error',
    //   })
    //   setSubmitted(false)
    // })
  }, [userData])

  // Effect after an image is uploaded
  useEffect(() => {
    if (!uploadedImgSrc) return
    // if (formMode === 'create')
    // requestCompany({
    //   variables: {
    //     where: { id: companyId },
    //     input: { ...getValues(), logo: { id: logoId } },
    //   },
    // })
    updatePost({
      variables: {
        where: { id: postId },
        input: { ...getValues()},
      },
    })
  }, [uploadedImgSrc])

  // useEffect(() => {
  //   if (!requestedCompany) return
  //   history.goBack()
  //   enqueueSnackbar(t('common:status.successRequest', { entity: t('company:name') }), {
  //     variant: 'success',
  //   })
  // }, [requestedCompany])

  useEffect(() => {
    if (!updatedPost) return
    history.goBack()
    enqueueSnackbar(t('common:status.successUpdate', { entity: t('company:name') }), {
      variant: 'success',
    })
  }, [updatedPost])

  // let uploadErrorMessage = uploadError
  // if (errors?.logo && 'id' in errors.logo) {
  //   const msg = errors?.logo?.id?.message
  //   if (msg) uploadErrorMessage = msg
  // }

  return (
    <>
      {loading ? null : (
        <Container maxWidth={matches ? 'md' : 'sm'} className={classes.root}>
          <Paper elevation={2} className={classes.paper}>
            <DialogTitle>
              Edit post
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.formBody}>
              <ImageUploadWithCrop
                image={{
                  src: (selectedImageSrc || postData?.post?.avatar?.fileLocation) ?? '',
                  alt: 'post-logo',
                  height: '230px',
                  width: '230px',
                }}
                isUploading={isUploading}
                buttonLabel="Upload"
                uploadError={''}
                onCroppedImage={(file: File) => setSelectedImage(file)}
              />

              <Grid container spacing={3} className={classes.formBody__fieldsArea}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="name"
                    name="name"
                    label={t('common:table.name')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="bio"
                    name="bio"
                    label="Bio"
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="username"
                    name="username"
                    label="Username"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="phone"
                      name="phone"
                      label="Phone"
                      required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="email"
                      name="email"
                      label="Email"
                      required
                  />
                </Grid>
                <Grid xs={12} item>
                  <LocationSelector
                      control={control}
                      name="address"
                      label={'Address'}
                      defaultValue={postData?.post?.address ?? defaultValues.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button
                      color="secondary"
                      onClick={() => history.goBack()}
                      className={classes.button}
                    >
                      {t('common:action.cancel')}
                    </Button>
                    <AppButton
                      color="primary"
                      className={classes.button}
                      variant="contained"
                      type="submit"
                      disabled={submitted}
                      loading={emailChecked || updateLoading}
                    >
                      {t('common:action.save')}
                    </AppButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      )}
    </>
  )
}

export default CreateOrEditPost
