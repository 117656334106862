import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'

import { ImageDisplay, DEFAULT_IMAGE_WIDTH } from 'components/Image/ImageDisplay'
import { FileSelectorBtn } from 'components/Form/Atoms'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: (props: Props) => props.imageWidth ?? DEFAULT_IMAGE_WIDTH,
  },
  changeLogoButton: {
    width: '100%',
  },
}))

interface Props {
  isLoading: boolean
  error: string | Error
  imageSrc: string
  imageAlt: string
  imageWidth?: string
  imageHeight?: string
  onImageSelect: (file: File) => void
  buttonLabel: string
  circular?: boolean,
  disabled?: boolean
}

export default function SingleImageUpload(props: Props) {
  const classes = useStyles(props)

  return (
    <div style={{ maxWidth: props.imageWidth ?? 'inherit' }}>
      <ImageDisplay
        height={props.imageHeight}
        width={props.imageWidth}
        src={props.imageSrc}
        alt={props.imageAlt}
        isLoading={props.isLoading}
        circular={props.circular}
      />
      {!props.disabled&&<FileSelectorBtn
        color="primary"
        variant="contained"
        onFileSelect={(files) => props.onImageSelect(files[0])}
        inputProps={{
          accept: 'image/*',
        }}
        className={classes.changeLogoButton}
      >
        {props.buttonLabel}
      </FileSelectorBtn>}

      {props.error ? (
        <Box mt={1}>
          <Alert severity="error">
            {typeof props.error === 'string' ? props.error : null}
            {props.error instanceof Error ? props.error.message : null}
          </Alert>
        </Box>
      ) : null}
    </div>
  )
}
