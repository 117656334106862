import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

import {
  Drawer,
  DrawerProps,
  useTheme,
  makeStyles,
  createStyles,
  Divider,
  Theme,
} from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { AppMode } from '..'
import MenuItems from './MenuItems'

export const SIDEBAR_WIDTH = 240
export const SIDEBAR_BREAKPOINT: Breakpoint = 'sm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: SIDEBAR_WIDTH,
      flexShrink: 0,
    },
    drawerPaper: {
      width: SIDEBAR_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  })
)

interface Props {
  isHidden?: boolean
  toggle?: () => void
  mode: AppMode
}

const SideBar = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const open = !props.isHidden
  const toggleOpen = props.toggle

  const getBaseDrawerProps = (): DrawerProps => {
    if (props.mode === 'mobile')
      return {
        anchor: 'left',
      }
    if (props.mode === 'desktop')
      return {
        variant: 'persistent',
        anchor: 'left',
      }
    return {}
  }

  return (
    <Drawer
      className={classes.drawer}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      {...getBaseDrawerProps()}
    >
      {toggleOpen ? (
        <>
          <div className={classes.drawerHeader}>
            <IconButton onClick={toggleOpen}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
        </>
      ) : null}
      <MenuItems toggleSidebar={props.mode === 'mobile' ? toggleOpen : undefined} />
    </Drawer>
  )
}

export default SideBar
