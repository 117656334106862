import { Switch, Route } from 'react-router-dom'
import PageLayout from 'components/PageLayout'
import { ErrorBoundary } from 'react-error-boundary'
import FallbackComponent from 'components/utils/ErrorBoundaryFallback'
import { Suspense } from 'react'
import Users from './Users'
import Companies from './Companies'
import Posts from './Posts'
import Spaces from './Spaces'
import Statistics from './Statistics'
import Settings from './Settings'

const Authenticated = () => {
  return (
    <PageLayout>
      <Suspense fallback={null}>
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <Switch>
            <Route exact path="/">
              <Users />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/companies">
              <Companies />
            </Route>
            <Route path="/posts">
              <Posts />
            </Route>
            <Route path="/spaces">
              <Spaces />
            </Route>
            <Route path="/statistics">
              <Statistics />
            </Route>
            <Route path="/settings">
              <Settings/>
            </Route>
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </PageLayout>
  )
}

export default Authenticated
