import FullPageLoading from 'components/utils/FullPageLoading'
import { useAuth, useLogin } from 'context/AuthContext'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLogger } from 'utils/hooks/useLogger'
import Authenticated from './Authenticated'
import Unauthenticated from './Unauthenticated'

const ScreensRoot = () => {
  const { user, initialLoading } = useAuth()

  if (initialLoading) return <FullPageLoading />
  return (
    <>
      <Switch>
        <SanitizedRoute>
          {user && user.isAdmin ? <Authenticated /> : <Unauthenticated />}
        </SanitizedRoute>
      </Switch>
    </>
  )
}

const SanitizedRoute: React.FC = ({ children }) => {
  return (
    <Route
      path="*"
      render={(props) => {
        const url = props.match.url
        const regex = /\/\/+/g
        if (url.match(regex)) {
          const cleanUrl = url.replace(regex, '/')
          return <Redirect to={cleanUrl} />
        } else {
          return children
        }
      }}
    />
  )
}

export default ScreensRoot
