import { ReactElement } from 'react'
import Routes from 'pages'
import { FILE_HANDLER_API, GQL_API } from 'config'

function throwMissingEnvError(type: string) {
  throw new Error(`Missing ${type}. Please provide one as an env variable`)
}

function App(): ReactElement {
  if (!GQL_API) throwMissingEnvError('graphql api url')
  if (!FILE_HANDLER_API) throwMissingEnvError('file handler api url')

  return <Routes />
}

export default App
