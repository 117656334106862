import { useMemo } from 'react'
import { AllCompanyMembersTableData, tableStyles } from '../model_styles'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import { MUIDataTableOptions, SelectableRows } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import {
  Company,
  CompanyMembershipStatus,
  useCompanyMembersQuery,
  UserRole,
} from 'generated/types-and-hooks'
import TableText from '../TableText'

const allCompanyMembers: number[] = []
const membersWithMatchingStatus: Array<number[]> = [[], [], [], [], []]

function mapCompaniesToMembersData(c: Company) {
  function allMembersCounter() {
    c.memberships
      ?.filter((e) => e?.user?.role !== UserRole.Admin && e?.user?.role !== UserRole.SuperAdmin)
      .map((e) => e?.user?.id)
      .forEach((e) => {
        if (e && !allCompanyMembers.includes(e)) allCompanyMembers.push(e)
      })
    return allCompanyMembers.length
  }

  function memberCounter(status: CompanyMembershipStatus, index: number) {
    c.memberships
      ?.filter((e) => e?.status === status)
      .map((e) => e?.user?.id)
      .forEach((e) => {
        if (e && !membersWithMatchingStatus[index].includes(e))
          membersWithMatchingStatus[index].push(e)
      })
    return membersWithMatchingStatus[index].length
  }

  const allMembers: AllCompanyMembersTableData = {
    allCount: allMembersCounter(),
    activeCount: memberCounter(CompanyMembershipStatus.Active, 0),
    inactiveCount: memberCounter(CompanyMembershipStatus.Inactive, 1),
    passiveCount: memberCounter(CompanyMembershipStatus.Passive, 2),
    requestedCount: memberCounter(CompanyMembershipStatus.Requested, 3),
    bannedCount: memberCounter(CompanyMembershipStatus.Banned, 4),
  }
  return allMembers
}

const AllCompanyMembers = () => {
  const { t } = useTranslation(['company', 'stat', 'user'])
  const classes = tableStyles()
  const { data, loading } = useCompanyMembersQuery()

  const allCompanyMembersData: AllCompanyMembersTableData[] = useMemo(() => {
    if (!data?.companiesConnection?.companies) return []

    const companiesArray = data.companiesConnection.companies as NonNullable<Company>[]
    const mappedCompanies = companiesArray.map((c) => {
      return mapCompaniesToMembersData(c)
    })
    return [mappedCompanies[0]]
  }, [data])

  const options: MUIDataTableOptions = {
    viewColumns: false,
    download: false,
    print: false,
    search: false,
    pagination: false,
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
  }

  const columns: DataTableColumn[] = [
    {
      name: 'companyId',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'allCount',
      label: t('stat:label.total'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersWoHaveMembership', { number: value })
                : t('stat:info.userWoHasMembership', { number: value })
            }`}
          />
        ),
      },
    },
    {
      name: 'activeCount',
      label: t('user:enum.statusAtCompany.ACTIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersHaveMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.ACTIVE'),
                  })
                : t('stat:info.userHasMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.ACTIVE'),
                  })
            }`}
          />
        ),
      },
    },
    {
      name: 'inactiveCount',
      label: t('user:enum.statusAtCompany.INACTIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersHaveMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.INACTIVE'),
                  })
                : t('stat:info.userHasMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.INACTIVE'),
                  })
            }`}
          />
        ),
      },
    },
    {
      name: 'passiveCount',
      label: t('user:enum.statusAtCompany.PASSIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersHaveMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.PASSIVE'),
                  })
                : t('stat:info.userHasMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.PASSIVE'),
                  })
            }`}
          />
        ),
      },
    },
    {
      name: 'requestedCount',
      label: t('user:enum.statusAtCompany.REQUESTED'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersHaveMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.REQUESTED'),
                  })
                : t('stat:info.userHasMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.REQUESTED'),
                  })
            }`}
          />
        ),
      },
    },
    {
      name: 'bannedCount',
      label: t('user:enum.statusAtCompany.BANNED'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersHaveMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.BANNED'),
                  })
                : t('stat:info.userHasMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.BANNED'),
                  })
            }`}
          />
        ),
      },
    },
    {
      name: 'bannedCount',
      label: 'Invited',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableText
            text={value}
            info={`${
              value > 1
                ? t('stat:info.usersHaveMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.BANNED'),
                  })
                : t('stat:info.userHasMembership', {
                    number: value,
                    status: t('user:enum.statusAtCompany.BANNED'),
                  })
            }`}
          />
        ),
      },
    },
  ]

  return (
    <div className={classes.table}>
      <DataTable
        isLoading={loading || !allCompanyMembersData}
        title={t('stat:title.allCompanyMembers')}
        columns={columns}
        data={allCompanyMembersData}
        options={options}
      />
    </div>
  )
}

export default AllCompanyMembers
