import { useEffect, useMemo, useState } from 'react'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import BackDropLoading from 'components/utils/BackDropLoading'
import { Company, usePrivacyPolicyQuery } from 'generated/types-and-hooks'
import { useDocumentStyles } from '../styles'
import { useTranslation } from 'react-i18next'

interface Props {
  companyId: number
}

const LANGUAGE = navigator.language.split('-')[0]

const PrivacyPolicy = ({ companyId }: Props) => {
  const { t } = useTranslation(['company', 'common'])
  const classes = useDocumentStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const [converted, setConverted] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const { data, loading } = usePrivacyPolicyQuery({ variables: { id: companyId } })

  const company: NonNullable<Company> = useMemo<Company>(() => {
    if (data?.company && !loading) {
      const companyData = data.company as NonNullable<Company>
      return companyData
    }
    return {} as NonNullable<Company>
  }, [data])

  useEffect(() => {
    if (company?.privacyPolicy) {
      if (company.privacyPolicy.find((priv) => priv?.language === LANGUAGE)?.content) {
        setPrivacyPolicy(
          company.privacyPolicy.find((priv) => priv?.language === LANGUAGE)?.content ?? ''
        )
      } else {
        setPrivacyPolicy(
          company.privacyPolicy.find((priv) => priv?.default === true)?.content ?? ''
        )
      }
    }
    setConverted(true)
  }, [company.privacyPolicy])

  if (loading || !converted) {
    return <BackDropLoading />
  } else {
    return (
      <Container className={matches ? classes.rootXs : classes.root}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paperTerms}>
              {privacyPolicy ? (
                <>
                  <Typography className={classes.text} color="textPrimary">
                    {t('company:documents.privacyPolicy')}
                  </Typography>
                  <Divider />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: privacyPolicy,
                    }}
                    color="textSecondary"
                  ></Typography>
                </>
              ) : (
                <Typography align="center">{t('common:info.noContent')}</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default PrivacyPolicy
