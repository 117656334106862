import { CreateUserInput, useSignupMutation } from 'generated/types-and-hooks'
import { useCallback } from 'react'
import { useAuth } from '../AuthContext'

export const useSignup = () => {
  const { _setAuthToken } = useAuth()
  const [startSignup, signupState] = useSignupMutation()

  const signup = useCallback(
    async (inputs: CreateUserInput) => {
      try {
        const registerData = await startSignup({ variables: { input: { ...inputs } } })
        const authToken = registerData.data?.registerUser?.authorization
        if (authToken) {
          _setAuthToken?.(authToken, { persist: false })
        }
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        if (e instanceof Error) console.log(e.message)
      }
    },
    [_setAuthToken, startSignup]
  )

  return [
    signup,
    {
      loading: signupState.loading,
      error: signupState.error,
      data: signupState.data,
    },
  ] as const
}
