import MUIAutocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import { UseControllerProps, Controller, FieldValues } from 'react-hook-form'

type Props<
  T,
  FieldValueTypes,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & UseControllerProps<FieldValueTypes>

export function Autocomplete<
  T,
  FieldValueTypes extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  control,
  name,
  rules,
  defaultValue,
  ...autocompleteProps
}: Props<T, FieldValueTypes, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Controller
      render={({ field }) => (
        <MUIAutocomplete
          {...field}
          {...autocompleteProps}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      control={control}
    />
  )
}

export default Autocomplete
