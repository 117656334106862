import { TextField } from '@material-ui/core'
import Autocomplete from 'components/Form/Atoms/Autocomplete'
import BackDropLoading from 'components/utils/BackDropLoading'
import { FieldValues, UseControllerProps } from 'react-hook-form'
import { useMemo } from 'react'
import { AutocompleteProps } from '@material-ui/lab'
import { useSpacesQuery } from 'generated/types-and-hooks'

type Props<
  T,
  FieldValueTypes,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> &
  UseControllerProps<FieldValueTypes> & {
    label: string
    disabled?: boolean
    required?: boolean
  }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SpaceSelector = <
  T,
  FieldValueTypes extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  control,
  name,
  label,
  id,
  disabled,
  required,
  rules,
  defaultValue,
  multiple,
  className,
}: Props<T, FieldValueTypes, Multiple, DisableClearable, FreeSolo>) => {
  const textFieldProps = {
    label,
    id: id ?? null,
    disabled: !!disabled,
    required: !!required,
  }
  const { data, loading } = useSpacesQuery()

  const optionsArray = useMemo(() => {
    if (!data?.spaces) return
    return data.spaces.map((space) => space?.id)
  }, [data])

  const getSpaceById = (spaceId: number) =>
    data?.spaces?.find((space) => space?.id === spaceId)?.name ?? null

  if (loading) {
    return <BackDropLoading />
  } else {
    return (
      <Autocomplete
        name={name}
        control={control}
        rules={rules}
        className={className}
        multiple={multiple ?? undefined}
        options={optionsArray ? optionsArray : []}
        getOptionLabel={(spaceId) => {
          const spaceName = getSpaceById(spaceId ?? 0)
          if (!spaceName) return ''
          return spaceName
        }}
        defaultValue={defaultValue}
        filterSelectedOptions
        renderInput={(param) => <TextField variant="outlined" {...textFieldProps} {...param} />}
      />
    )
  }
}
