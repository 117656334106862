import { LanguageSelector, TextField } from 'components/Form/Atoms'
import { Control, UseFormTrigger } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StyledHeader } from './Styles'
import { SignupFormValues } from './types'
import { checkIfUserRegistered } from 'utils/actions/checkIfUserRegistered'
import { debounce } from 'lodash'

export type Props = {
  control: Control<SignupFormValues>
  trigger: UseFormTrigger<SignupFormValues>
  isValidating: boolean
}

const BaseDataFields = (props: Props) => {
  const { t } = useTranslation(['common', 'signup', 'user'])
  const control = props.control
  return (
    <>
      <Grid item xs={12}>
        <StyledHeader variant="h6">{t('signup:form.basicInfo')}</StyledHeader>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          control={control}
          label={t('user:property.firstName')}
          name="firstName"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          control={control}
          label={t('user:property.lastName')}
          name="lastName"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="email"
          required
          rules={{
            validate: async (email) => {
              if (props.isValidating) return true
              if (email && typeof email === 'string') {
                try {
                  const isUserRegistered = await checkIfUserRegistered({ email }, t)
                  return !isUserRegistered || 'Email address is already in use'
                } catch (e) {
                  console.log(e)
                }
              }
            },
          }}
          control={control}
          label={t('common:table.email')}
          name="email"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          control={control}
          rules={{
            validate: async (username) => {
              try {
                if (props.isValidating) return true
                if (username && typeof username === 'string') {
                  const isUserRegistered = await checkIfUserRegistered({ username }, t)
                  return !isUserRegistered || 'Username is already taken'
                }
              } catch (e) {
                console.log(e)
              }
            },
          }}
          required
          label={t('user:property.userName')}
          name="username"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LanguageSelector
          multiple
          name="languages"
          label={t('user:property.languages')}
          control={props.control}
        />
      </Grid>
    </>
  )
}

export default BaseDataFields
