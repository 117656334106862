import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Paper,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  DialogTitle,
} from '@material-ui/core'
import { TextField } from 'components/Form/Atoms'
import { useSpaceFormStyles } from './styles'
import { useTranslation } from 'react-i18next'
import AppButton from 'components/Button/AppButton'
import { useHistory } from 'react-router-dom'
import {
  useCreateSpaceMutation,
  useSpaceLazyQuery,
  useUpdateSpaceMutation,
} from 'generated/types-and-hooks'
import ColorSelector from 'components/Form/Atoms/Selectors/ColorSelector'
import { FormValues } from './model'
import { useSnackbar } from 'notistack'
import { useSpaceFormValidation } from './validation'
import { useGlobalLoading } from 'context/LoadingContext'
import IconSelector from 'components/Form/Atoms/Selectors/IconSelector/IconSelector'

type EditProps = { mode: 'edit'; spaceId: number }
type CreateProps = { mode: 'create' }
type Props = EditProps | CreateProps

const CreateOrEditSpace = (props: Props) => {
  const formMode = props.mode
  const spaceId = 'spaceId' in props ? props.spaceId : null
  const classes = useSpaceFormStyles()
  const { t } = useTranslation(['common', 'space'])
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [submitted, setSubmitted] = useState(false)
  const defaultValues: FormValues = {
    name: '',
    description: '',
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
    icon: '',
  }
  const { control, reset, handleSubmit, register, setValue, watch } = useForm({
    defaultValues,
  })
  const [getSpace, { data: spaceData, loading }] = useSpaceLazyQuery()
  const [createSpace, { data: createdSpace, loading: createLoading }] = useCreateSpaceMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          spaces(existing, { toReference }) {
            if (data?.createSpace) return [...existing, toReference(data.createSpace)]
          },
        },
      })
    },
  })
  const [updateSpace, { loading: updateLoading }] = useUpdateSpaceMutation()
  useGlobalLoading(loading || createLoading || updateLoading)
  useSpaceFormValidation(register)

  useEffect(() => {
    if (!spaceId) return
    getSpace({ variables: { id: spaceId } })
  }, [spaceId])

  useEffect(() => {
    if (!spaceData?.space) return
    reset({
      name: spaceData.space.name ?? '',
      description: spaceData.space.description ?? '',
      primaryColor: spaceData.space.primaryColor ? spaceData.space.primaryColor : '#FFFFFF',
      secondaryColor: spaceData.space.secondaryColor ? spaceData.space.secondaryColor : '#FFFFFF',
      icon: spaceData.space.icon ?? '',
    })
  }, [spaceData])

  const onSubmit = (values: FormValues) => {
    setSubmitted(true)
    if (formMode === 'create') {
      const result = createSpace({
        variables: {
          input: {
            name: values.name || '',
            description: values.description,
            primaryColor: values.primaryColor || '',
            secondaryColor: values.secondaryColor,
            icon: values.icon,
          },
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(e.message, { variant: 'error' })
        enqueueSnackbar(t('common:status.unSuccessCreate', { entity: t('space:name') }), {
          variant: 'error',
        })
        setSubmitted(false)
      })
    }
    if (formMode === 'edit' && spaceId) {
      const result = updateSpace({
        variables: {
          where: { id: spaceId },
          input: {
            name: values.name,
            description: values.description,
            primaryColor: values.primaryColor,
            secondaryColor: values.secondaryColor,
            icon: values.icon,
          },
        },
      })
      result
        .then(() => {
          history.goBack()
          enqueueSnackbar(t('common:status.successUpdate', { entity: t('space:name') }), {
            variant: 'success',
          })
        })
        .catch((e) => {
          console.error(e)
          enqueueSnackbar(e.message, { variant: 'error' })
          enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('space:name') }), {
            variant: 'error',
          })
          setSubmitted(false)
        })
    }
  }

  useEffect(() => {
    if (!createdSpace) return
    history.goBack()
    enqueueSnackbar(t('common:status.successCreate', { entity: t('space:name') }), {
      variant: 'success',
    })
  }, [createdSpace])

  return (
    <>
      {loading ? null : (
        <Container maxWidth="sm">
          <Paper elevation={2} className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle className={classes.dialogTitle}>
                {formMode === 'edit' ? t('space:form.editSpace') : t('space:form.createSpace')}
              </DialogTitle>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="name"
                    name="name"
                    label={t('common:table.name')}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <IconSelector
                    className={classes.inputField}
                    control={control}
                    name="icon"
                    label="Icon"
                    popupMarginTop={formMode === 'create' ? '5rem' : '13rem'}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="description"
                    name="description"
                    label={t('common:table.description')}
                    multiline
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <ColorSelector
                    className={matches ? classes.inputField : classes.inputFieldXs}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    name="primaryColor"
                    label={t('common:table.primaryColor')}
                    colorByTyping
                    required
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <ColorSelector
                    className={matches ? classes.inputField : classes.inputFieldXs}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    name="secondaryColor"
                    label={t('common:table.secondaryColor')}
                    colorByTyping
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.actionButtons}>
                <Button
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => history.goBack()}
                >
                  {t('common:action.cancel')}
                </Button>
                <AppButton
                  type="submit"
                  disabled={submitted}
                  loading={createLoading || updateLoading}
                  style={{ color: theme.palette.primary.main }}
                >
                  {t('common:action.save')}
                </AppButton>
              </Grid>
            </form>
          </Paper>
        </Container>
      )}
    </>
  )
}

export default CreateOrEditSpace
