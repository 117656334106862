import Header from 'components/PageLayout/Header/Header'
import SideBar from 'components/PageLayout/SideBar/SideBar'
import Body from 'components/PageLayout/Body/Body'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import React, { useState, useEffect } from 'react'

export type AppMode = 'mobile' | 'desktop'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
})

interface SidebarState {
  isHidden: boolean
  mode: AppMode
}
const defaultState: SidebarState = {
  isHidden: false,
  mode: 'desktop',
}

const PageLayout: React.FC = (props) => {
  const [sidebarState, setSidebarState] = useState<SidebarState>(defaultState)
  const theme = useTheme()
  const fixedSidebarBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (fixedSidebarBreakpoint) {
      setSidebarState({ isHidden: false, mode: 'desktop' })
    }
    if (!fixedSidebarBreakpoint) {
      setSidebarState({ isHidden: true, mode: 'mobile' })
    }
  }, [fixedSidebarBreakpoint])

  const toggleSidebar = () =>
    setSidebarState((prevState) => ({
      ...prevState,
      isHidden: !prevState.isHidden,
    }))

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Header mode={sidebarState.mode} toggleSidebar={toggleSidebar} />
      <SideBar mode={sidebarState.mode} isHidden={sidebarState.isHidden} toggle={toggleSidebar} />
      <Body>{props.children}</Body>
    </div>
  )
}

export default PageLayout
