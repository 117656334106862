import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useIconSelectorStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    flexSection: {
      width: '33%',
      margin: '0.3rem 0',
      minWidth: '145px',
      textAlign: 'center',
    },
    labelSection: {
      width: '33%',
      minWidth: '145px',
      paddingLeft: '14px',
      textAlign: 'left',
    },
    btnMain: {
      width: 'calc(100% - 1rem)',
      maxWwidth: '9rem',
    },
    stickyContainer: {
      width: '100%',
      margin: '2rem 1rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'sticky',
      top: '1rem',
    },
    searchBox: {
      width: '60%',
      marginLeft: '20%',
      background: 'rgba(255, 255, 255, 0.95)',
    },
    btnClose: {
      margin: '0.25rem 0',
      background: 'rgba(255, 255, 255, 0.95)',
    },
    results: {
      width: '100%',
      margin: '1rem 0',
      display: 'block',
      textAlign: 'center',
    },
    popover: {
      '& .MuiPopover-paper': {
        maxWidth: '90%',
      },
    },
    popoverUpMd: {
      '& .MuiPopover-paper': {
        maxWidth: '60%',
      },
    },
    iconContainer: {
      minHeight: '8rem',
      maxHeight: '24rem',
      position: 'relative',
      overflowY: 'scroll',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    iconContainerUpSm: {
      minHeight: '8rem',
      maxHeight: '31rem',
      position: 'relative',
      overflowY: 'scroll',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    iconCard: {
      width: '5.375rem',
      height: '6.375rem',
      margin: '0.25rem 0 0.75rem',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '& .MuiTypography-body1': {
        fontSize: '0.8rem',
      },
    },
    icon: {
      padding: '1rem',
      boxSizing: 'content-box',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    flexContainerInner: {
      margin: '0.75rem 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    flexSectionInner: {
      width: '33%',
      minWidth: '230px',
      textAlign: 'center',
    },
    btnPrimary: {
      margin: '0.75rem 1.25rem 0.75rem 0',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    btnSecondary: {
      margin: '0.75rem 1.25rem 0.75rem 0',
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    error: {
      margin: '0 14px',
      fontSize: '0.75rem',
      color: '#f44336',
    },
  })
)
