import { useLoginUserMutation } from 'generated/types-and-hooks'
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../AuthContext'

const AuthError = new Error('You are not authorized to access the site')

export const useLogin = () => {
  const [startLogin, loginState] = useLoginUserMutation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const {
    _setAuthToken,
    _clearAuthToken,
    user,
    isLoading: loadingCurrentUser,
    error: currentUserError,
  } = useAuth()

  useEffect(() => {
    const newError: Error | undefined = loginState?.error

    if (newError !== error && error !== AuthError) {
      if (newError) setLoading(false)
      setError(newError)
    }
  }, [loginState])

  const login = useCallback(
    async (email: string, password: string, persist = false) => {
      try {
        setLoading(true)
        setError(undefined)

        const loginData = await startLogin({ variables: { email, password } })
        const authToken = loginData.data?.loginUser?.authorization

        if (authToken) {
          _setAuthToken?.(authToken, { persist })
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          setLoading(false)
          setError(e)
        }
      }
    },
    [_setAuthToken, startLogin]
  )

  useEffect(() => {
    if (!loadingCurrentUser) setLoading(false)
    if ((!user && !currentUserError) || loadingCurrentUser) return
    if (user && !user.isAdmin) {
      setError(AuthError)
      _clearAuthToken()
    }
  }, [user, loadingCurrentUser, currentUserError])

  return [
    login,
    {
      loading,
      error,
      data: loginState.data,
    },
  ] as const
}
