import { MUIDataTableOptions, SelectableRows } from 'mui-datatables'
import { useState, useMemo } from 'react'
import {
  User,
  UserRole,
  UserStatus,
  UserWhereManyInput,
  useUserListQuery,
} from 'generated/types-and-hooks'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DeleteButton, EditButton } from 'components/Button/IconButtons'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import { useDeleteDialog } from 'utils/hooks/useDeleteDialog'
import { usePagination } from 'utils/query/usePagination'
import { useTranslation } from 'react-i18next'
import { useSearch } from 'utils/query/useSearch'
import { getTableChangeHandler } from 'utils/query/table-utils'
import { Box, Button } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { SearchBox } from 'components/Form/SearchBox'
import { useFilter } from 'utils/query/useFilter'
import { UserData } from 'pages/Users/userList.model'
import { useGlobalLoading } from 'context/LoadingContext'
import DeleteUserDialog from 'modules/user/Delete/DeleteUser'
import { TFunction } from 'i18next'

const tableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .MuiTableCell-root': {
        borderBottom: '0px',
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  })
)

function mapUserToUserData(user: User, t: TFunction): UserData {
  const userData: UserData = {
    id: user.id ?? 0,
    username: user.username ?? '',
    fullName: user.fullName ?? '',
    email: user.email ?? '',
    role: t(`user:enum.role.${user.role}` ?? UserRole.User),
    status: t(`user:enum.status.${user.status}` ?? UserStatus.Inactive),
  }

  return userData
}

type UserRoleMap = {
  name: string
  key: string
}[]

export default function LeaderList() {
  const [count, setCount] = useState(0)
  const { currentPage, changePage, rowsPerPage, changeRowsPerPage, paginationParams } =
    usePagination()
  const { setQuery, clearQuery, searchQueryParams } = useSearch<UserWhereManyInput>([
    'firstName.contains',
    'lastName.contains',
    'email.contains',
  ])

  const { data: userListData, loading } = useUserListQuery({
    variables: {
      ...paginationParams,
      where: {
        OR: searchQueryParams,
        NOT: [{ role: UserRole.User }],
        //AND: filters,
      },
    },
  })
  const onTableChange = useMemo(
    () =>
      getTableChangeHandler({
        onChangePage: changePage,
        onChangeRowsPerPage: changeRowsPerPage,
        //onFilterChange: setFilters,
      }),
    [changePage, changeRowsPerPage]
  )
  useGlobalLoading(loading)
  const classes = tableStyles()

  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation(['common', 'stat', 'user'])
  const { openDialog, closeDialog, isOpen, selectedId } = useDeleteDialog()

  const userList = useMemo(() => {
    if (!userListData?.usersConnection?.users) return []

    setCount(userListData?.usersConnection?.count ?? 0)
    const usersArray = userListData.usersConnection.users as NonNullable<User>[]
    const mappedUsers = usersArray.map((u) => {
      return mapUserToUserData(u, t)
    })
    return mappedUsers
  }, [userListData])

  const options: MUIDataTableOptions = {
    viewColumns: false,
    download: false,
    print: false,
    filter: true,
    search: false,
    serverSide: true,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    page: currentPage,
    count,
    onTableChange,
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => history.push(`${url}/new`)}
          >
            {t('common:action.addLeader')}
          </Button>
        </>
      )
    },
  }

  const columns: DataTableColumn[] = [
    {
      name: 'id',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'username',
      label: t('user:property.userName'),
      options: { filter: false, sort: false },
    },
    {
      name: 'fullName',
      label: t('common:table.name'),
      options: { filter: false, sort: false },
    },
    {
      name: 'email',
      label: t('common:table.email'),
      options: { filter: false, sort: false },
    },
    {
      name: 'role',
      label: t('common:table.role'),
      options: { filter: false, sort: false },
    },
    {
      name: 'status',
      label: t('common:table.status'),
      options: { filter: false, sort: false },
    },
    {
      name: 'Edit',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <EditButton
            onClick={(e) => {
              e.stopPropagation()
              history.push(`${url}/${rowData[0]}/edit`)
            }}
          />
        ),
      },
    },
    {
      name: 'Delete',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation()
              openDialog(rowData[0] as number)
            }}
          />
        ),
      },
    },
  ]

  return (
    <div className={classes.table}>
      {selectedId && <DeleteUserDialog open={isOpen} onClose={closeDialog} userId={selectedId} />}
      <Box mb={2}>
        <SearchBox onSearch={setQuery} onClear={clearQuery} />
      </Box>
      <DataTable
        isLoading={loading}
        title={t('setting:leaders.name_other')}
        columns={columns}
        data={userList}
        options={{
          ...options,
          onRowClick: ([id]) => {
            history.push(`/users/${id}`)
          },
        }}
      />
    </div>
  )
}
