import { ApolloError } from '@apollo/client'
import { useCreateAssetMutation } from 'generated/types-and-hooks'
import { useCallback, useEffect, useState } from 'react'
import { uploadImage } from 'utils/actions/uploadImage'
import { Status } from './useFiles.utils'

type Idle = { status: Status.Idle }
type Pending = { status: Status.Pending }
type Rejected = { status: Status.Rejected; error: string | Error }
type Resolved = {
  status: Status.Resolved
  assetId: number
  fileLocation: string
}

type MainState = Idle | Pending | Rejected | Resolved

type SetStateArg = MainState | ((oldState: MainState) => MainState)

/** Example usage:
 *  ...
 *  const [uploadImage, {assetId, isLoading: isUploading, error, imageLocation}] = useImageUpload()
 *
 *  const onFileSelect = e => uploadImage(e.currentTarget.files[0])
 *
 *  render(
 *  <>
 *  ...
 *    {isUploading ? <Spinner /> : null}
 *    {error ? {error} : null}
 *    {imageLocation <img src={imageLocation} alt="something" />}
 *  ...
 *  </>
 *  )
 * */

const useImageUpload = () => {
  const [state, _setState] = useState<MainState>({ status: Status.Idle })
  const [selectedFileToUpload, setSelectedFileToUpload] = useState<File | null>(null)
  const [createAssetMutation] = useCreateAssetMutation()

  const setState = useCallback((newState: SetStateArg) => _setState(newState), [])

  const { status } = state
  const error = 'error' in state ? state.error : ''
  const assetId = 'assetId' in state ? state.assetId : null
  const fileLocation = 'fileLocation' in state ? state.fileLocation : ''

  useEffect(() => {
    if (!selectedFileToUpload) return
    const controller = new AbortController()
    const { signal } = controller
    const handleUpload = async () => {
      setState({ status: Status.Pending })
      try {
        const uploadResponse = await uploadImage(selectedFileToUpload, {
          signal,
        })
        const { fileLocation: uploadedFileLocation } = uploadResponse
        const assetResponse = await createAssetMutation({
          variables: {
            fileExtension: selectedFileToUpload.type,
            fileLocation: uploadedFileLocation ?? '',
            fileName: selectedFileToUpload.name,
            fileSize: selectedFileToUpload.size,
          },
        })
        const uploadedAssetId = assetResponse.data?.createAsset?.id
        if (typeof uploadedAssetId === 'number') {
          setState({
            status: Status.Resolved,
            fileLocation: uploadedFileLocation ?? '',
            assetId: uploadedAssetId,
          })
        }
      } catch (e: unknown) {
        let err = 'unknown error'
        if (typeof e === 'string') err = e
        else if (e instanceof Error || e instanceof ApolloError) err = e.message
        setState({ status: Status.Rejected, error: err })
      }
    }

    void handleUpload()
    return () => {
      controller.abort()
    }
  }, [selectedFileToUpload])

  const handleImageUpload = useCallback((file: File) => setSelectedFileToUpload(file), [])
  return [
    handleImageUpload,
    {
      assetId,
      isLoading: status === Status.Pending,
      error,
      fileLocation,
    },
  ] as const
}

export default useImageUpload
