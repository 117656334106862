import { FILE_HANDLER_API } from 'config'

interface TypedResponse<T = unknown> extends Response {
  json<P = T>(): Promise<P>
}

interface Payload {
  fileLocation?: string
  error?: {
    name: string
    message: string
    field: string
    code: string
  }
}

export const uploadImage = async (file: File, options?: RequestInit) => {
  const formData = new FormData()
  formData.append('image', file, file.name)

  const res: TypedResponse<Payload> = await fetch(`${FILE_HANDLER_API}/upload-image`, {
    method: 'POST',
    body: formData,
    ...options,
  })
  const body = await res.json()
  if (res.ok && body.fileLocation) {
    return body
  }
  throw new Error(body.error?.message)
}
