import { AppCurrentUser, useAuth } from 'context/AuthContext'
import { UserRole } from 'generated/types-and-hooks'
import { Route, Redirect, RouteProps } from 'react-router-dom'

export const ONLY_SUPERADMIN = [UserRole.SuperAdmin]
export const ONLY_ADMIN = [UserRole.Admin]
export const ADMINS = [UserRole.Admin, UserRole.SuperAdmin]
export const USER = [UserRole.User]

interface PrivateRouteProps extends RouteProps {
  allowedRoles?: UserRole[]
  condition?: (user: AppCurrentUser) => boolean
  isAvailable?: boolean
}

const PrivateRoute = ({
  allowedRoles,
  condition,
  isAvailable,
  children,
  ...routeProps
}: PrivateRouteProps) => {
  const { user } = useAuth()
  if (!user?.role) return null

  const routeAllowedByRole = allowedRoles ? allowedRoles.includes(user.role) : true
  const routeallowedByCondition = condition ? condition(user) : true
  const routeallowedDirectly = isAvailable ? isAvailable : true

  const shouldAllowAccess = routeAllowedByRole && routeallowedByCondition && routeallowedDirectly

  return shouldAllowAccess ? <Route {...routeProps}>{children}</Route> : <Redirect to="/" />
}

export default PrivateRoute
