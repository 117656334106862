import { MUIDataTableOptions, SelectableRows } from 'mui-datatables'
import { useState, useMemo } from 'react'
import {
  CompanyMembershipRole,
  CompanyMembershipStatus,
  CompanyMembershipWithoutCompany,
  useCompanyMemberListQuery,
  UserWhereManyInput,
} from 'generated/types-and-hooks'
import { useAuth } from 'context/AuthContext'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DeleteButton, EditButton } from 'components/Button/IconButtons'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import PageHeader from 'components/utils/PageHeader'
import { useDeleteDialog } from 'utils/hooks/useDeleteDialog'
import { usePagination } from 'utils/query/usePagination'
import { useTranslation } from 'react-i18next'
import { useSearch } from 'utils/query/useSearch'
import { getTableChangeHandler } from 'utils/query/table-utils'
import { Box, Button } from '@material-ui/core'
import { SearchBox } from 'components/Form/SearchBox'
import { useFilter } from 'utils/query/useFilter'
import { CompanyUserData } from './list.model'
import { useGlobalLoading } from 'context/LoadingContext'
import DeleteUserDialog from 'modules/user/Delete/DeleteUser'
import { TFunction } from 'i18next'

const tableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .MuiTableCell-root': {
        borderBottom: '0px',
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  })
)

function mapUserToUserData(
  companyUser: CompanyMembershipWithoutCompany,
  t: TFunction
): CompanyUserData {
  const { user } = companyUser
  const companyUserData: CompanyUserData = {
    id: user?.id ?? 0,
    username: user?.username ?? '',
    fullName: user?.fullName ?? '',
    email: user?.email ?? '',
    roleAtCompany: t(`user:enum.roleAtCompany.${companyUser.role}`) ?? CompanyMembershipRole.User,
    statusAtCompany:
      t(`user:enum.statusAtCompany.${companyUser.status}`) ?? CompanyMembershipStatus.Inactive,
    createdAt: companyUser.createdAt ?? '',
    updatedAt: companyUser.updatedAt ?? '',
  }

  return companyUserData
}

interface Props {
  companyId: number
}

export default function CompanyUserList({ companyId }: Props) {
  const [count, setCount] = useState(0)
  const { user } = useAuth()
  const { currentPage, changePage, rowsPerPage, changeRowsPerPage, paginationParams } =
    usePagination()
  const { setQuery, clearQuery, searchQueryParams } = useSearch<UserWhereManyInput>([
    'firstName.contains',
    'lastName.contains',
  ])

  const { data: companyUsersData, loading } = useCompanyMemberListQuery({
    variables: {
      ...paginationParams,
      where: {
        id: companyId,
        //OR: searchQueryParams,
        //NOT: [{ role: UserRole.Admin }, { role: UserRole.SuperAdmin }, { id: user?.id }],
        //AND: filters,
      },
    },
  })
  const onTableChange = useMemo(
    () =>
      getTableChangeHandler({
        onChangePage: changePage,
        onChangeRowsPerPage: changeRowsPerPage,
        //onFilterChange: setFilters,
      }),
    [changePage, changeRowsPerPage]
  )
  useGlobalLoading(loading)
  const classes = tableStyles()

  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation(['common', 'user'])
  const { openDialog, closeDialog, isOpen, selectedId } = useDeleteDialog()

  const companyUserList = useMemo(() => {
    if (!companyUsersData?.company?.memberships) return []

    setCount(companyUsersData.company.memberships.length)
    const companyUsersArray = companyUsersData.company
      .memberships as NonNullable<CompanyMembershipWithoutCompany>[]
    const mappedCompanyUsers = companyUsersArray.map((u) => {
      return mapUserToUserData(u, t)
    })
    return mappedCompanyUsers
  }, [companyUsersData])

  const options: MUIDataTableOptions = {
    viewColumns: false,
    download: false,
    print: false,
    filter: true,
    search: false,
    serverSide: true,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    page: currentPage,
    count,
    onTableChange,
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => history.push(`${url}/new`)}
          >
            {t('common:action.addMember')}
          </Button>
        </>
      )
    },
  }

  const columns: DataTableColumn[] = [
    {
      name: 'id',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'username',
      label: t('user:property.userName'),
      options: { filter: false, sort: false },
    },
    {
      name: 'fullName',
      label: t('common:table.name'),
      options: { filter: false, sort: false },
    },
    {
      name: 'email',
      label: t('common:table.email'),
      options: { filter: false, sort: false },
    },
    {
      name: 'roleAtCompany',
      label: t('user:property.roleAtComp'),
      options: { filter: false, sort: false },
    },
    {
      name: 'statusAtCompany',
      label: t('user:property.statusAtComp'),
      options: { filter: false, sort: false },
    },
    // Edit company membership. It will uncomment later.
    // {
    //   name: 'Edit',
    //   label: ' ',
    //   options: {
    //     narrow: true,
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (_, { rowData }) => (
    //       <EditButton
    //         onClick={(e) => {
    //           e.stopPropagation()
    //           history.push(`${url}/${rowData[0]}/edit`)
    //         }}
    //       />
    //     ),
    //   },
    // },
    {
      name: 'Delete',
      label: ' ',
      options: {
        narrow: true,
        filter: false,
        sort: false,
        customBodyRender: (_, { rowData }) => (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation()
              openDialog(rowData[0] as number)
            }}
          />
        ),
      },
    },
  ]

  return (
    <div className={classes.table}>
      {selectedId && <DeleteUserDialog open={isOpen} onClose={closeDialog} userId={selectedId} />}
      <Box mb={2}>
        <SearchBox onSearch={setQuery} onClear={clearQuery} />
      </Box>
      <DataTable
        isLoading={loading}
        title={t('common:header.members', { entity: `${companyUsersData?.company?.name}` })}
        columns={columns}
        data={companyUserList}
        options={{
          ...options,
          onRowClick: ([id]) => {
            history.push(`/users/${id}`)
          },
        }}
      />
    </div>
  )
}
