import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'

import MUIEditButton from '@material-ui/icons/Edit'
import MUIDeleteButton from '@material-ui/icons/Delete'
import MUIBackButton from '@material-ui/icons/ArrowBack'
import MUICloseButton from '@material-ui/icons/Close'

export const EditButton = (props: IconButtonProps) => {
  return (
    <IconButton color="primary" {...props}>
      <MUIEditButton />
    </IconButton>
  )
}

export const DeleteButton = (props: IconButtonProps) => {
  return (
    <IconButton color="secondary" {...props}>
      <MUIDeleteButton />
    </IconButton>
  )
}

export const BackButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <MUIBackButton />
    </IconButton>
  )
}

export const CloseButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <MUICloseButton />
    </IconButton>
  )
}
