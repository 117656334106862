import React, { useState, useEffect, useRef, ReactNode } from 'react'
import {
  Button,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popper,
  ClickAwayListener,
  MenuList,
  Paper,
  styled,
  Grow,
  Avatar,
  Divider,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useAuth } from 'context/AuthContext'

export type MenuItem = {
  name: string
  linkTo?: string
  onClick?: () => void
  icon?: ReactNode
}
interface Props {
  menuItems: MenuItem[]
}

const UserMenu = ({ menuItems }: Props) => {
  const [open, setOpen] = useState(false)
  const { user } = useAuth()
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const anchorRef = useRef<HTMLButtonElement>(null)

  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }
  return (
    <Box display="flex" ml="auto">
      <Box display="flex" alignItems="center">
        <StyledButton ref={anchorRef} color="secondary" onClick={handleToggle}>
          <Avatar src={user?.avatar?.fileLocation || undefined} />
        </StyledButton>
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open}>
                    <MenuItem disabled>
                      {user?.firstName} {user?.lastName}
                    </MenuItem>
                    <Divider />
                    {menuItems.map((menuItem) => {
                      const linkProps: { component?: ReactNode; to?: string } = {}
                      if (menuItem.linkTo) {
                        linkProps.component = Link
                        linkProps.to = menuItem.linkTo
                      }
                      return (
                        <MenuItem
                          key={menuItem.name}
                          {...linkProps}
                          onClick={(e) => {
                            handleClose(e)
                            if (menuItem.onClick) menuItem.onClick()
                          }}
                        >
                          {menuItem.icon ? <ListItemIcon>{menuItem.icon}</ListItemIcon> : null}
                          <ListItemText primary={menuItem.name} />
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  )
}

const StyledButton = styled(Button)({
  color: 'white',
  border: 0,
  outline: 0,
  borderRadius: 9999,
  '&:hover': {
    outline: 0,
    border: 0,
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
})
export default UserMenu
