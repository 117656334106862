import { Container, Grid, Typography } from '@material-ui/core'
import AppButton from 'components/Button/AppButton'
import { BackButton } from 'components/Button/IconButtons'
import BackDropLoading from 'components/utils/BackDropLoading'
import { useSignup } from 'context/AuthContext/index'
import { CreateUserInput } from 'generated/types-and-hooks'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SignupFooter, SignupPaper, useSignUpStyles } from './Styles'
import PasswordFields from './PasswordFields'
import SecurityQuestionFields from './SecurityQuestionFields'
import BaseDataFields from './BaseDataFields'
import CheckboxFields from './CheckboxFields'
import { SignupFormValues, defaultSignupFormValues } from './types.d'

interface Props {
  onSuccesfulSignup?: (userId: number) => void
}
const SignUp: React.FC<Props> = (props: Props) => {
  const classes = useSignUpStyles()
  const { t } = useTranslation(['common', 'signup'])

  const [startSignup, { data, loading, error }] = useSignup()

  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const {
    control,
    trigger,
    getValues,
    handleSubmit,
    formState: { isValidating },
  } = useForm<SignupFormValues>({
    mode: 'onBlur',
    defaultValues: defaultSignupFormValues,
  })

  const onSubmit = (values: SignupFormValues) => {
    const parsedValues = mapFormInputsToRegisterData(values)
    startSignup(parsedValues).catch(() => null)
  }

  useEffect(() => {
    const registeredUserId = data?.registerUser?.user?.id
    const succesfulRegistration = !!registeredUserId
    if (!data) return
    if (error) {
      enqueueSnackbar(t('signup:form.unSuccessRegistrate'), {
        variant: 'error',
      })
    }
    if (succesfulRegistration && registeredUserId) {
      enqueueSnackbar(t('signup:form.successRegistrate'), {
        variant: 'success',
      })
      props.onSuccesfulSignup?.(registeredUserId)
    }
  }, [data, error])

  if (loading) return <BackDropLoading />

  return (
    <Container maxWidth="md">
      <SignupPaper>
        <h1 className={classes.title}>{t('signup:name')}</h1>
        <Typography align="center">Request access to the admin interface</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container className={classes.fieldsContainer} spacing={3}>
            <BaseDataFields control={control} trigger={trigger} isValidating={isValidating} />
            <PasswordFields getValues={getValues} control={control} />
            <SecurityQuestionFields control={control} />
            <CheckboxFields control={control} />
            <Grid item xs={12}>
              <SignupFooter>
                <BackButton onClick={() => history.goBack()} />
                <AppButton loading={loading} type="submit" variant="contained" color="primary">
                  {t('common:action.submit')}
                </AppButton>
              </SignupFooter>
            </Grid>
          </Grid>
        </form>
      </SignupPaper>
    </Container>
  )
}

const mapFormInputsToRegisterData = (values: SignupFormValues): CreateUserInput => {
  const { email, languages, lastName, firstName, password, securityQuestions, username } = values
  return {
    email,
    languages,
    lastName,
    username,
    firstName,
    password,
    securityQuestions,
    securityAnswers: processSecurityAnswers(values.securityAnswers),
  }
}

function processSecurityAnswers(answers: string[]): string {
  return answers.map((answer) => answer.trim().toLowerCase()).join('')
}

export default SignUp
