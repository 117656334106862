import Tabs from 'components/Tabs/Tabs'
import PageHeader from 'components/utils/PageHeader'
import PrivateRoute, { ADMINS } from 'components/utils/PrivateRoute'
import LeaderRoutes from 'modules/settings/leaders'
import CreateOrEditLeader from 'modules/settings/leaders/CreateOrEditLeader'
import LeaderList from 'modules/settings/leaders/LeaderList'
import { useTranslation } from 'react-i18next'
import { Switch, useRouteMatch } from 'react-router-dom'

const Settings = () => {
  const match = useRouteMatch()
  const { t } = useTranslation('setting')

  return (
    <>
      <PageHeader>{t('setting:name')}</PageHeader>
      <Tabs
        labels={[t('setting:name'), t('setting:leaders.name_other')]}
        subLinks={['', '/leaders']}
      />
      <Switch>
        <PrivateRoute allowedRoles={ADMINS} exact path={`${match.path}/leaders`}>
          <LeaderList />
        </PrivateRoute>
        <PrivateRoute allowedRoles={ADMINS} exact path={`${match.path}/leaders/new`}>
          <CreateOrEditLeader mode="create" />
        </PrivateRoute>
        <PrivateRoute allowedRoles={ADMINS} path={`${match.path}/leaders/:userId`}>
          <LeaderRoutes />
        </PrivateRoute>
      </Switch>
    </>
  )
}

export default Settings
