import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Paper,
  Container,
  Grid,
  useTheme,
  Button,
  DialogTitle,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core'
import { TextField } from 'components/Form/Atoms'
import { useSpaceFormStyles } from 'modules/space/CreateOrEdit/styles'
import { useTranslation } from 'react-i18next'
import AppButton from 'components/Button/AppButton'
import { useHistory } from 'react-router-dom'
import {
  UserRole,
  UserStatus,
  useUpdateUserStatusMutation,
  useUserLazyQuery,
} from 'generated/types-and-hooks'

import { useSnackbar } from 'notistack'
import { useGlobalLoading } from 'context/LoadingContext'

interface FormValues {
  name: string
  email: string
  role: UserRole
  status: UserStatus
}

type EditProps = { mode: 'edit'; userId: number }
type CreateProps = { mode: 'create' }
type Props = EditProps | CreateProps

const CreateOrEditLeader = (props: Props) => {
  const formMode = props.mode
  const userId = 'userId' in props ? props.userId : null
  const [emailChecked, setEmailChecked] = useState(false)
  const [role, setRole] = useState<UserRole>(UserRole.User)
  const [status, setStatus] = useState<UserStatus>(UserStatus.Inactive)
  const classes = useSpaceFormStyles()
  const { t } = useTranslation(['post', 'common', 'company', 'setting', 'user'])
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const selectableLeaderRoles =
    formMode === 'create'
      ? Object.values(UserRole).filter((role) => role !== UserRole.User)
      : Object.values(UserRole)
  const defaultValues: FormValues = {
    name: '',
    email: '',
    role: UserRole.Admin,
    status: UserStatus.Inactive,
  }
  const { control, reset, handleSubmit, register } = useForm({
    defaultValues,
  })
  const [getUser, { data: userData, loading: userLoading }] = useUserLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [updateLeader, { data: updatedLeader, loading: updateLoading }] =
    useUpdateUserStatusMutation()

  useGlobalLoading(userLoading || updateLoading)

  useEffect(() => {
    if (formMode === 'edit' && userId) getUser({ variables: { where: { id: userId } } })
  }, [])

  useEffect(() => {
    if (formMode === 'create' || !userData) return
    reset({
      name: userData.user?.fullName ?? '',
      email: userData.user?.email ?? '',
      role: userData.user?.role ?? UserRole.Admin,
      status: userData.user?.status ?? UserStatus.Inactive,
    })
  }, [userData])

  const onSubmit = (values: FormValues) => {
    if (formMode === 'create') {
      getUser({ variables: { where: { email: values.email } } })
      setEmailChecked(true)
      setRole(values.role)
      setStatus(values.status)
    }

    if (formMode === 'edit' && userId) {
      const result = updateLeader({
        variables: {
          where: { id: userId },
          input: {
            role: values.role,
            status: values.status,
          },
        },
        update(cache) {
          if (values.role === UserRole.User) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'usersConnection' })
            cache.gc()
          }
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('setting:leaders.name') }), {
          variant: 'error',
        })
      })
    }
  }

  useEffect(() => {
    if (emailChecked && !userLoading && !userData?.user) {
      enqueueSnackbar(t('company:form.noUserFound'), { variant: 'error' })
      setEmailChecked(false)
      return
    }
    if (emailChecked && userData?.user && !userLoading) {
      if (userData.user.role === UserRole.Admin || userData.user.role === UserRole.SuperAdmin) {
        enqueueSnackbar(t('company:form.alreadyLeader'), { variant: 'error' })
      } else {
        const result = updateLeader({
          variables: {
            where: { uuid: userData.user.uuid },
            input: {
              role: role,
              status: status,
            },
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'usersConnection' })
            cache.gc()
          },
        })
        result.catch((e) => {
          console.error(e)
          enqueueSnackbar(
            t('common:status.unSuccessCreate', { entity: t('setting:leaders.name') }),
            {
              variant: 'error',
            }
          )
        })
      }
    }
  }, [userData])

  useEffect(() => {
    if (!updatedLeader) return
    history.goBack()
    enqueueSnackbar(
      t(`common:status.${formMode === 'create' ? 'successCreate' : 'successUpdate'}`, {
        entity: t('setting:leaders.name'),
      }),
      { variant: 'success' }
    )
  }, [updatedLeader])

  // Validation
  register('email', {
    required: String(t('post:members.emailRequired')),
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: t('post:members.emailError'),
    },
  })

  return (
    <>
      {userLoading && !emailChecked ? null : (
        <Container maxWidth="sm">
          <Paper elevation={2} className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle className={classes.dialogTitle}>
                {formMode === 'edit'
                  ? t('setting:leaders.form.editLeader')
                  : t('setting:leaders.form.addLeader')}
              </DialogTitle>

              {formMode === 'edit' && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="name"
                      name="name"
                      label={t('common:table.name')}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
              {formMode === 'create' && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="email"
                      name="email"
                      label={t('setting:leaders.form.email')}
                      required
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={matches ? classes.inputField : classes.inputFieldXs}
                    fullWidth
                    control={control}
                    id="role"
                    name="role"
                    label={t('setting:leaders.form.role')}
                    select
                    required
                  >
                    {selectableLeaderRoles.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`user:enum.role.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="status"
                    name="status"
                    label={t('setting:leaders.form.status')}
                    select
                    required
                  >
                    {Object.values(UserStatus).filter(o => o !== UserStatus.Registered).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`user:enum.status.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.actionButtons}>
                <Button
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => history.goBack()}
                >
                  {t('common:action.cancel')}
                </Button>
                <AppButton style={{ color: theme.palette.primary.main }} type="submit">
                  {formMode === 'edit' ? t('common:action.edit') : t('common:action.save')}
                </AppButton>
              </Grid>
            </form>
          </Paper>
        </Container>
      )}
    </>
  )
}

export default CreateOrEditLeader
