import { CreateUserInput } from 'generated/types-and-hooks'

export type SignupFormValues = Omit<CreateUserInput, 'securityQuestions' | 'securityAnswers'> & {
  securityQuestions: [string, string]
  securityAnswers: [string, string]
  passwordConfirm: string
  acceptTermsAndConditions: boolean
  acceptPrivacyPolicy: boolean
}

export const defaultSignupFormValues: SignupFormValues = {
  email: '',
  firstName: '',
  languages: [],
  lastName: '',
  password: '',
  passwordConfirm: '',
  securityQuestions: ['', ''],
  securityAnswers: ['', ''],
  username: '',
  bio: '',
  acceptTermsAndConditions: false,
  acceptPrivacyPolicy: false,
}
