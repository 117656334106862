import { useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { AppBar, Tabs as MUITabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tabPanel: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabPanelBox: {
    width: '100%',
  },
  appBar: {
    marginBottom: '2rem',
  },
  button: {
    maxWidth: '10rem',
    marginRight: '1.3rem',
  },
  link: {
    display: 'flex',
  },
  icon: {
    width: 20,
    height: 20,
  },
}))

const isActiveLink = (routeToMatch: string, pathname: string) =>
  pathname.split('/').includes(routeToMatch.replace('/', ''))

interface Props {
  labels: string[]
  subLinks: string[]
  defaultActiveLink?: string
  className?: string
}

const Tabs = ({ labels, subLinks, defaultActiveLink, className }: Props) => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const [tab, setTab] = useState(0)
  const classesTabs = useStyles()
  const { pathname } = useLocation()
  const [tabsData, setTabsData] = useState([{ label: labels[0], subLink: subLinks[0] }])

  useEffect(() => {
    if (!defaultActiveLink) return
    history.push(`${url}${defaultActiveLink}`)
  }, [defaultActiveLink])

  useEffect(() => {
    subLinks.slice(1).forEach((subLink, index) => {
      setTabsData((prev) => [...prev, { label: labels[index + 1], subLink: subLink }])
    })
  }, [])

  useEffect(() => {
    subLinks.forEach((subLink, index) => {
      if (isActiveLink(`${subLink}`, pathname)) setTab(index)
    })
    if (isActiveLink('/edit', pathname)) setTab(0)
  }, [pathname])

  return (
    <AppBar position="static" className={className ? className : classesTabs.appBar}>
      <MUITabs
        value={tab}
        variant="scrollable"
        scrollButtons="on"
        TabIndicatorProps={{
          style: { display: isActiveLink('/edit', pathname) ? 'none' : 'inline' },
        }}
      >
        {tabsData.map((tab) => (
          <Tab
            key={tab.subLink}
            label={tab.label}
            onClick={() => history.push(`${url}${tab.subLink}`)}
          />
        ))}
      </MUITabs>
    </AppBar>
  )
}

export default Tabs
