import SpaceRoutes from 'modules/space'
import CreateOrEditSpace from 'modules/space/CreateOrEdit/CreateOrEditForm'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import SpaceList from './SpaceList'

const Spaces = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <SpaceList />
      </Route>
      <Route exact path={`${match.path}/new`}>
        <CreateOrEditSpace mode="create" />
      </Route>
      <Route path={`${match.path}/:spaceId`}>
        <SpaceRoutes />
      </Route>
    </Switch>
  )
}

export default Spaces
