import React from 'react'
import { LinearProgress, styled, Theme, withStyles } from '@material-ui/core'
import { useGlobalLoading } from 'context/LoadingContext'

const AppLoaderBarWrapper = styled('div')({
  width: '100%',
  position: 'fixed',
  zIndex: 10000,
  top: 0,
  left: 0,
})

const BorderLinearProgress = withStyles((theme: Theme) => ({
  root: {
    height: 3,
    backgroundColor: theme.palette.primary.main,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bar: {
    backgroundColor: theme.palette.primary.contrastText,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
}))(LinearProgress)

export const AppLoaderTopBar: React.FC = ({ children }) => {
  const { loading, progress } = useGlobalLoading()
  return (
    <>
      {loading ? (
        <>
          <AppLoaderBarWrapper>
            <BorderLinearProgress variant="determinate" value={progress} />
          </AppLoaderBarWrapper>
        </>
      ) : null}
      {children}
    </>
  )
}
