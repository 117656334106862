import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import CreateOrEditLeader from './CreateOrEditLeader'

const LeaderRoutes = () => {
  const match = useRouteMatch()
  const params = useParams<{ userId: string }>()
  const userId = parseInt(params.userId, 10)

  return (
    <Switch>
      <Route path={`${match.path}/edit`}>
        <CreateOrEditLeader mode={'edit'} userId={userId} />
      </Route>
    </Switch>
  )
}

export default LeaderRoutes
