import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PostList from './postList'
import CreateOrEditPost from 'modules/post/CreateOrEdit/CreateOrEditPost'
import PostRoutes from 'modules/post'

const Posts = () => {
    const match = useRouteMatch()

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <PostList />
            </Route>
            <Route exact path={`${match.path}/new`}>
                <CreateOrEditPost mode="create" />
            </Route>
            <Route path={`${match.path}/:postId`}>
                <PostRoutes />
            </Route>
        </Switch>
    )
}

export default Posts
