import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { LOCALSTORAGE_AUTH_KEY } from 'utils/constants/general'
import { GQL_API } from 'config'
import typePolicies from './client.typePolicies'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )

  // eslint-disable-next-line no-console
  if (networkError) console.log('[Network error]: ', networkError)
})

const authLink = new ApolloLink((operation, forward) => {
  const token =
    localStorage.getItem(LOCALSTORAGE_AUTH_KEY) ?? sessionStorage.getItem(LOCALSTORAGE_AUTH_KEY)

  operation.setContext({
    headers: {
      authorization: token ? `${token}` : '',
    },
  })
  return forward(operation)
})

const uri = `${GQL_API}`

const httpLink = new HttpLink({ uri })

const link = from([errorLink, authLink, httpLink])

const cache = new InMemoryCache({ typePolicies })
const client = new ApolloClient({ link, cache })

export default client
