import { useState, useCallback, useEffect } from 'react'

import { clearToken, getToken, setToken } from './utils'

interface Args {
  onTokenChange?: () => void
}
export const useAuthToken = (args: Args) => {
  const [authTokenString, setAuthTokenString] = useState<string | null>(getToken())

  useEffect(() => {
    if (args.onTokenChange) {
      args.onTokenChange()
    }
  }, [authTokenString, args])

  const setAuthToken = useCallback((token: string, { persist }: { persist: boolean }) => {
    setToken(token, persist)
    setAuthTokenString(token)
  }, [])

  const clearAuthToken = useCallback(() => {
    clearToken()
    setAuthTokenString(null)
  }, [])

  return { authToken: authTokenString, setAuthToken, clearAuthToken } as const
}
