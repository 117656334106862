import {
  FormControl,
  InputLabel,
  Select as MUISelect,
  FormHelperText,
  FormControlProps,
} from '@material-ui/core'
import { FieldValues, UseControllerProps, Controller } from 'react-hook-form'

type Props<T> = UseControllerProps<T> &
  Partial<FormControlProps> & { label: string; shrink?: boolean }

export const Select = <T extends FieldValues>({
  name,
  label,
  control,
  rules,
  defaultValue,
  children,
  shrink,
  ...formcontrolProps
}: Props<T>) => {
  const labelId = `${name}-label`
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} {...formcontrolProps}>
          <InputLabel shrink={shrink} id={labelId}>
            {label}
          </InputLabel>
          <MUISelect labelId={labelId} label={label} {...field}>
            {children}
          </MUISelect>
          <FormHelperText>{error?.message ? error.message : null}</FormHelperText>
        </FormControl>
      )}
      rules={rules}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  )
}
