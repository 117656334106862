import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useSpaceViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: 'calc(50% + 15rem)',
      minWidth: '30rem',
      marginTop: '1.5rem',
    },
    rootXs: {
      flexGrow: 1,
      width: '90%',
      marginTop: '1.5rem',
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: '2rem',
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    logoGrid: {
      marginRight: '2rem',
    },
    logo: {
      height: '11.25rem',
      width: '11.25rem',
      padding: theme.spacing(2),
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoXs: {
      height: '11.25rem',
      width: '11.25rem',
      padding: theme.spacing(2),
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      height: '10rem',
      width: '10rem',
      objectFit: 'cover',
    },
    baseData: {
      marginBottom: '2rem',
    },
    textLeft: {
      margin: '1rem 0.5rem 1rem 0',
    },
    textRight: {
      margin: '1rem 0',
    },
    colorBox: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      margin: '0.5rem 0',
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  })
)
