import { ApolloProvider } from '@apollo/client'
import client from 'apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { CssBaseline, makeStyles } from '@material-ui/core'
import React from 'react'
import { AuthProvider } from 'context/AuthContext'
import { LoadingProvider } from 'context/LoadingContext'
import { AppLoaderTopBar } from 'components/utils/LoadingIndicators/AppLoaderBar'

const useStyles = makeStyles(() => ({
  snackbarRoot: {
    marginTop: '8px',
  },
}))

const AppProviders: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Router>
      <ApolloProvider client={client}>
        <LoadingProvider>
          <CssBaseline>
            <AppLoaderTopBar>
              <AuthProvider>
                <SnackbarProvider classes={{ root: classes.snackbarRoot }}>
                  {children}
                </SnackbarProvider>
              </AuthProvider>
            </AppLoaderTopBar>
          </CssBaseline>
        </LoadingProvider>
      </ApolloProvider>
    </Router>
  )
}

export default AppProviders
