import { UserRole } from 'generated/types-and-hooks'
import { useCallback } from 'react'
import { LOCALSTORAGE_AUTH_KEY } from 'utils/constants/general'

export function getToken(): string | null {
  return (
    localStorage.getItem(LOCALSTORAGE_AUTH_KEY) ?? sessionStorage.getItem(LOCALSTORAGE_AUTH_KEY)
  )
}

export function setToken(token: string, persist = false): void {
  const destStorage = persist ? localStorage : sessionStorage
  destStorage.setItem(LOCALSTORAGE_AUTH_KEY, token)
}

export function clearToken(): void {
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEY)
  sessionStorage.removeItem(LOCALSTORAGE_AUTH_KEY)
}

export function hasAuthority(role: UserRole): boolean {
  if (role === UserRole.Admin) return true
  if (role === UserRole.SuperAdmin) return true
  return false
}
