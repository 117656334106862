import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormValues } from './model'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSpaceFormValidation = (register: UseFormRegister<FormValues>) => {
  const { t } = useTranslation('space')
  register('name', {
    required: String(t('space:form.nameRequired')),
    pattern: {
      value: /[A-Z0-9]{1}[A-Za-z0-9]{2}/,
      message: t('space:form.nameError'),
    },
  })
  register('description', {
    required: String(t('space:form.descriptionRequired')),
    minLength: {
      value: 5,
      message: t('space:form.descriptionError'),
    },
    maxLength: {
      value: 190,
      message: t('space:form.descriptionError'),
    },
  })
  register('icon', {
    required: String(t('space:form.iconRequired')),
  })
}
