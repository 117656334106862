import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { CompanyMembersTableData, tableStyles } from '../model_styles'
import DataTable, { DataTableColumn } from 'components/Table/DataTable'
import { MUIDataTableOptions, SelectableRows } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { Company, CompanyMembershipStatus, useCompanyMembersQuery } from 'generated/types-and-hooks'
import TableText from '../TableText'

function mapCompaniesToMembersData(c: Company) {
  const allMembers: CompanyMembersTableData = {
    companyId: c.id ?? 1,
    companyName: c.name ?? '',
    allCount: c.memberships?.map((e) => e?.user?.id).length ?? 0,
    activeCount:
      c.memberships?.filter((e) => e?.status === CompanyMembershipStatus.Active).length ?? 0,
    inactiveCount:
      c.memberships?.filter((e) => e?.status === CompanyMembershipStatus.Inactive).length ?? 0,
    passiveCount:
      c.memberships?.filter((e) => e?.status === CompanyMembershipStatus.Passive).length ?? 0,
    requestedCount:
      c.memberships?.filter((e) => e?.status === CompanyMembershipStatus.Requested).length ?? 0,
    bannedCount:
      c.memberships?.filter((e) => e?.status === CompanyMembershipStatus.Banned).length ?? 0,
  }
  return allMembers
}

const CompanyMembers = () => {
  const { t } = useTranslation(['company', 'stat', 'user'])
  const classes = tableStyles()
  const history = useHistory()
  const { data, loading } = useCompanyMembersQuery()

  const companyMembersData: CompanyMembersTableData[] = useMemo(() => {
    if (!data?.companiesConnection?.companies) return []

    const companiesArray = data.companiesConnection.companies as NonNullable<Company>[]
    const mappedCompanies = companiesArray.map((c) => {
      return mapCompaniesToMembersData(c)
    })
    return mappedCompanies
  }, [data])

  const options: MUIDataTableOptions = {
    viewColumns: false,
    download: false,
    print: false,
    search: false,
    pagination: false,
    onRowClick: ([companyId]) => history.push(`/companies/${companyId}/members`),
    selectableRows: 'none' as SelectableRows,
    selectableRowsHideCheckboxes: true,
  }

  const columns: DataTableColumn[] = [
    {
      name: 'companyId',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'companyName',
      label: t('company:companyName'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'allCount',
      label: t('stat:label.total'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'activeCount',
      label: t('user:enum.statusAtCompany.ACTIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'inactiveCount',
      label: t('user:enum.statusAtCompany.INACTIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'passiveCount',
      label: t('user:enum.statusAtCompany.PASSIVE'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'requestedCount',
      label: t('user:enum.statusAtCompany.REQUESTED'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'bannedCount',
      label: t('user:enum.statusAtCompany.BANNED'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
    {
      name: 'bannedCount',
      label: 'Invited',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <TableText text={value} />,
      },
    },
  ]

  return (
    <div className={classes.table}>
      <DataTable
        isLoading={loading}
        title={t('stat:title.companyMembers')}
        columns={columns}
        data={companyMembersData}
        options={options}
      />
    </div>
  )
}

export default CompanyMembers
