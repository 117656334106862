import DeleteWarningDialog from 'components/Dialog/DeleteWarningDialog'
import { useTranslation } from 'react-i18next'

interface Props {
  onClose: () => void
  companyId: number
  open: boolean
}

const DeleteCompanyDialog = ({ companyId, open, onClose }: Props) => {
  const { t } = useTranslation(['common', 'company'])

  const onDelete = () => {
    alert(`Company Id: ${companyId}. Delete function has not been implemented, yet.`)
  }

  return (
    <DeleteWarningDialog
      open={open}
      onDelete={onDelete}
      onClose={onClose}
      title={t('common:confirm.delete', { entity: t('company:name') })}
    />
  )
}

export default DeleteCompanyDialog
