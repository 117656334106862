import { Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useGlobalLoading } from 'context/LoadingContext'
import {
  CompanyMembershipRole,
  CompanyMembershipStatus,
  useUsersCompanyListQuery,
} from 'generated/types-and-hooks'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserDetailsStyles } from '../styles'

export type UsersCompany = {
  companyUuId?: string
  companyName: string
  roleAtCompany: CompanyMembershipRole
  statusAtCompany: CompanyMembershipStatus
}

interface Props {
  userId: number
}

const UsersCompanyMembership = ({ userId }: Props) => {
  const classes = useUserDetailsStyles()
  const { t } = useTranslation(['user', 'welcome'])
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const { data, loading } = useUsersCompanyListQuery()
  useGlobalLoading(loading)

  const usersCompanyList: NonNullable<UsersCompany>[] = useMemo<UsersCompany[]>(() => {
    if (!data?.companiesConnection?.companies) return []

    const usersCompaniesData: UsersCompany[] = []
    data.companiesConnection.companies.forEach((e) => {
      if (e?.memberships?.find((ee) => ee?.user?.id === userId)) {
        const companyName = e.name
        const roleAtCompany = e?.memberships?.find((ee) => ee?.user?.id === userId)?.role
        const statusAtCompany = e?.memberships?.find((ee) => ee?.user?.id === userId)?.status

        if (companyName && roleAtCompany && statusAtCompany) {
          const usersCompanyData = {
            companyName: companyName,
            roleAtCompany: roleAtCompany,
            statusAtCompany: statusAtCompany,
          }
          if (usersCompanyData) usersCompaniesData.push(usersCompanyData)
        }
      }
    })
    return usersCompaniesData
  }, [data])

  if (loading) {
    return null
  } else {
    return (
      <Paper className={classes.paper}>
        <Typography color="textPrimary" paragraph>
          {`Company ${usersCompanyList.length === 1 ? 'Membership' : 'Memberships'}`}
        </Typography>
        <Divider className={classes.divider} />
        {usersCompanyList.length ? (
          usersCompanyList.map((e) => (
            <Grid key={e.companyName} container alignItems="center" spacing={3}>
              <Grid item xs={6}>
                <Typography color="textPrimary">{e.companyName}</Typography>
              </Grid>
              {matches ? (
                <Grid item xs={6}>
                  <Typography color="textSecondary">
                    {t(`user:enum.roleAtCompany.${e.roleAtCompany}`)}
                  </Typography>
                  <Typography color="textSecondary">
                    {t(`user:enum.statusAtCompany.${e.statusAtCompany}`)}
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={3}>
                    <Typography color="textSecondary">
                      {t(`user:enum.roleAtCompany.${e.roleAtCompany}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="textSecondary">
                      {t(`user:enum.statusAtCompany.${e.statusAtCompany}`)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          ))
        ) : (
          <Typography color="textSecondary">
            {t('welcome:info.noFound', { entity: 'membership' })}
          </Typography>
        )}
      </Paper>
    )
  }
}

export default UsersCompanyMembership
