import CreateOrEditSpace from 'modules/space/CreateOrEdit/CreateOrEditForm'
import { useTranslation } from 'react-i18next'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import Header from './Header'
import Tabs from 'components/Tabs/Tabs'
import SpaceView from './View'
import CompanyList from 'pages/Companies/companyList'

const SpaceRoutes = () => {
  const { t } = useTranslation(['common', 'company'])
  const match = useRouteMatch()
  const params = useParams<{ spaceId: string }>()
  const spaceId = parseInt(params.spaceId, 10)

  return (
    <>
      <Header spaceId={spaceId} />
      <Tabs
        labels={[t('common:tab.details'), t('company:name_other')]}
        subLinks={['', '/companies']}
      />
      <Switch>
        <Route exact path={`${match.path}/edit`}>
          <CreateOrEditSpace mode="edit" spaceId={spaceId} />
        </Route>
        <Route exact path={`${match.path}/companies`}>
          <CompanyList spaceId={spaceId} />
        </Route>
        <Route exact path={`${match.path}`}>
          <SpaceView spaceId={spaceId} />
        </Route>
      </Switch>
    </>
  )
}

export default SpaceRoutes
