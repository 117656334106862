import { makeStyles, createStyles, styled, Typography, Paper } from '@material-ui/core'

export const useSignUpStyles = makeStyles(() =>
  createStyles({
    fieldsContainer: {
      padding: '1rem 0',
      paddingTop: '3rem',
    },

    centered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    title: { textAlign: 'center' },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
  })
)

export const StyledHeader = styled(Typography)({
  margin: '0',
  marginTop: '1rem',
})

export const SignupPaper = styled(Paper)({
  padding: '1rem 3rem',
  margin: '1rem auto',
})

export const SignupFooter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '24px',
})
