import { useState, useCallback, useEffect } from 'react'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import {
  makeStyles,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { BackButton } from 'components/Button/IconButtons'

import { useSnackbar } from 'notistack'
import { readURL } from 'utils/image-utils/readUrl'
import getCroppedImg from 'utils/image-utils/getCroppedImage'
import { resize } from 'utils/image-utils/resize'
import { normalize } from 'utils/image-utils/normalize'
import ResponsiveDialog from 'components/Dialog/ResponsiveDialog'
import AppButton from 'components/Button/AppButton'

const MAX_ZOOM = 5
const MIN_ZOOM = 1
const DEFAULT_ZOOM = 1

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
      position: 'relative',
    },
    '& .reactEasyCrop_Container': {
      overflow: 'visible',
    },
  },
  body: {
    minHeight: '20rem',
  },

  cropContainer: {},

  controls: {
    position: 'relative',
    bottom: 0,
    left: '50%',
    width: '50%',
    transform: 'translateX(-50%)',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  },
  slider: {
    padding: '22px 0px',
  },
  actions: {
    padding: '1rem',
  },
}))

interface Props {
  onCrop: (file: File) => void
  onClose?: () => void
  isOpen: boolean
  initialImage: File
  title?: string
}

const ImageCropper = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(DEFAULT_ZOOM)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop | null>(null)
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onCropComplete = useCallback((_, _croppedAreaPixels: PixelCrop) => {
    setCroppedAreaPixels(_croppedAreaPixels)
  }, [])

  const init = useCallback(() => {
    if (imageUrl) URL.revokeObjectURL(imageUrl)
    setZoom(DEFAULT_ZOOM)
    setCrop({ x: 0, y: 0 })
    setCroppedAreaPixels(null)
    setImageUrl(undefined)
  }, [imageUrl])

  useEffect(() => {
    const handleNewImage = async () => {
      setIsLoading(true)
      const normalizedImage = await normalize(props.initialImage, 1200, 400)

      const newImageUrl = await readURL(normalizedImage)
      setImageUrl(newImageUrl)
      setIsLoading(false)
    }
    handleNewImage().catch((e) => {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' })
    })
  }, [props.initialImage])

  const handleClose = () => {
    init()
    if (props.onClose) props.onClose()
  }

  const handleCrop = async () => {
    if (!imageUrl || !croppedAreaPixels) return
    try {
      setIsLoading(true)
      const croppedImageBlob = await getCroppedImg(imageUrl, croppedAreaPixels)
      const croppedImageUrl = await readURL(croppedImageBlob)
      const finalResizedImage = await resize(croppedImageUrl, 600, 600)
      const file = new File([finalResizedImage], props.initialImage.name, {
        type: finalResizedImage.type,
      })

      props.onCrop(file)
    } catch (e: unknown) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <ResponsiveDialog
      fullScreen={fullScreen}
      open={props.isOpen}
      onClose={handleClose}
      maxWidth="sm"
      className={classes.dialog}
      fullWidth
    >
      <DialogTitle>{props.title ? props.title : 'Crop the image'}</DialogTitle>
      <DialogContent>
        <div className={classes.body}>
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            aspect={1}
            objectFit="vertical-cover"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={(zoomValue: number) => {
              setZoom(zoomValue)
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {props.onClose ? <BackButton onClick={handleClose} /> : null}
        <Box mx={2}>
          <Typography>Zoom</Typography>
        </Box>
        <Slider
          value={zoom}
          min={MIN_ZOOM}
          max={MAX_ZOOM}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(_, _zoom) => setZoom(Number(_zoom))}
        />
        <AppButton loading={isLoading} onClick={handleCrop} color="primary">
          Crop
        </AppButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}

type Point = {
  x: number
  y: number
}

interface PixelCrop {
  width: number
  height: number
  x: number
  y: number
}

export default ImageCropper
