import { Reference } from '@apollo/client'
import { TypedTypePolicies } from 'generated/apollo-helpers'

const typePolicies: TypedTypePolicies = {
  // Keys in this object will be validated against the typed on your schema
  ActivityLogsConnection: {
    fields: {
      activityLogs: {
        keyArgs: false,
        ...getMergeFn(),
        ...getReadFn(),
      },
    },
  },

  UsersConnections: {
    fields: {
      users: {
        keyArgs: false,
        ...getMergeFn(),
        ...getReadFn(),
      },
    },
  },

  CompaniesConnection: {
    fields: {
      companies: {
        keyArgs: false,
        ...getMergeFn(),
        ...getReadFn(),
      },
    },
  },
}

// Pagination helpers (merging and reading out the cache paginated)
type ReadArgs = null | { skip?: number; take?: number }
function getReadFn<T = Reference>() {
  const read = (existing: T[], { args }: { args: ReadArgs }) => {
    if (!args) return existing

    const { skip = 0, take = 10 }: { skip?: number; take?: number } = args
    const [...existingLogs] = existing
    const [sliceStart, sliceEnd] = [skip, skip + take]
    return existingLogs.slice(sliceStart, sliceEnd)
  }
  return { read }
}

const mergeArrays = <T>(existingArr: T[] = [], incomingArr: T[], skip: number) => {
  const mergedArr = [...existingArr]
  incomingArr.forEach((incomingEntry, index) => {
    mergedArr[skip + index] = incomingEntry
  })
  return mergedArr
}
type MergeArgs = null | { skip?: number }

function getMergeFn<T>() {
  const merge = (existing: T[], incoming: T[], { args }: { args: MergeArgs }) => {
    const skip = args?.skip ?? 0
    return mergeArrays(existing, incoming, skip)
  }
  return { merge }
}

export default typePolicies
