import React from 'react'
import { Box, Divider, Container, makeStyles, Theme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { BackButton } from 'components/Button/IconButtons'

interface Props {
  aside?: React.ReactNode
  noBackBtn?: boolean
  icon?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: Props) => {
    return props.noBackBtn
      ? {}
      : {
          paddingLeft: 0,
        }
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.6rem',
    },
  },
  headerAside: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
}))

const PageHeader: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const history = useHistory()
  const headerLeft = typeof props.children === 'string' ? <h1>{props.children}</h1> : props.children

  return (
    <Box mb={3}>
      <Container className={classes.container} maxWidth="xl">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div className={classes.headerLeft}>
            <Box display="flex" alignItems="center" mr={2}>
              <BackButton onClick={() => history.goBack()} />
            </Box>
            {props.icon ? (
              <Box display="flex" alignItems="center" mr={2}>
                {props.icon}
              </Box>
            ) : null}
            {headerLeft}
          </div>
          <div className={classes.headerAside}>{props.aside}</div>
        </Box>
      </Container>
      <Divider />
    </Box>
  )
}

export default PageHeader
