import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField'
import { TextField as MUITextField, makeStyles } from '@material-ui/core'

export type TextFieldProps<T> = UseControllerProps<T> & Partial<MUITextFieldProps>

const useStyles = makeStyles(() => ({
  multiline: {
    '& textarea': { minHeight: '3rem' },
  },
}))

export const TextField = <T extends FieldValues>({
  name,
  label,
  control,
  rules,
  defaultValue,
  className,
  ...props
}: TextFieldProps<T>) => {
  const classes = useStyles()
  const InputClass = props.multiline ? `${classes.multiline} ${className ?? ''}` : className ?? ''

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <MUITextField
          className={InputClass}
          variant="outlined"
          error={!!error}
          helperText={error?.message}
          label={label}
          type="text"
          {...field}
          {...props}
        />
      )}
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
    />
  )
}
