import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: { marginTop: '1.5rem' },

  paper: {
    marginTop: '1rem',
    padding: '1.5rem 1rem',
    margin: '0 auto 2.5rem',
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '1.2rem',
    },

    '& .MuiDialogTitle-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  formBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      flexDirection: 'row-reverse',
    },

    '& >*': {
      margin: '1rem',
    },
  },
  formBody__fieldsArea: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      margin: '0 0.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '1.5rem',
    },
  },
  button: {
    marginLeft: '0.25rem',
  },
  inputField: { marginBottom: '1rem' },
  inputFieldXs: { marginBottom: '0.75rem' },
}))
