import { Paper, makeStyles, createStyles, Theme } from '@material-ui/core'
import defaultLogo from 'assets/img/default-logo_sm.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: '3rem',
      width: '3rem',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      height: '2.5rem',
      width: '2.5rem',
      objectFit: 'cover',
    },
  })
)

interface Props {
  src: string
  alt?: string
}

const Logo = ({ src, alt }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'company'])

  return (
    <Paper className={classes.logo}>
      {src ? (
        <img className={classes.image} src={src} alt={alt ? alt : t('common:table.logo')} />
      ) : (
        <img className={classes.image} src={defaultLogo} alt={t('company:defaultLogo')} />
      )}
    </Paper>
  )
}
export default Logo
