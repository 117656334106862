import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Paper, Container, Grid, useTheme, Button, DialogTitle, MenuItem } from '@material-ui/core'
import { TextField } from 'components/Form/Atoms'
import { useSpaceFormStyles } from 'modules/space/CreateOrEdit/styles'
import { useTranslation } from 'react-i18next'
import AppButton from 'components/Button/AppButton'
import { useHistory } from 'react-router-dom'
import {
  CompanyMembershipRole,
  CompanyMembershipStatus,
  useCompanyMemberListQuery,
  useCreateCompanyMemberMutation,
  useUpdateCompanyMemberMutation,
  useUserLazyQuery,
} from 'generated/types-and-hooks'
import { FormValues } from './model_validation'
import { useCompanyMemberFormValidation } from './model_validation'
import { useSnackbar } from 'notistack'
import { useGlobalLoading } from 'context/LoadingContext'
import { selectableRoles } from 'modules/user/CreateOrEdit/model'

type EditProps = { mode: 'edit'; companyId: number; userId: number }
type CreateProps = { mode: 'create'; companyId: number }
type Props = EditProps | CreateProps

const CreateOrEditMember = (props: Props) => {
  const formMode = props.mode
  const companyId = 'companyId' in props ? props.companyId : null
  const userId = 'userId' in props ? props.userId : null
  const [emailChecked, setEmailChecked] = useState(false)
  const [userUuId, setUserUuId] = useState<string | undefined>('')
  const [companyUuId, setCompanyUuId] = useState<string>('')
  const [roleAtCompany, setRoleAtCompany] = useState<CompanyMembershipRole>(
    CompanyMembershipRole.User
  )
  const [statusAtCompany, setStatusAtCompany] = useState<CompanyMembershipStatus>(
    CompanyMembershipStatus.Inactive
  )
  const classes = useSpaceFormStyles()
  const { t } = useTranslation(['common', 'company', 'user'])
  const theme = useTheme()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const defaultValues: FormValues = {
    name: '',
    email: '',
    roleAtCompany: CompanyMembershipRole.User,
    statusAtCompany: CompanyMembershipStatus.Inactive,
  }
  const { control, reset, handleSubmit, register } = useForm({
    defaultValues,
  })
  const { data: companyData, loading } = useCompanyMemberListQuery({
    variables: { where: { id: companyId } },
  })
  const [getUser, { data: userData, loading: userLoading }] = useUserLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [createMember, { data: createdMember, loading: createLoading }] =
    useCreateCompanyMemberMutation({
      update(cache) {
        const normalizedId = cache.identify({ id: companyId, __typename: 'Company' })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      optimisticResponse: {},
    })
  const [updateCompanyMember, { data: updatedMember, loading: updateLoading }] =
    useUpdateCompanyMemberMutation()
  useGlobalLoading(loading || createLoading || updateLoading)
  useCompanyMemberFormValidation(register)

  useEffect(() => {
    if (!companyData?.company?.uuid) return
    setCompanyUuId(companyData.company.uuid)
    if (formMode === 'create' || !companyData?.company?.memberships) return
    if (companyData.company.memberships.find((e) => e?.user?.id === userId)?.user?.uuid)
      setUserUuId(
        companyData.company.memberships.find((e) => e?.user?.id === userId)?.user?.uuid ?? ''
      )
    reset({
      name:
        companyData.company.memberships.find((e) => e?.user?.id === userId)?.user?.fullName ?? '',
      email: companyData.company.memberships.find((e) => e?.user?.id === userId)?.user?.email ?? '',
      roleAtCompany:
        companyData.company.memberships.find((e) => e?.user?.id === userId)?.role ??
        CompanyMembershipRole.User,
      statusAtCompany:
        companyData.company.memberships.find((e) => e?.user?.id === userId)?.status ??
        CompanyMembershipStatus.Inactive,
    })
  }, [companyData])

  const onSubmit = (values: FormValues) => {
    if (formMode === 'create' && companyUuId) {
      getUser({ variables: { where: { email: values.email } } })
      setEmailChecked(true)
      setRoleAtCompany(values.roleAtCompany)
      setStatusAtCompany(values.statusAtCompany)
    }

    if (formMode === 'edit' && companyUuId && userUuId && userId) {
      const result = updateCompanyMember({
        variables: {
          input: {
            userUUID: userUuId,
            companyUUID: companyUuId,
            role: values.roleAtCompany,
            status: values.statusAtCompany,
          },
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(t('common:status.unSuccessUpdate', { entity: t('common:table.member') }), {
          variant: 'error',
        })
      })
    }
  }

  useEffect(() => {
    if (emailChecked && !userLoading && !userData?.user) {
      enqueueSnackbar(t('company:form.noUserFound'), { variant: 'error' })
      setEmailChecked(false)
      return
    }
    if (emailChecked && userData?.user && !userLoading) {
      const result = createMember({
        variables: {
          input: {
            userUUID: userData.user.uuid,
            companyUUID: companyUuId,
            role: roleAtCompany,
            // Set/Edit user status. It will uncomment later.
            //status: statusAtCompany,
          },
        },
      })
      result.catch((e) => {
        console.error(e)
        enqueueSnackbar(e.message, { variant: 'error' })
        enqueueSnackbar(t('common:status.unSuccessCreate', { entity: t('common:table.member') }), {
          variant: 'error',
        })
      })
    }
  }, [userData])

  useEffect(() => {
    if (!createdMember && !updatedMember) return
    history.goBack()
    enqueueSnackbar(
      t(`common:status.${createdMember ? 'successCreate' : 'successUpdate'}`, {
        entity: t('common:table.member'),
      }),
      { variant: 'success' }
    )
  }, [createdMember, updatedMember])

  return (
    <>
      {loading ? null : (
        <Container maxWidth="sm">
          <Paper elevation={2} className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle className={classes.dialogTitle}>
                {formMode === 'edit' ? t('company:form.editMember') : t('company:form.addMember')}
              </DialogTitle>

              {formMode === 'edit' && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="name"
                      name="name"
                      label={t('common:table.name')}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
              {formMode === 'create' && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.inputField}
                      fullWidth
                      control={control}
                      id="email"
                      name="email"
                      label={t('company:form.membersEmail')}
                      required
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="roleAtCompany"
                    name="roleAtCompany"
                    label={t('company:form.memberRole')}
                    select
                    required
                  >
                    {selectableRoles.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`user:enum.roleAtCompany.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* Set/Edit user status. It will uncomment later. 
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    control={control}
                    id="statusAtCompany"
                    name="statusAtCompany"
                    label={t('company:form.memberStatus')}
                    select
                    required
                  >
                    {Object.values(CompanyMembershipStatus).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`user:enum.statusAtCompany.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
              </Grid>
              <Grid item xs={12} className={classes.actionButtons}>
                <Button
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => history.goBack()}
                >
                  {t('common:action.cancel')}
                </Button>
                <AppButton style={{ color: theme.palette.primary.main }} type="submit">
                  {formMode === 'edit' ? t('common:action.edit') : t('common:action.save')}
                </AppButton>
              </Grid>
            </form>
          </Paper>
        </Container>
      )}
    </>
  )
}

export default CreateOrEditMember
