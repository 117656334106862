import { useMemo } from 'react'
import {
  AppBar,
  styled,
  Toolbar,
  Typography,
  Box,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'
import { AppMode } from '..'
import UserMenu, { MenuItem as MenuItemType } from './UserMenu'
import { useLogout } from 'context/AuthContext'

const StyledAppBar = styled(AppBar)(({ theme }: { theme: Theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}))

const MenuButton = styled(IconButton)(({ theme }: { theme: Theme }) => ({
  marginRight: theme.spacing(2),
}))

const useStyles = makeStyles(() =>
  createStyles({
    hide: {
      display: 'none',
    },
    pageTitle: {
      '&:hover': {
        outline: 0,
        border: 0,
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
  })
)
interface Props {
  toggleSidebar?: () => void
  mode: AppMode
}

const Header = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const logout = useLogout()

  const userMenuItems: MenuItemType[] = useMemo(() => {
    return [
      {
        name: 'Log out',
        onClick: () => {
          logout()
        },
        icon: <ExitToAppIcon fontSize="small" />,
      },
    ]
  }, [])

  return (
    <StyledAppBar position="fixed" color="primary">
      <Container maxWidth="xl">
        <Toolbar>
          <Box display="flex" alignItems="center">
            {props.toggleSidebar && props.mode === 'mobile' ? (
              <MenuButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.toggleSidebar}
                edge="start"
              >
                <MenuIcon />
              </MenuButton>
            ) : null}
            <MenuItem className={classes.pageTitle} component={Link} to="/">
              <Typography variant="h6" noWrap>
                {isMobile ? 'Home' : 'Adminways'}
              </Typography>
            </MenuItem>
          </Box>
          <UserMenu menuItems={userMenuItems} />
        </Toolbar>
      </Container>
    </StyledAppBar>
  )
}

export default Header
