import { TextField } from 'components/Form/Atoms'
import { Control, UseFormGetValues } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StyledHeader } from './Styles'
import { SignupFormValues } from './types'

interface Props {
  control: Control<SignupFormValues>
  getValues: UseFormGetValues<SignupFormValues>
}

const PasswordFields = (props: Props) => {
  const { t } = useTranslation('signup')

  return (
    <>
      <Grid item xs={12}>
        <StyledHeader variant="h6">{t('signup:form.setPassword')}</StyledHeader>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="password"
          fullWidth
          required
          control={props.control}
          label={t('signup:form.password')}
          name="password"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="password"
          fullWidth
          rules={{
            validate: (confirmedPassword) =>
              props.getValues('password') === confirmedPassword || 'Passwords do not match',
          }}
          required
          control={props.control}
          label={t('signup:form.confirmPassword')}
          name="passwordConfirm"
        />
      </Grid>
    </>
  )
}

export default PasswordFields
